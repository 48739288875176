<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="990px" :title="diaLogTitle" :visible.sync="diaLogVisible">
		<div class="handle_form">
			<el-form ref="mailDetail" :rules="mailRules" :model="mailDetail" label-width="90px">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="任务完成情况:">
							<div class="handle_form_num">
								<span class="form_num_green">{{mailDetail.finishCount}}</span>
								<span>/{{mailDetail.count}}</span>
							</div>
						</el-form-item>
					</div>
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="任务总数:">
							<el-input-number v-model="mailDetail.count" :min="mailDetail.count" :max="1000" @change="inputChange"></el-input-number>
						</el-form-item>
					</div>
				</div>
				<div class="" v-for="(item,index) in mailDetail.controlpanelEmails" :key="index">
					<div class="handle_form_row flex_between">
						<div class="handle_form_item">
							<el-form-item label-width="60px" class="flex_item" label="邮寄时间:">
								<el-date-picker class="handle_form_date" v-model="item.sendTime" value-format="yyyy-MM-dd"  placeholder="选择日期">
								</el-date-picker>
							</el-form-item>
						</div>
						<div class="handle_form_item">
							<el-form-item class="flex_item" label="状态:">
								<el-select v-model="item.state" class="handle_form_select" clearable
									placeholder="请选择">
									<el-option v-for="ite in mainTeachers" :key="ite.value" :label="ite.label"
										:value="ite.value">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>
					<div class="handle_form_row">
						<div class="handle_form_item">
							<el-form-item label-width="60px" class="flex_item" label="快递单号:">
								<input class="handle_form_ipt" v-model="item.number" placeholder="请输入快递单号"/>
							</el-form-item>
						</div>
					</div>
					<div class="handle_form_row">
						<div class="handle_form_item margin_right_66">
							<el-form-item label-width="95px" class="" label="邮寄截图/照片:">
								<clickUpLoad :fileNum="index" @beforeUpload="beforeUpload" :fileList="item.picUrl"/>
							</el-form-item>
						</div>
						<div class="handle_form_item">
							<el-form-item label-width="40px" class="" label="备注:">
								<textarea class="handle_form_textarea" v-model="item.remark"></textarea>
							</el-form-item>
						</div>
					</div>
					<div class="handle_form_row">
						<div class="flex-start delete-left" @click="handleDelete(item,index)">
							<img class="delete-icon" src="@/assets/images/common/delete-icon.png" alt="">
							<span class="delete-text">删除任务</span>
						</div>
						<div class="handle_form_user">
							<span v-if="item.submitTime" class="margin_right_66">创建时间：{{item.submitTime}}</span>
							<span v-if="item.updateTime" class="margin_right_66">提交时间：{{item.updateTime}}</span>
							<span  v-if="item.updateUser">提交人：{{item.updateUser}}</span>
						</div>
					</div>
					<div class="bottom_line"></div>
				</div>
			</el-form>
			<div class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span v-preventReClick @click="submitMail" class="handle_form_confirm">确定</span>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import Bus from "./eventBus.js";
	import clickUpLoad from "@/components/clickUpLoad/index.vue";
	import {
		mailList,
		mailSave
	} from "@/api/acTable"
	export default {
		components: {
			clickUpLoad,
		},
		props: {},
		watch: {},
		mounted() {
			Bus.$on("handleMailForm", props => {
				this.propsData = props.row
				this.diaLogVisible = true
				this.getMailList()
				// console.log("handleMailForm", props)
			})
		},
		data() {
			return {
				diaLogTitle: "邮寄",
				diaLogVisible: false,
				mailDetail: {
					controlpanelEmails:[
						// {number:"",remark:"",picUrl:"",sendTime:"",submitTime:"",submitUser:"",state:""}
					],
					finishCount:0,
					count:0,
					deleteIds:""
				},
				mailRules: {},
				mainTeachers:[{
					value: '邮寄中',
					label: '邮寄中'
					},{
					value: '已完成',
					label: '已完成'
					},
				],
				deleteIds:[],
			}
		},
		methods: {
			//点击删除
			handleDelete(item,index){
				if(!item.id){
					this.mailDetail.controlpanelEmails.splice(index,1)
					this.mailDetail.count--
				}else{
					this.deleteIds.push(item.id)
					this.mailDetail.controlpanelEmails.splice(index,1)
					this.mailDetail.count--
				}
			},
			//邮寄证明
			getMailList(){
				var params = {
					studentId: this.propsData.studentId,
					schoolId:this.propsData.schoolId
				}
				mailList(params).then(res=>{
					console.log("成绩单")
					if(res.code == 0){
						if(res.data){
							// if(res.data.controlpanelEmails.length==0){
							// 	var params ={number:"",remark:"",picUrl:"",sendTime:"",submitTime:"",submitUser:"",state:""}
							// 	res.data.controlpanelEmails.push(params)
							// 	res.data.count = 1
							// }
							this.mailDetail = res.data
							// this.gradeDetail = res.data
							
						}
					}
				})
			},
			//点击计数器添加任务数
			inputChange(){
				var params ={
					number:"",picUrl:"",remark:"",sendTime:"",submitTime:"",submitUser:"",state:"",schoolId:this.propsData.schoolId,studentId:this.propsData.studentId
				}
				this.mailDetail.controlpanelEmails.push(params)
			},
			beforeUpload(data){
				this.mailDetail.controlpanelEmails[data.number].picUrl = data.fileData
			},
			//邮寄编辑
			submitMail(){
				this.mailDetail.controlpanelEmails.forEach(ite=>{
					ite.studentId = this.propsData.studentId
					ite.schoolId = this.propsData.schoolId
				})
				this.mailDetail.studentId = this.propsData.studentId
				this.mailDetail.schoolId = this.propsData.schoolId
				const newArr = Array.from(new Set(this.deleteIds))
				this.mailDetail.deleteIds = newArr.join(',')
				let editParams = JSON.parse(JSON.stringify(this.mailDetail))
				mailSave(editParams).then(res=>{
					if(res.code == 0){
						this.$message({
							message: '编辑成功',
							type: 'success'
						});
						this.closeDialog()
						this.$parent.getAcLists()
					}
				})
			},
			closeDialog() {
				this.diaLogVisible = false
				// this.$emit('closeDialog', 'commom')
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";

	/deep/.el-date-editor.el-input :focus {
		border: 1px solid $theme_color;
	}

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex_column {
		display: flex;
		flex-direction: column;
	}

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.margin_right_66 {
		margin-right: 66px;
	}
	
	.bottom_line {
		width: 100%;
		height: 1px;
		margin-top: 20px;
		background-color: #EEEEEE;
	}

	.handle_form {
		max-height:700px;
		overflow-y: scroll;
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			// padding: 0 23px;
			.delete-left{
				cursor: pointer;
			}
			.delete-icon{
				width:14px;
				height:14px;
				margin-right:8px;
			}
			.delete-text{
				font-size:14px;
			}
			.handle_form_label {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #333333;
			}
			
			.handle_form_user {
				margin-left: auto;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #333333;
			}

			.handle_form_item {
				// flex: 1;
				display: flex;

				.handle_form_num {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #333333;

					.form_num_green {
						color: $theme_color;
					}
				}

				.handle_form_ipt {
					width: 240px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					padding: 0 10px;
					box-sizing: border-box;
					margin-right: 150px;
				}

				.handle_form_date {
					width: 240px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
				}

				.handle_form_select {
					width: 200px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					margin-top: 9px;
				}

				.handle_form_textarea {
					width: 580px;
					height: 112px;
					background: #FFFFFF;
					border: 1px solid #EEEEEE;
					border-radius: 4px;
					outline: none;
					resize: none;
					padding: 6px 10px;
					box-sizing: border-box;
					margin: 0px 0 0 0px;
				}

				.handle_form_upload {
					display: flex;
					flex-direction: column;

					.form_upload_box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 128px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #EEEEEE;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #000000;
						cursor: pointer;

						.form_upload_icon {
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
					}

					.form_upload_tip {
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 17px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #FFFFFF;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFF;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>
