import axios from '@/utils/request'
const Api = {
    detailControlpanel:"/api/backyard/controlpanelCommon/detail",
	saveControlpanel:"/api/backyard/controlpanelCommon/save",
    listBatch:"/api/backyard/controlpanelScore/list",
    saveBatch:"/api/backyard/controlpanelScore/savebatch",
    listLetter:"/api/backyard/controlpanelRecommendation/list",
    saveLetter:"/api/backyard/controlpanelRecommendation/savebatch",
    listFunds:"/api/backyard/controlpanelFunds/list",
    saveFunds:"/api/backyard/controlpanelFunds/savebatch",
    listMater:"/api/backyard/controlpanelExtramaterial/list",
    saveMater:"/api/backyard/controlpanelExtramaterial/savebatch",
    listInterview:"/api/backyard/controlpanelInterview/list",
    saveInterview:"/api/backyard/controlpanelInterview/savebatch",
    listMail:"/api/backyard/controlpanelEmail/list",
    saveMail:"/api/backyard/controlpanelEmail/savebatch",
    listState:"/api/backyard/controlpanelState/detail",
    saveState:"/api/backyard/controlpanelState/save",
    listAc:"/api/backyard/controlpanel/list",
    listSchoolAudition:"/api/backyard/schoolAudition/list",
    saveSchoolAudition:"/api/backyard/schoolAudition/save",
    listWriteForm:"/api/backyard/schoolPaperwork/paperwork/detail",
    saveWriteForm:"/api/backyard/schoolPaperwork/updateState",
    listStandarForm:"/api/backyard/controlpanelStandard/list",
    saveStandarForm:"/api/backyard/controlpanelStandard/save",
    deleteInterview:"/api/backyard/schoolAudition/delete"
}
//查看ac控制面板列表
export function acList (params) {
  return axios({
    method: 'get',
    url: Api.listAc,
    params: params
  })
}
// 查看通用
export function controlpanelDetail (params) {
    return axios({
      method: 'get',
      url: Api.detailControlpanel,
      params: params
    })
}
// 保存通用
export function controlpanelSave (params) {
    return axios({
      method: 'post',
      url: Api.saveControlpanel,
      params: params
    })
}
// 查看标化成绩
export function standarFormList (params) {
  return axios({
    method: 'post',
    url: Api.listStandarForm,
    params: params
  })
}
// 保存标化成绩
export function standarFormSave (params) {
  return axios({
    method: 'post',
    url: Api.saveStandarForm,
    data: params
  })
}
// 查看成绩单
export function batchList (params) {
    return axios({
      method: 'get',
      url: Api.listBatch,
      params: params
    })
}
// 保存成绩单
export function batchSave (params) {
    return axios({
      method: 'post',
      url: Api.saveBatch,
      data: params
    })
}
// 查看推荐信
export function letterList (params) {
    return axios({
      method: 'get',
      url: Api.listLetter,
      params: params
    })
}
// 保存推荐信
export function letterSave (params) {
    return axios({
      method: 'post',
      url: Api.saveLetter,
      data: params
    })
}
// 查看资金证明
export function fundsList (params) {
  return axios({
    method: 'get',
    url: Api.listFunds,
    params: params
  })
}
// 保存资金证明
export function fundsSave (params) {
  return axios({
    method: 'post',
    url: Api.saveFunds,
    data: params
  })
}
// 查看材料附件
export function materList (params) {
  return axios({
    method: 'get',
    url: Api.listMater,
    params: params
  })
}
// 保存材料附件
export function materSave (params) {
  return axios({
    method: 'post',
    url: Api.saveMater,
    data: params
  })
}
// 查看面试
export function interviewList (params) {
  return axios({
    method: 'get',
    url: Api.listInterview,
    params: params
  })
}
// 保存面试
export function interviewSave (params) {
  return axios({
    method: 'post',
    url: Api.saveInterview,
    data: params
  })
}
// 查看邮件
export function mailList (params) {
  return axios({
    method: 'get',
    url: Api.listMail,
    params: params
  })
}
// 保存邮件
export function mailSave (params) {
  return axios({
    method: 'post',
    url: Api.saveMail,
    data: params
  })
}
// 查看状态
export function stateList (params) {
  return axios({
    method: 'get',
    url: Api.listState,
    params: params
  })
}
// 保存状态
export function stateSave (params) {
  return axios({
    method: 'post',
    url: Api.saveState,
    data: params
  })
}
// 查看文书
export function writeFormList (params) {
  return axios({
    method: 'post',
    url: Api.listWriteForm,
    params: params
  })
}
// 保存文书
export function writeFromSave (params) {
  return axios({
    method: 'post',
    url: Api.saveWriteForm,
    data: params
  })
}
//面试列表
export function schoolAuditionList (params) {
  return axios({
    method: 'get',
    url: Api.listSchoolAudition,
    params: params
  })
}
//面试编辑
export function schoolAuditionSave (params) {
  return axios({
    method: 'post',
    url: Api.saveSchoolAudition,
    data: params
  })
}
//面试删除
export function InterviewDelete(params) {
	return axios({
		method: 'get',
		url: Api.deleteInterview,
		params: params
	})
}
