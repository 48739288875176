<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="981px" :title="diaLogTitle" :visible.sync="diaLogVisible">
		<div class="handle_form">
			<el-form ref="leterDetail" :rules="letterRules" :model="letterDetail" label-width="90px">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="任务完成情况:">
							<div class="handle_form_num">
								<span class="form_num_green">{{letterDetail.finishCount}}</span>
								<span>/{{letterDetail.count}}</span>
							</div>
						</el-form-item>
					</div>
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="任务总数:">
							<el-input-number v-model="letterDetail.count" :min="letterDetail.count" :max="1000" @change="inputChange"></el-input-number>
						</el-form-item>
					</div>
				</div>
				<div class="" v-for="(item,index) in letterDetail.controlpanelRecommendations" :key="index">
					<div class="handle_form_row flex_between">
						<div class="handle_form_item">
							<el-form-item label-width="60px" class="" label="附件资料:">
								<uploadFile :fileNum="index" @successUpload="successUpload" :fileSrc="item.appendixUrl" :fileNameP="item.appendixName"/>
								<!-- <div class="handle_form_upload">
									<span class="form_upload_box">
										<img src="@/assets/images/dashboard/upLoad.png" class="form_upload_icon">点击下载
									</span>
									<span class="form_upload_tip">注：可上传文本和图片</span>
								</div> -->
							</el-form-item>
						</div>
						<div class="handle_form_item">
							<el-form-item class="flex_item" label="状态:">
								<el-select v-model="item.state" class="handle_form_select" clearable
									placeholder="请选择">
									<el-option v-for="ite in mainTeachers" :key="ite.value" :label="ite.label"
										:value="ite.value">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>
					<div class="handle_form_row">
						<div class="handle_form_item">
							<el-form-item label-width="40px" class="flex-column" label="备注:">
								<textarea class="handle_form_textarea" v-model="item.remark"></textarea>
							</el-form-item>
						</div>
					</div>
					<div class="handle_form_row">
						<div class="flex-start delete-left" @click="handleDelete(item,index)">
							<img class="delete-icon" src="@/assets/images/common/delete-icon.png" alt="">
							<span class="delete-text">删除任务</span>
						</div>
						<div class="handle_form_user">
							<span v-if="item.submitTime">创建时间：{{item.submitTime}}</span>
							<span v-if="item.updateTime" class="margin_right_66">提交时间：{{item.updateTime}}</span>
							<span  v-if="item.updateUser">提交人：{{item.updateUser}}</span>
						</div>
					</div>
					<div class="line"></div>
				</div>
			</el-form>
			<div class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span v-preventReClick @click="submitLetter" class="handle_form_confirm">确定</span>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import Bus from "./eventBus.js";
	import dragUpLoad from "@/components/dragUpLoad/index.vue";
	import uploadFile from "@/components/uploadFile/index.vue";
	import {
		letterList,
		letterSave
	} from "@/api/acTable"
	export default {
		components: {
			dragUpLoad,
			uploadFile
		},
		props: {},
		watch: {},
		mounted() {
			Bus.$on("handleLetterForm", props => {
				this.propsData = props.row
				this.diaLogVisible = true
				this.getLetterList()
				// console.log("handleLetterForm", props)
			})
		},
		data() {
			return {
				diaLogTitle: "推荐信",
				diaLogVisible: false,
				letterDetail: {
					controlpanelRecommendations:[
						{remark:"",appendixUrl:"",submitTime:"",submitUser:"",state:"",appendixName:""}
					],
					finishCount:0,
					count:0,
					deleteIds:""
				},
				letterRules: {},
				mainTeachers:[
					{
					value: '进行中',
					label: '进行中'
					}, {
					value: '已完成',
					label: '已完成'
					}
				],
				propsData:{},
				deleteIds:[],
			}
		},
		methods: {
			//点击删除
			handleDelete(item,index){
				if(!item.id){
					this.letterDetail.controlpanelRecommendations.splice(index,1)
					this.letterDetail.count--
				}else{
					this.deleteIds.push(item.id)
					this.letterDetail.controlpanelRecommendations.splice(index,1)
					this.letterDetail.count--
				}
			},
			//查看推荐信
			getLetterList(){
				var params = {
					studentId: this.propsData.studentId,
					schoolId:this.propsData.schoolId
				}
				letterList(params).then(res=>{
					if(res.code == 0){
						this.letterDetail = res.data
					}
				})
			},
			//点击计数器添加任务数
			inputChange(){
				var params ={
					remark:"",appendixUrl:"",submitTime:"",submitUser:"",state:""
				}
				this.letterDetail.controlpanelRecommendations.push(params)
			},
			successUpload(data){
				console.log(data)
				this.letterDetail.controlpanelRecommendations[data.number].appendixUrl = data.fileData
				this.letterDetail.controlpanelRecommendations[data.number].appendixName = data.fileName
			},
			//推荐信编辑
			submitLetter(){
				this.letterDetail.controlpanelRecommendations.forEach(ite=>{
					ite.studentId = this.propsData.studentId
					ite.schoolId = this.propsData.schoolId
				})
				this.letterDetail.studentId = this.propsData.studentId
				this.letterDetail.schoolId = this.propsData.schoolId
				const newArr = Array.from(new Set(this.deleteIds))
				this.letterDetail.deleteIds = newArr.join(',')
				let editParams = JSON.parse(JSON.stringify(this.letterDetail))
				letterSave(editParams).then(res=>{
					if(res.code == 0){
						this.$message({
							message: '编辑成功',
							type: 'success'
						});
						this.closeDialog()
						this.$parent.getAcLists()
					}
				})
			},
			closeDialog() {
				this.diaLogVisible = false
				// this.$emit('closeDialog', 'commom')
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	.margin_right_66 {
		margin:0 66px;
	}
	/deep/.el-date-editor.el-input :focus {
		border: 1px solid $theme_color;
	}

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.line{
		height: 1px;
		background: #DDDDDD;
		margin-top:22px;
	}
	.handle_form {
		max-height:600px;
		overflow:auto;
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			// padding: 0 23px;
			.delete-left{
				cursor: pointer;
			}
			.delete-icon{
				width:14px;
				height:14px;
				margin-right:8px;
			}
			.delete-text{
				font-size:14px;
			}
			.handle_form_label {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #333333;
			}
			
			.handle_form_user {
				margin-left: auto;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #333333;
			}

			.handle_form_item {
				// flex: 1;
				display: flex;

				.handle_form_num {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #333333;

					.form_num_green {
						color: $theme_color;
					}
				}

				.handle_form_ipt {
					width: 200px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 30px;
					color: #000000;
					padding: 0 10px;
					box-sizing: border-box;
				}

				.handle_form_date {
					width: 200px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					margin-top: 9px;
				}

				.handle_form_select {
					width: 200px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					margin-top: 9px;
				}

				.handle_form_textarea {
					width: 930px;
					height: 120px;
					background: #FFFFFF;
					border: 1px solid #EEEEEE;
					border-radius: 4px;
					outline: none;
					resize: none;
					padding: 6px 10px;
					box-sizing: border-box;
					margin: 8px 0 0 0px;
				}

				.handle_form_upload {
					display: flex;
					flex-direction: column;

					.form_upload_box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 128px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #EEEEEE;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #000000;
						cursor: pointer;

						.form_upload_icon {
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
					}

					.form_upload_tip {
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 17px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #FFFFFF;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFF;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>
