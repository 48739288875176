<template>
	<div>
		<div v-if="fileData">
			<div>
				<div class="handle_form_upload" v-if="uploadType==2&&sendType==2" @click="handleUpload">
					<span class="content_teacher_right">
						<img src="@/assets/images/applicationProgress/file_down.png">
						<span class="yel">文件下载</span>
					</span>
				</div>
				<div class="handle_form_upload" v-if="uploadType==2&&sendType==1&&!reUpload" @click="handleUpload">
					<span class="content_teacher_right">
						<img src="@/assets/images/applicationProgress/file_down_green.png">
						<span class="green">文件下载</span>
					</span>
				</div>
				<div class="handle_form_upload" v-if="reUpload" @click="handlereUpload">
					<span class="content_teacher_right">
						<img src="@/assets/images/applicationProgress/file_upload.png">
						<span class="green">重新上传</span>
					</span>
				</div>
			</div>
		</div>
		<div v-else>
			<div v-if="uploadType==2&&sendType==1">
				<el-upload class="upload-demo" :action="upLoadUrl" :before-upload="beforeUpload" :show-file-list="false"
					v-if="showType=='addStudent'">
					<div class="content_teacher_right">
						<img src="@/assets/images/applicationProgress/file_up.png">
						<span class="green">文件未上传</span>
					</div>
				</el-upload>
				<div class="content_teacher_right" v-else>
					<img src="@/assets/images/applicationProgress/file_up.png">
					<span class="green">文件未上传</span>
				</div>
			</div>
		</div>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload"></el-upload>
	</div>
</template>
<script>
	import {
		getImgSrc,
		upLoadFile
	} from "@/api/OSS";
	import {
		saveAs
	} from 'file-saver';
	export default {
		props: {
			fileSrc: {
				type: String,
				default: function() {
					return "";
				}
			},
			showType: {
				type: String,
				default: function() {
					return "";
				}
			},
			reUpload: {
				type: Boolean,
				default: function() {
					return false;
				},
			},
			fileNum: {
				type: Number,
				default: 0
			},
			fileType: {
				type: Number
			},
			sendType: {
				type: Number
			}
		},
		data() {
			return {
				upLoadUrl: "",
				fileData: "",
				showUpload: false,
				uploadType: "",

			};
		},
		watch: {
			fileSrc: {
				handler(val) {
					// console.log("fileList", val)
					this.fileData = val
				},
				deep: true,
				immediate: true,
			},
			fileType: {
				handler(val) {
					if (val) {
						this.uploadType = val
					}
				},
				deep: true,
				immediate: true,
			},

		},
		created() {
			// this.uploadType=0
		},
		methods: {
			handlereUpload() {
				document.querySelector(".uploadImage input").click();
			},
			beforeUpload(file) {
				console.log(file)
				let fileName = file.name;
				let fileType = file.type;
				let pointIndex = fileName.lastIndexOf('.')
				let type = fileName.substring(pointIndex + 1, fileName.length).toLowerCase()
				console.log("fileType", type)
				if(type == 'txt'||type == 'zip') {
					this.$message({
						message: "暂不支持txt、zip文件格式~",
						type: "error"
					});
					return false;
				}
				getImgSrc({
					fileName: fileName
				}).then((res) => {
					if (res.code != 0) return;
					this.upLoadUrl = res.data.uploadUrl;
					upLoadFile(file, this.upLoadUrl, fileType).then((res) => {
						const fileUrl = res.config.url.substring(
							0,
							res.config.url.indexOf("?")
						);
						this.fileData = fileUrl;;
						this.$emit("successUpload", {
							fileData: this.fileData,
							number: this.fileNum
						});
						// this.uploadType='reUpload'
					});
				});
			},
			remove() {
				this.uploadType = 0
			},
			getCharFromUtf8(str) {
				var cstr = "";
				var nOffset = 0;
				if (str == "")
					return "";
				str = str.toLowerCase();
				nOffset = str.indexOf("%e");
				if (nOffset == -1)
					return str;
				while (nOffset != -1) {
					cstr += str.substr(0, nOffset);
					str = str.substr(nOffset, str.length - nOffset);
					if (str == "" || str.length < 9)
						return cstr;
					cstr += this.utf8ToChar(str.substr(0, 9));
					str = str.substr(9, str.length - 9);
					nOffset = str.indexOf("%e");
				}
				return cstr + str;
			},
			utf8ToChar(str) {
				var iCode, iCode1, iCode2;
				iCode = parseInt("0x" + str.substr(1, 2));
				iCode1 = parseInt("0x" + str.substr(4, 2));
				iCode2 = parseInt("0x" + str.substr(7, 2));
				return String.fromCharCode(((iCode & 0x0F) << 12) | ((iCode1 & 0x3F) << 6) | (iCode2 & 0x3F));
			},
			handleUpload() {
				let pointIndex = this.fileData.lastIndexOf('.')
				let lineIndex = this.fileData.lastIndexOf('/')
				let fileName = ""
				let fileType = ""
				if (pointIndex != -1) {
					fileName = this.fileData.substring(lineIndex+1, pointIndex).toLowerCase()
					fileName = this.getCharFromUtf8(fileName)
					fileType = this.fileData.substring(pointIndex + 1, this.fileData.length).toLowerCase()
					// console.log("fileType", fileType)
				}
				if (fileType == 'txt') {
					let xhr = new XMLHttpRequest();
					xhr.open("get", this.fileData, true);
					xhr.responseType = "blob";
					xhr.onload = function() {
						if (this.status == 200) {
							const reader = new FileReader()
							reader.onload = function() {
								// console.log('reader.result', reader.result)
								var blob = new Blob([reader.result], {
									type: 'text/plain;charset=utf-8'
								})
								saveAs(blob, fileName + '.txt')
							}
							reader.readAsText(this.response);
						}
					};
					xhr.send();
				} else {
					window.open(this.fileData);
				}
			},
		}
	};
</script>
<style lang="scss" scoped>
	.handle_form_upload {
		text-align: right;

		.form_upload_tip {
			font-size: 12px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			line-height: 17px;
			color: #999999;
			margin-top: 5px;

		}

		.form_upload_again {
			width: 100px;
			font-size: 12px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			line-height: 30px;
			color: #999999;
			margin-top: 5px;
			cursor: pointer;
		}
	}

	.content_teacher_right {
		// margin-left: 28px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		img {
			width: 48px;
			height: 48px;
			margin-bottom: 8px;
		}

		span {
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			line-height: 20px;

		}

		.green {
			color: #5BA897;
		}

		.yel {
			color: #B5B181;
		}
	}
</style>
