<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2022-08-24 18:42:56
-->
<template>
	<div class="applicationProgress">
		<div class="applicationProgress_tab">
			<module-list :moduleList="moduleList" :currentTab="currentTab" @changeTab="changeTab" v-preventReClick />
		</div>
		<div class="applicationProgress_content">
			<div class="applicationProgress_left">
				<!-- 学生列表 -->
				<student-list :studentIndex="studentIndex" :intList="studentList" @selectStudent="selectStudent"
					@searchStudent="searchStudent" v-preventReClick />
			</div>
			<div class="applicationProgress_right">
				<!-- 选校列表 -->
				<div v-if="currentTab == '选校列表'" class="applicationProgress_right_school">
					<choose-table ref="chooseTable" @currentChangeS="currentChangeS" :studentId="studentId" :totalCountS="totalCountS" :pageIndexS="pageIndexS" :schoolData="schoolData" />
					<!-- 添加选校 -->
					<add-draw ref="addDraw" :studentId="studentId" />
					<!-- 编辑选校 -->
					<edit-draw ref="editDraw" :studentId="studentId" />
				</div>
				<!-- AC控制面板 -->
				<div v-if="currentTab == 'AC控制面板'" class="applicationProgress_right_ac">
					<!-- AC控制面板 -->
					<ac-table @currentChangeA="currentChangeA" :intPageSizeA="pageSizeA" :intPageNumA="pageNumA" :intTotalCountA="totalCountA*1" :studentId="studentId" :acControlData="acControlData" />
					<!-- 通用 -->
					<common-form />
					<!-- 标化 -->
					<standard-form />
					<!-- 成绩单 -->
					<grade-form />
					<!-- 推荐信 -->
					<letter-form />
					<!-- 资金证明 -->
					<prove-form />
					<!-- 面试 -->
					<interview-form />
					<!-- 附加材料 -->
					<materials-form />
					<!-- 文书详情 -->
					<writ-form :studentName="name" />
					<!-- 邮寄 -->
					<mail-form />
					<!-- 状态 -->
					<status-form />
				</div>
				<!-- 学校文书 -->
				<div v-if="currentTab == '学校文书'" class="applicationProgress_right_writ">
					<!-- 文书列表 -->
					<writ-table ref="writTable" :studentId="studentId" />
					<!-- 查看文书流 -->
					<writFlow-table :studentName="name" :studentId="studentId" />
				</div>
				<!-- 学校面试 -->
				<div v-if="currentTab == '学校面试'" class="applicationProgress_right_interview">
					<interview-table :studentId="studentId" :studentName="name" ref="interview" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import moduleList from "@/components/moduleList/index.vue";
import studentList from "@/components/studentList/index.vue";
import chooseTable from "./components/chooseTable.vue";
import addDraw from "./components/addDraw.vue";
import editDraw from "./components/editDraw.vue";
import acTable from "./components/acTable.vue";
import commonForm from "./components/commonForm.vue";
import statusForm from "./components/statusForm.vue";
import standardForm from "./components/standardForm.vue";
import gradeForm from "./components/gradeForm.vue";
import letterForm from "./components/letterForm.vue";
import proveForm from "./components/proveForm.vue";
import interviewForm from "./components/interviewForm.vue";
import materialsForm from "./components/materialsForm.vue";
import writForm from "./components/writForm.vue";
import mailForm from "./components/mailForm.vue";
import writTable from "./components/writTable.vue";
import writFlowTable from "./components/writFlowForm.vue";
import interviewTable from "./components/interviewTable.vue";
import commonFn from "@/utils/common.js";
import Bus from "./components/eventBus.js";
import { listStudents } from "@/api/common";
import { selectSchoolList } from "@/api/applicationProgress";
import { acList } from "@/api/acTable";
import storage from "store";
import { mapActions } from "vuex";
export default {
  components: {
    moduleList,
    studentList,
    chooseTable,
    addDraw,
    editDraw,
    acTable,
    commonForm,
    statusForm,
    standardForm,
    gradeForm,
    letterForm,
    proveForm,
    interviewForm,
    materialsForm,
    writForm,
    mailForm,
    writTable,
    writFlowTable,
    interviewTable
  },
  data() {
    return {
      currentTab: "",
      moduleList: [],
      studentIndex: 0,
      studentList: [],
      studentName: "",
      studentId: "",
      schoolData: [],
      acControlData: [],
      pageSizeA: 10,
      pageNumA: 1,
      totalCountA: 0,
      name: "",
      isDocFlow: 0,
      pageIndexS: 1,
      pageSizeS: 10,
      totalCountS: 0
    };
  },
  watch: {
    $route: {
      handler(val) {
        console.log("$router", val);
        if (val && val.query.type == 3) {
          this.currentTab = "学校文书";
          this.isDocFlow = 1;
          this.getStudentLists();
        } else if (val && val.query.type == 1) {
          this.currentTab = "选校列表";
          this.getStudentLists();
        }
      },
      immediate: true
    }
  },
  // mounted() {
  // 	Bus.$on("handleWritFlowSchool", props => {
  // 		console.log("进来了")
  // 		this.$refs.writTable.schoolWritListOfschool()
  // 	})
  // },
  destroyed() {
    // console.log("beforeDestroy")
    storage.remove("currentTab");
  },
  created() {
    console.log("申请进度路由>>>>", this.$route);
    this.moduleList = [];
    if (this.$route.meta.secondaryMenu) {
      let secondaryMenu = this.$route.meta.secondaryMenu;
      this.moduleList = commonFn.secondaryMenu(secondaryMenu);
      if (this.$route.query.type) {
        let tabMap = {
          1: "选校列表",
          2: "AC控制面板",
          3: "学校文书",
          4: "学校面试"
        };
        this.currentTab = tabMap[this.$route.query.type];
      } else {
        if (storage.get("currentTab")) {
          this.currentTab = storage.get("currentTab");
        } else {
          this.currentTab = this.moduleList[0].label;
        }
      }
    }
    this.getStudentLists();
  },
  methods: {
    ...mapActions(["studentUpdateClear"]),
    getShoolList() {
      let params = {
        studentId: this.studentId,
        pageNum: this.pageIndexS,
        pageSize: this.pageSizeS
      };
      selectSchoolList(params).then(res => {
        if (res.code == 0) {
          console.log(res.data, "==>>>>>>>信息");
          this.schoolData = res.data.records;
          this.totalCountS = res.data.total * 1;
        }
      });
    },
    currentChangeS(props) {
      this.pageIndexS = props;
      this.getShoolList();
    },
    // currentChange(val) {
    //   this.pageIndexS = val;
    //   this.getShoolList();
    // },
    changeCurrentTab(studentId) {
      this.currentTab = "学校文书";
      this.studentId = studentId;
      this.isDocFlow = 1;
      this.getStudent();
      this.$nextTick(() => {
        // this.$refs.writTable.schoolWritListOfschool()
        // this.$refs.writTable.commitWriteList()
        // this.$refs.writTable.getPaperWorkPross()
        Bus.$emit("handleWritFlowSchool");
      });
    },
    getStudentLists() {
      console.log(storage.get("currentTab"));
      if (
        storage.get("currentTab") &&
        storage.get("currentTab") == "学校文书"
      ) {
        var params = {
          name: this.studentName,
          isDocFlow: 1
        };
      } else {
        var params = {
          name: this.studentName,
          isDocFlow: this.isDocFlow
        };
      }
      listStudents(params).then(res => {
        if (res.code == 0) {
          this.studentList = res.data;
          if (this.$route.query.studentId) {
            this.studentId = this.$route.query.studentId;
            this.name = res.data.filter(
              item => item.studentAccountId == this.studentId
            )[0].name;
            this.studentIndex = res.data.findIndex(
              item => item.studentAccountId == this.studentId
            );
            storage.set("studentIndex", this.studentIndex);
            if (this.studentList[this.studentIndex].updateNotifyId) {
              this.studentUpdateClear({
                updateNotifyId: this.studentList[this.studentIndex]
                  .updateNotifyId
              }).then(res => {
                if (res.code == 0) {
                  this.studentList[
                    this.studentIndex
                  ].updateNotifyId = undefined;
                  this.$forceUpdate();
                }
              });
            }
          } else {
            // if(storage.get('studentIndex')) {
            // 	this.studentIndex = storage.get('studentIndex') * 1
            // }
            // this.studentId = res.data[this.studentIndex].studentAccountId;
            if (res.data.length == 0) {
              return;
            }
            if (storage.get("studentId")) {
              let Index = res.data.findIndex(
                item => item.studentAccountId == storage.get("studentId")
              );
              // console.log(Index,Index)
              if (Index == -1) {
                this.studentIndex = 0;
                this.studentId = res.data[this.studentIndex].studentAccountId;
              } else {
                this.studentId = storage.get("studentId");
                this.studentIndex = res.data.findIndex(
                  item => item.studentAccountId == this.studentId
                );
              }
              storage.set("studentIndex", this.studentIndex);
              storage.set("studentId", this.studentId);
            } else {
              if (storage.get("studentIndex")) {
                this.studentIndex = storage.get("studentIndex") * 1;
              }
              this.studentId = res.data[this.studentIndex].studentAccountId;
            }
            this.name = res.data[this.studentIndex].name;
            if (this.studentList[this.studentIndex].updateNotifyId) {
              this.studentUpdateClear({
                updateNotifyId: this.studentList[this.studentIndex]
                  .updateNotifyId
              }).then(res => {
                if (res.code == 0) {
                  this.studentList[
                    this.studentIndex
                  ].updateNotifyId = undefined;
                  this.$forceUpdate();
                }
              });
            }
          }
          if (this.currentTab == "选校列表") {
            this.isDocFlow = 0;
            this.getShoolList();
            this.getStudent();
          } else if (this.currentTab == "AC控制面板") {
            this.isDocFlow = 0;
            this.$nextTick(() => {
              this.getStudent();
              this.getAcLists();
            });
          } else if (this.currentTab == "学校文书") {
            this.$nextTick(() => {
              this.isDocFlow = 1;
              this.getStudent();
              Bus.$emit("handleWritFlowSchool");
            });
          } else if (this.currentTab == "学校面试") {
            this.isDocFlow = 0;
            this.$nextTick(() => {
              this.getStudent();
              this.$refs.interview.getschoolAuditionList();
            });
          }
        }
      });
    },
    //搜索学生
    searchStudent(data) {
      this.studentName = data;
      this.getStudentLists();
    },
    //获取ac控制面板列表
    getAcLists() {
      if (this.studentList.length == 0) {
        return;
      }
      var params = {
        pageSize: this.pageSizeA,
        pageNum: this.pageNumA,
        studentId: this.studentId
      };
      acList(params).then(res => {
        if (res.code == 0) {
          this.acControlData = res.data.records;
          this.totalCountA = res.data.total;
        }
      });
    },
    currentChangeA(props) {
      this.pageNumA = props;
      this.getAcLists();
    },
    //获取学生列表
    getStudent() {
      return new Promise((resolve, reject) => {
        var params = {
          name: this.studentName,
          isDocFlow: this.isDocFlow
        };
        listStudents(params).then(res => {
          if (res.code == 0) {
            this.studentList = res.data;
            if (this.$route.query.studentId) {
              this.studentId = this.$route.query.studentId;
              this.name = res.data.filter(
                item => item.studentAccountId == this.studentId
              )[0].name;
              this.studentIndex = res.data.findIndex(
                item => item.studentAccountId == this.studentId
              );
              storage.set("studentIndex", this.studentIndex);
            } else {
              if (res.data.length == 0) {
                return;
              }
              if (storage.get("studentId")) {
                let IndexTwo = res.data.findIndex(
                  item => item.studentAccountId == storage.get("studentId")
                );
                if (IndexTwo == -1) {
                  this.studentIndex = 0;
                  this.studentId = res.data[this.studentIndex].studentAccountId;
                } else {
                  this.studentId = storage.get("studentId");
                  this.studentIndex = res.data.findIndex(
                    item => item.studentAccountId == this.studentId
                  );
                }
                storage.set("studentIndex", this.studentIndex);
                storage.set("studentId", this.studentId);
              } else {
                if (storage.get("studentIndex")) {
                  this.studentIndex = storage.get("studentIndex") * 1;
                }
                this.studentId = res.data[this.studentIndex].studentAccountId;
              }
            }
            this.name = res.data[this.studentIndex].name;
            if (this.studentList[this.studentIndex].updateNotifyId) {
              this.studentUpdateClear({
                updateNotifyId: this.studentList[this.studentIndex]
                  .updateNotifyId
              }).then(res => {
                if (res.code == 0) {
                  this.studentList[
                    this.studentIndex
                  ].updateNotifyId = undefined;
                  this.$forceUpdate();
                }
              });
            }
            // }
            resolve(this.studentList);
            resolve(this.studentId);
            resolve(this.studentIndex);
          } else {
            reject();
          }
        });
      });
    },
    selectStudent(data) {
      this.studentId = data.studentAccountId;
      this.studentIndex = this.studentList.findIndex(
        item => item.studentAccountId == this.studentId
      );
      storage.set("studentIndex", this.studentIndex);
      storage.set("studentId", this.studentId);
      this.name = data.name;
      switch (this.currentTab) {
        case "选校列表":
          this.$nextTick(() => {
            this.getShoolList();
          });
          break;
        case "AC控制面板":
          this.$nextTick(() => {
            this.getAcLists();
          });
          break;
        case "学校文书":
          this.$nextTick(() => {
            // this.isDocFlow = 1
            // this.getStudent()
            Bus.$emit("handleWritFlowSchool");
          });
          break;
        case "学校面试":
          this.$nextTick(() => {
            this.$refs.interview.getschoolAuditionList();
          });
          break;
      }
    },

    async changeTab(props) {
      this.studentId = "";
      this.currentTab = props.label;
      storage.set("currentTab", this.currentTab, 7 * 24 * 60 * 60 * 1000);
      if (props.label == "学校文书") {
        this.isDocFlow = 1;
      } else {
        this.isDocFlow = 0;
      }
      await this.getStudent();
      switch (this.currentTab) {
        case "选校列表":
          if (this.studentList.length == 0) {
            return;
          }
          this.getShoolList();
          break;
        case "AC控制面板":
          if (this.studentList.length == 0) {
            return;
          }
          this.getAcLists();
          break;
        case "学校文书":
          if (this.studentList.length == 0) {
            return;
          }
          this.$nextTick(() => {
            Bus.$emit("handleWritFlowSchool");
          });
          break;
        case "学校面试":
          if (this.studentList.length == 0) {
            return;
          }
          this.$nextTick(() => {
            this.$refs.interview.getschoolAuditionList();
          });
          break;
      }
      this.$router.push({
        query: {}
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.applicationProgress {
  display: flex;
  flex-direction: column;

  .applicationProgress_tab {
    padding-left: 780px;
    background-color: #f7f7f7;
    margin-top: 4px;
  }

  .applicationProgress_content {
    background-color: #ffffff;
    padding: 20px 40px;
    display: flex;

    .applicationProgress_left {
      margin-right: 20px;
    }

    .applicationProgress_right {
      flex: 1;

      .applicationProgress_right_school {
        position: relative;
      }

      .applicationProgress_right_ac {
        position: relative;
      }

      .applicationProgress_right_writ {
        position: relative;
      }

      .applicationProgress_right_interview {
        position: relative;
      }
    }
  }
}

.knowledgeBase_table_page {
  text-align: center;
  margin-top: 20px;
}
</style>
