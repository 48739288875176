<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="981px" :title="diaLogTitle"
		:visible.sync="diaLogVisible">
		<div class="handle_form">
			<el-form ref="standardDetail" :rules="standardRules" :model="standardDetail" label-width="90px">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="任务完成情况:">
							<div class="handle_form_num">
								<span class="form_num_green">{{standardDetail.finishCount||0}}</span>
								<span>/{{standardDetail.count}}</span>
							</div>
						</el-form-item>
					</div>
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="任务总数:">
							<el-input-number  v-model="standardDetail.count" :min="standardDetail.count" :max="1000" @change="inputChange" @input="1"
                @keydown.native="channelInputLimit"></el-input-number>
						</el-form-item>
					</div>
				</div>
				<div v-for="(item,index) in standardDetail.controlpanelStandards" :key="index">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label-width="60px" class="flex_item" label="选择标化:">
							<div class="handle_form_tab">
								<div @click="changeTab(item,ite.value,'change')" :class="{'tab_active': (item.currentTab).includes(ite.value)}"
									class="form_tab_item" v-for="(ite, index) in moduleList" :key="index">
									{{ite.label}}
								</div>
							</div>
						</el-form-item>
					</div>
				</div>
				<!-- <div class="handle_form_row flex_between">
						<span class="handle_form_label"></span>
						<div class="handle_form_item">
							<el-form-item class="flex_item" label="状态:">
								<el-select v-model="item.state" class="handle_form_select" clearable
									placeholder="请选择">
									<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</div> -->
				<div class="handle_form_content" >
					<!-- 托福 -->
					<div v-if="filterArr((item.currentTab), 1)" class="flex_column">
						<div class="handle_form_row flex_between m-b">
							<div class="left-img">
								<span class="handle_form_label">托福</span>
								<img src="@/assets/images/common/go-icon.png" alt="" class="go-icon" @click="handleGoPath(1)">
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="状态:">
									<el-select v-model="item.state" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_table">
							<el-table class="form_table_inner" ref="multipleTable" highlight-current-row :header-cell-style="headStyle" :cell-style="rowStyle"
								:data="item.tableData" border>
								 <el-table-column label-width="55" v-if="!item.type&&item.tableData.length>0"  >
									 <template slot-scope="scope">
											<el-radio size="medium" :label="scope.row.standardToeflId" v-model="userInfor.tofuId" @click.native.stop.prevent="handleRowChange(1,scope.row,item)">&nbsp;</el-radio>
									 </template>
								 </el-table-column>
								<el-table-column prop="examDate" label="考试时间"></el-table-column>
								<el-table-column prop="grossScore" label="总分(120)">
								</el-table-column>
								<el-table-column prop="readingScore" label="阅读(30)">
								</el-table-column>
								<el-table-column prop="hearingScore" label="听力(30)">
								</el-table-column>
								<el-table-column prop="speakingScore" label="口语(30)">
								</el-table-column>
								<el-table-column prop="writingScore" label="写作(30)">
								</el-table-column>
								<el-table-column prop="model" label="考试模式">
									<template slot-scope="scope">
										<div class="">
											{{scope.row.model==1?"线上":scope.row.model==2?"线下":''}}
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<!-- 雅思 -->
					<div v-if="filterArr((item.currentTab), 2)" class="flex_column">
						<div class="handle_form_row flex_between m-b">
							<div class="left-img">
								<span class="handle_form_label">雅思</span>
								<img src="@/assets/images/common/go-icon.png" alt="" class="go-icon" @click="handleGoPath(2)">
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="状态:">
									<el-select v-model="item.state" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_table">
							<el-table class="form_table_inner" highlight-current-row :header-cell-style="headStyle" :cell-style="rowStyle"
								:data="item.tableData" border>
								<el-table-column label-width="55" v-if="!item.type&&item.tableData.length>0">
									 <template slot-scope="scope">
										 <el-radio size="medium" :label="scope.row.standardIeltsId" v-model="userInfor.yasiId" @click.native.stop.prevent="handleRowChange(2,scope.row,item)">&nbsp;</el-radio>
									 </template>
								 </el-table-column>
								<el-table-column prop="examDate" label="考试时间"></el-table-column>
								<el-table-column prop="grossScore" label="总分(120)">
								</el-table-column>
								<el-table-column prop="readingScore" label="阅读(30)">
								</el-table-column>
								<el-table-column prop="hearingScore" label="听力(30)">
								</el-table-column>
								<el-table-column prop="speakingScore" label="口语(30)">
								</el-table-column>
								<el-table-column prop="writingScore" label="写作(30)">
								</el-table-column>
								<el-table-column prop="model" label="考试模式">
									<template slot-scope="scope">
										<div class="">
											{{scope.row.model==1?"线上":scope.row.model==2?"线下":''}}
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<!-- 多邻国 -->
					<div v-if="filterArr((item.currentTab), 3)" class="flex_column">
						<div class="handle_form_row flex_between m-b">
							<div class="left-img">
								<span class="handle_form_label">多邻国</span>
								<img src="@/assets/images/common/go-icon.png" alt="" class="go-icon" @click="handleGoPath(3)">
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="状态:">
									<el-select v-model="item.state" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_table">
							<el-table class="form_table_inner" highlight-current-row :header-cell-style="headStyle" :cell-style="rowStyle"
								:data="item.tableData" border>
								<el-table-column label-width="55" v-if="!item.type&&item.tableData.length>0">
									 <template slot-scope="scope">
										 <el-radio size="medium" :label="scope.row.standardDuolingoId" v-model="userInfor.duolinguoId" @click.native.stop.prevent="handleRowChange(3,scope.row,item)">&nbsp;</el-radio>
									 </template>
								 </el-table-column>
								<el-table-column prop="examDate" label="考试时间"></el-table-column>
								<el-table-column prop="grossScore" label="总分">
								</el-table-column>
								<el-table-column prop="literacyScore" label="Literacy(160)">
								</el-table-column>
								<el-table-column prop="comprehensionScore" label="Comprehension(160)">
								</el-table-column>
								<el-table-column prop="conversationScore" label="Conversation(160)">
								</el-table-column>
								<el-table-column prop="productionScore" label="Production(160)">
								</el-table-column>
								<el-table-column prop="model" label="考试模式">
									<template slot-scope="scope">
										<div class="">
											{{scope.row.model==1?"线上":scope.row.model==2?"线下":''}}
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<!-- SAT -->
					<div v-if="filterArr((item.currentTab), 4)" class="flex_column">
						<div class="handle_form_row flex_between m-b">
							<div class="left-img">
								<span class="handle_form_label">SAT</span>
								<img src="@/assets/images/common/go-icon.png" alt="" class="go-icon" @click="handleGoPath(4)">
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="状态:">
									<el-select v-model="item.state" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_table">
							<el-table class="form_table_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
								:data="item.tableData" border>
								<el-table-column label-width="55" v-if="!item.type&&item.tableData.length>0">
									 <template slot-scope="scope">
										 <el-radio size="medium" :label="scope.row.standardSatId" v-model="userInfor.SATId" @click.native.stop.prevent="handleRowChange(4,scope.row,item)">&nbsp;</el-radio>
									 </template>
								 </el-table-column>
								<el-table-column prop="examDate" label="考试时间"></el-table-column>
								<el-table-column prop="grossScore" label="总分(1600)">
								</el-table-column>
								<el-table-column prop="readingGrammarScore" label="阅读+文法(800)">
								</el-table-column>
								<el-table-column prop="mathScore" label="数学(800)">
								</el-table-column>
								<el-table-column prop="readingScore" label="写作/Reading(8)">
								</el-table-column>
								<el-table-column prop="analysisScore" label="写作/Analysis(8)">
								</el-table-column>
								<el-table-column prop="writingScore" label="写作/Writing(8)">
								</el-table-column>
								<el-table-column prop="model" label="考试模式">
									<template slot-scope="scope">
										<div class="">
											{{scope.row.model==1?"线上":scope.row.model==2?"线下":''}}
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<!-- SAT2 -->
					<div v-if="filterArr((item.currentTab), 5)" class="flex_column">
						<div class="handle_form_row flex_between m-b">
							<div class="left-img">
								<span class="handle_form_label">SAT2</span>
								<img src="@/assets/images/common/go-icon.png" alt="" class="go-icon" @click="handleGoPath(5)">
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="状态:">
									<el-select v-model="item.state" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_table">
							<el-table class="form_table_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
								:data="item.tableData" border>
								<el-table-column label-width="55" v-if="!item.type&&item.tableData.length>0">
									 <template slot-scope="scope">
										 <el-radio size="medium" :label="scope.row.standardSat2Id"  v-model="userInfor.Sat2Id" @click.native.stop.prevent="handleRowChange(5,scope.row,item)">&nbsp;</el-radio>
									 </template>
								 </el-table-column>
								<el-table-column prop="examDate" label="考试时间"></el-table-column>
								<el-table-column v-for="(el,index) in sat2SubjectData" :key="index" :prop="'subjectScore'+el.standardSat2ExtendId"  :label="el.subjectName">
								</el-table-column>
								<el-table-column prop="model" label="考试模式">
									<template slot-scope="scope">
										<div class="">
											{{scope.row.model==1?"线上":scope.row.model==2?"线下":''}}
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<!-- AP -->
					<div v-if="filterArr((item.currentTab), 6)" class="flex_column">
						<div class="handle_form_row flex_between m-b">
							<div class="left-img">
								<span class="handle_form_label">AP</span>
								<img src="@/assets/images/common/go-icon.png" alt="" class="go-icon" @click="handleGoPath(6)">
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="状态:">
									<el-select v-model="item.state" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_table">
							<el-table class="form_table_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
								:data="item.tableData" border>
								<el-table-column label-width="55" v-if="!item.type&&item.tableData.length>0">
									 <template slot-scope="scope">
										 <el-radio size="medium" :label="scope.row.standardApId" v-model="userInfor.ApId" @click.native.stop.prevent="handleRowChange(6,scope.row,item)">&nbsp;</el-radio>
									 </template>
								 </el-table-column>
								<el-table-column prop="examDate" label="考试时间"></el-table-column>
								<el-table-column prop="subjectName" label="科目"></el-table-column>
								<el-table-column prop="subjectScore" label="分数"></el-table-column>
								<el-table-column prop="model" label="考试模式">
									<template slot-scope="scope">
										<div class="">
											{{scope.row.model==1?"线上":scope.row.model==2?"线下":''}}
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<!-- ALevel -->
					<div v-if="filterArr(item.currentTab, 7)" class="flex_column">
						<div class="handle_form_row flex_between m-b">
							<div class="left-img">
								<span class="handle_form_label">ALevel</span>
								<img src="@/assets/images/common/go-icon.png" alt="" class="go-icon" @click="handleGoPath(7)">
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="状态:">
									<el-select v-model="item.state" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_table">
							<el-table class="form_table_inner"  :header-cell-style="headStyle" :cell-style="rowStyle"
								:data="item.tableData" border>
								<el-table-column label-width="55" v-if="!item.type&&item.tableData.length>0">
									 <template slot-scope="scope">
										 <el-radio size="medium" :label="scope.row.standardAlevelId" v-model="userInfor.AlevelId"  @click.native.stop.prevent="handleRowChange(7,scope.row,item)">&nbsp;</el-radio>
									 </template>
								 </el-table-column>
								<el-table-column prop="examDate" label="考试时间"></el-table-column>
								<el-table-column prop="exam" label="考试类型">
									<template slot-scope="scope">
										<div class="gradeTable_table_select">
											<div class="table_select_inner">
												<span class="">{{scope.row.type?typeText(scope.row.type):''}}</span>
											</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column v-for="(item,index) in aLevelSubjectData" :key="index"
									:prop="'subjectScore'+item.standardAlevelExtendId" :label="item.subjectName">
									<template slot-scope="scope">
										<div class="gradeTable_table_ipt">{{scope.row['subjectScore'+item.standardAlevelExtendId]}}</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<!-- IB -->
					<div v-if="filterArr(item.currentTab, 8)">
						<div class="handle_form_row flex_between m-b">
							<div class="left-img">
								<span class="handle_form_label">IB</span>
								<img src="@/assets/images/common/go-icon.png" alt="" class="go-icon" @click="handleGoPath(8)">
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="状态:">
									<el-select v-model="item.state" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<el-table class="gradeTable_table_inner"  :header-cell-style="headStyle" :cell-style="rowStyle"
							:data="item.tableData" border>
							<el-table-column label-width="55" v-if="!item.type&&item.tableData.length>0">
									<template slot-scope="scope">
										<el-radio size="medium" :label="scope.row.standardIbId" v-model="userInfor.IbId"  @click.native.stop.prevent="handleRowChange(8,scope.row,item)">&nbsp;</el-radio>
									</template>
								</el-table-column>
							<el-table-column prop="examDate" label="考试时间"></el-table-column>
							<el-table-column prop="type" label="分数类型">
								<template slot-scope="scope">
									<div class="gradeTable_table_value">
										<div v-for="(item,index) in scope.row.type" :key="index" class="table_value_h360">
											{{item == '1'? 'IB Predict Score' : 'IB Actual Score'}}
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="grossScore" label="总分">
								<template slot-scope="scope">
									<div class="gradeTable_table_value">
										<div v-for="(item,index) in scope.row.grossScore" :key="index" class="table_value_h360">
											{{item.value}}
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="tokEe" label="TOK+EE">
								<template slot-scope="scope">
									<div class="gradeTable_table_value">
										<div v-for="(item,index) in scope.row.tokEe" :key="index" class="table_value_h360">
												{{item.value}}
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="subjectName" label="科目">
								<template slot-scope="scope">
									<div class="gradeTable_table_value">
										<div v-for="(item,index) in scope.row.subjectName" :key="index" class="table_value_h60">
											<div :class="{'border_bottom': index != 11}">
												<div class="gradeTable_table_ipt60">
													{{item.value}}
												</div>
											</div>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="level" label="科目等级">
								<template slot-scope="scope">
									<div v-for="(item,index) in scope.row.level" :key="index" class="table_value_h60">
										<div :class="{'border_bottom': index != 11}" class="gradeTable_table_select">
											<div class="table_select_inner">
												<span
													class="">{{item.value == '1'?'High Level': item.value == '2'? 'Standard Level': ''}}</span>
											</div>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="subjectScore" label="分数">
								<template slot-scope="scope">
									<div class="gradeTable_table_value">
										<div v-for="(item,index) in scope.row.subjectScore" :key="index"
											class="table_value_h60">
											<div :class="{'border_bottom': index != 11}">
												<div class="gradeTable_table_ipt60">
													{{item.value}}
												</div>
											</div>
										</div>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<!-- GRE -->
					<div v-if="filterArr(item.currentTab, 9)" class="flex_column">
						<div class="handle_form_row flex_between m-b">
							<div class="left-img">
								<span class="handle_form_label">GRE</span>
								<img src="@/assets/images/common/go-icon.png" alt="" class="go-icon" @click="handleGoPath(9)">
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="状态:">
									<el-select v-model="item.state" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_table">
							<el-table class="form_table_inner"  :header-cell-style="headStyle" :cell-style="rowStyle"
								:data="item.tableData" border>
								<el-table-column label-width="55" v-if="!item.type&&item.tableData.length>0">
									 <template slot-scope="scope">
										 <el-radio size="medium" :label="scope.row.standardGreId" v-model="userInfor.GreId"  @click.native.stop.prevent="handleRowChange(9,scope.row,item)">&nbsp;</el-radio>
									 </template>
								 </el-table-column>
								<el-table-column prop="examDate" label="考试时间"></el-table-column>
								<el-table-column prop="grossScore" label="Verbal Reasoning + Quantitative Reasoning(总分340)">
								</el-table-column>
								<el-table-column prop="verbal" label="Verbal Reasoning(满分170分)+百分比">
								</el-table-column>
								<el-table-column prop="quantitative" label=" Quantitative Reasoning(满分170分)+百分比">
								</el-table-column>
								<el-table-column prop="analytical" label="Analytical Writing(满分6分)+百分比">
								</el-table-column>
								<el-table-column prop="model" label="考试模式">
									<template slot-scope="scope">
										<div class="">
											{{scope.row.model==1?"线上":scope.row.model==2?"线下":''}}
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="writingScore" label="写作">
								</el-table-column>
							</el-table>
						</div>
					</div>
					<!-- GMAT -->
					<div v-if="filterArr(item.currentTab, 10)" class="flex_column">
						<div class="handle_form_row flex_between m-b">
							<div class="left-img">
								<span class="handle_form_label">GMAT</span>
								<img src="@/assets/images/common/go-icon.png" alt="" class="go-icon" @click="handleGoPath(10)">
							</div>
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="状态:">
									<el-select v-model="item.state" class="handle_form_select" clearable
										placeholder="请选择">
										<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_table">
							<el-table class="form_table_inner"  :header-cell-style="headStyle" :cell-style="rowStyle"
								:data="item.tableData" border>
								<el-table-column label-width="55" v-if="!item.type&&item.tableData.length>0">
									 <template slot-scope="scope">
										 <el-radio size="medium" :label="scope.row.standardGmatId" v-model="userInfor.GmatId"  @click.native.stop.prevent="handleRowChange(10,scope.row,item)">&nbsp;</el-radio>
									 </template>
								 </el-table-column>
								<el-table-column prop="examDate" label="考试时间"></el-table-column>
								<el-table-column prop="grossScore" label="总分(800)">
								</el-table-column>
								<el-table-column prop="quantitative" label="Quantitative Reasoning">
								</el-table-column>
								<el-table-column prop="verbal" label="Verbal Reasoning">
								</el-table-column>
								<el-table-column prop="analytical" label="Analytical Writing">
								</el-table-column>
								<el-table-column prop="integrated" label="Integrated Reasoning">
								</el-table-column>
								<el-table-column prop="model" label="考试模式">
									<template slot-scope="scope">
										<div class="">
											{{scope.row.model==1?"线上":scope.row.model==2?"线下":''}}
										</div>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
					<div class="handle_form_row">
						<div class="handle_form_item">
							<el-form-item label-width="60px" class="flex_column" label="上传图片:">
								<div class="margin_top_10">
									<dragUpLoad ref="dragUpLoad" :fileList="item.appendixUrl" :fileNum="index"  @beforeUpload="beforeUpload" @removeImg="removeImg"/>
								</div>
							</el-form-item>
						</div>
						<div class="handle_form_item">
							<el-form-item label-width="40px" class="flex_column" label="备注:">
								<textarea class="handle_form_textarea" v-model="item.remark"></textarea>
							</el-form-item>
						</div>
					</div>
					<div class="submit-user">
						<div class="mt" @click="handleDelete(item,index)">
							<img class="delete-icon" src="@/assets/images/common/delete-icon.png" alt="">
							<span class="delete-text">删除任务</span>
						</div>
						<div class="handle_form_user">
							<span v-if="item.createTime">创建时间：{{item.createTime}}</span>
							<span v-if="item.updateTime" class="margin_right_66">提交时间：{{item.updateTime}}</span>
							<span  v-if="item.updateUser">提交人：{{item.updateUser}}</span>
						</div>
					</div>
					
					<div class="line"></div>
				</div>
				</div>
			</el-form>
			<div class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span v-preventReClick @click="submitStandard" class="handle_form_confirm">确定</span>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import Bus from "./eventBus.js";
	import dragUpLoad from "@/components/dragUpLoad/dragUpload.vue";
	import moduleList from "@/components/moduleList/index.vue";
	import {
		standarFormList,
		standarFormSave,
		batchList,
		batchSave
	} from "@/api/acTable"
	import {
		gradeDuolinStudents,
		gradeSatStudents,
		gradeSat2Students,
		gradeYasiStudents,
		gradeTofuStudents,
		gradeSat2SubjectStudents,
		gradeApStudents,
		gradeALevelStudents,
		gradeALevelSubjectStudents,
		gradeGreStudents,
		gradeGmatStudents,
		gradeIbStudents

	} from "@/api/common"
	export default {
		components: {
			dragUpLoad,
			moduleList
		},
		props: {

		},
		watch: {},
		mounted() {
			Bus.$on("handleStandardForm", props => {
				// console.log(props)
				this.propsData = props.row
				this.diaLogVisible = true
				this.userInfor = {
					tofuId:"",
					yasiId:"",
					duolinguoId:"",
					SATId:"",
					Sat2Id:"",
					ApId:"",
					AlevelId:"",
					IbId:"",
					GreId:"",
					GmatId:""
				}
				this.standardId = ""
				this.studentId = this.propsData.studentId
				this.getStandFormList()
			})
		},
		data() {
			return {
				studentId:[],
				diaLogTitle: "标化",
				diaLogVisible: false,
				standardDetail: {},
				standardRules: {},
				// currentTab: [],
				sat2SubjectData:[],
				tableData:[],
				propsData:[],
				mainTeachers:[
					{
					value: '进行中',
					label: '进行中'
					}, {
					value: '已完成',
					label: '已完成'
					}
				],
				moduleList: [{
						label: "托福",
						value: 1
					},
					{
						label: "雅思",
						value: 2
					},
					{
						label: "多邻国",
						value: 3
					},
					{
						label: "SAT",
						value: 4
					},
					{
						label: "SAT2",
						value: 5
					},
					{
						label: "AP",
						value: 6
					},
					{
						label: "ALevel",
						value: 7
					},
					{
						label: "IB",
						value: 8
					},
					{
						label: "GRE",
						value: 9
					},
					{
						label: "GMAT",
						value: 10
					},
				],
				userInfor:{
					tofuId:"",
					yasiId:"",
					duolinguoId:"",
					SATId:"",
					Sat2Id:"",
					ApId:"",
					AlevelId:"",
					IbId:"",
					GreId:"",
					GmatId:""
				},
				standardId:"",
				deleteIds:[],
				isFinish:false
			}
		},
		methods: {
			handleGoPath(label){
				this.$router.push({
					path: `/studentInformation?label=${label}&&studentId=${this.studentId}`,
				});
			},
			channelInputLimit(e) {
		      e.returnValue = ''
		    },
			typeText(val) {
				let mapObj = {
					"1": "IGCES",
					"2": "As Level",
					"3": "A Level",
				}
				return mapObj[val]
			},
			//删除任务
			handleDelete(item,index){
				if(!item.id){
					this.standardDetail.controlpanelStandards.splice(index,1)
					this.standardDetail.count--
				}else{
					this.deleteIds.push(item.id)
					this.standardDetail.controlpanelStandards.splice(index,1)
					this.standardDetail.count--
				}
			},
			//查看标化成绩
			getStandFormList(){
				var params = {
					studentId: this.propsData.studentId,
					schoolId:this.propsData.schoolId
				}
				standarFormList(params).then(res=>{
					if(res.code == 0){
						if(res.data){
							if(res.data.controlpanelStandards.length>0){
								var standars = res.data.controlpanelStandards
								standars.forEach(ite=>{
									// ite.type = 'edit'
									ite.standard = JSON.parse(ite.standard)
									ite.currentTab = []
									ite.tableData = []
									if(ite.standards&&ite.standards.length>0){
									ite.standards.forEach(el=>{
										if(el.standard){
											ite.tableData.push(el.standard)
											ite.currentTab.push(el.standardType)
											ite.type = 'edit'
										}else{
											ite.currentTab.push(el.standardType)
											this.changeTab(ite,el.standardType,'edit')
										}
									})
									}
								})
							}
							this.standardDetail = res.data
							// console.log(this.standardDetail,'qwqewqewqewq')
						}
					}
				})
			},
			handleRowChange (type,val,item) {
				// this.standardId = ""
				// console.log(999999)
				// return
				// console.log(type,val,'单选事件')
				// return
				switch(type){
					case 1:
						// console.log(val.standardToeflId,this.userInfor.tofuId,66666666)
						if(val.standardToeflId==this.userInfor.tofuId){
							// console.log('相等')
							this.userInfor.tofuId = ""
							this.standardId = ""
						}else{
							this.userInfor.tofuId=val.standardToeflId
							this.standardId = val.standardToeflId
							// console.log('两个不相等')
						}
						// this.userInfor.tofuId = val.standardToeflId
						// this.standardId = val.standardToeflId
						break;
					case 2:
						if(val.standardIeltsId==this.userInfor.yasiId){
							this.userInfor.yasiId = ""
							this.standardId = ""
						}else{
							this.userInfor.yasiId = val.standardIeltsId
							this.standardId = val.standardIeltsId
						}
						break;
					case 3:
						if(val.standardDuolingoId==this.userInfor.duolinguoId){
							this.userInfor.duolinguoId = ""
							this.standardId = ""
						}else{
							this.userInfor.duolinguoId = val.standardDuolingoId
							this.standardId = val.standardDuolingoId
						}
						// this.userInfor.duolinguoId = val.standardDuolingoId
						// this.standardId = val.standardDuolingoId
						break;
					case 4:
						if(val.standardSatId==this.userInfor.SATId){
							this.userInfor.SATId = ''
							this.standardId = ''
						}else{
							this.userInfor.SATId = val.standardSatId
							this.standardId = val.standardSatId
						}
						// this.userInfor.SATId = val.standardSatId
						// this.standardId = val.standardSatId
						break;
					case 5:
						if(val.standardSat2Id==this.userInfor.Sat2Id){
							this.userInfor.Sat2Id = ''
							this.standardId = ''
						}else{
							this.userInfor.Sat2Id = val.standardSat2Id
							this.standardId = val.standardSat2Id
						}
						// this.userInfor.Sat2Id = val.standardSat2Id
						// this.standardId = val.standardSat2Id
						break;
					case 6:
						if(val.standardApId==this.userInfor.ApId){
							this.userInfor.ApId = ''
							this.standardId = ''
						}else{
							this.userInfor.ApId = val.standardApId
							this.standardId = val.standardApId
						}
						// this.userInfor.ApId = val.standardApId
						// this.standardId = val.standardApId
						break;
					case 7:
						if(val.standardAlevelId==this.userInfor.AlevelId){
							this.userInfor.AlevelId = ''
							this.standardId = ''
						}else{
							this.userInfor.AlevelId = val.standardAlevelId
							this.standardId = val.standardAlevelId
						}
						// this.userInfor.AlevelId = val.standardAlevelId
						// this.standardId = val.standardAlevelId
						break;
					case 8:
						if(val.standardIbId==this.userInfor.IbId){
							this.userInfor.IbId = ''
							this.standardId = ''
						}else{
							this.userInfor.IbId = val.standardIbId
							this.standardId = val.standardIbId
						}
						// this.userInfor.IbId = val.standardIbId
						// this.standardId = val.standardIbId
						break;
					case 9:
						if(val.standardGreId==this.userInfor.GreId){
							this.userInfor.GreId = ''
							this.standardId = ''
						}else{
							this.userInfor.GreId = val.standardGreId
							this.standardId = val.standardGreId
						}
						// this.userInfor.GreId = val.standardGreId
						// this.standardId = val.standardGreId
						break;
					case 10:
						if(val.standardGmatId==this.userInfor.GmatId){
							this.userInfor.GmatId = ''
							this.standardId = ''
						}else{
							this.userInfor.GmatId = val.standardGmatId
							this.standardId = val.standardGmatId
						}
						// this.userInfor.GmatId = val.standardGmatId
						// this.standardId = val.standardGmatId
						break;
				}
				this.getRadioData(type,item)
			},

			headStyle() {
				return 'text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;'
			},
			rowStyle() {
				return 'text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;height:44px;'
			},
			// 托福成绩操作
			studentsGradeTofuHandle(params,item) {
				// 托福列表
				gradeTofuStudents(params).then((res) => {
					if (res.code == 0) {
						item.tableData = res.data
					}
				})
			},
			getRadioData(type,item){
				let obj = {
					standardId:this.standardId,
					standardType:type
				}
				item.standard = [obj]
				// if(item.standard.length>0){
				// 	item.standard.forEach((el,index)=>{
				// 		if(type==el.standardType){
				// 			item.standard.splice(index,1,obj)
				// 		}else{
				// 			item.standard.push(obj)
				// 		}
				// 	})
				// }else{
				// 	item.standard.push(obj)
				// }
				// item.standard=[...new Set(item.standard)]
				// console.log(item.standard)
			},
			// 雅思成绩操作
			studentsGradeYasiHandle(params,item) {
				// 雅思列表
				gradeYasiStudents(params).then((res) => {
					// console.log(res)
					if (res.code == 0) {
						item.tableData = res.data
					}
				})
			},
			// 多邻国成绩操作
			studentsGradeDuolinHandle(params,item) {
					// 多邻国列表
					gradeDuolinStudents(params).then((res) => {
						if (res.code == 0) {
							item.tableData = res.data
						}
					})
			},
			// SAT成绩操作
			studentsGradeSatHandle(params,item) {
					// SAT列表
					gradeSatStudents(params).then((res) => {
						if (res.code == 0) {
							item.tableData = res.data
							// console.log(this.SatData)
						}
					})
			},
			// SAT2成绩操作
			async studentsGradeSat2Handle(params,item) {
				// SAT2列表
				await this.studentsGradeSat2Subject(params)
				gradeSat2Students(params).then((res) => {
					if (res.code == 0) {
						item.tableData = res.data
						if (item.tableData.length > 0) {
								item.tableData.forEach(itemOne => {
									if (itemOne.subjectScores && itemOne.subjectScores.length > 0) {
										itemOne.subjectScores.forEach(itemTwo => {
											this.$set(itemOne,
												`subjectScore${itemTwo.standardSat2ExtendId}`,
												itemTwo.subjectScore)
										})
									}
								})
							}
					}
				})
			},
			// 获取SAT2科目
			studentsGradeSat2Subject(params) {
				return new Promise((resolve, reject) => {
					let params = {
						studentInfoId: this.studentId
					}
					gradeSat2SubjectStudents(params).then((res) => {
						if (res.code == 0) {
							this.sat2SubjectData = res.data
							// console.log(this.sat2SubjectData)
							resolve(this.sat2SubjectData)
						} else {
							reject()
						}
					})
				})
			},
			// Ap成绩操作
			studentsGradeApHandle(params,item) {
					// Ap列表
					gradeApStudents(params).then((res) => {
						if (res.code == 0) {
							item.tableData = res.data
						}
					})
			},
			// Alevel成绩操作
			async studentsGradeALevelHandle(params,item) {
				// Alevel科目列表
				await this.studentsGradeALevelSubject()
				// Alevel列表
				gradeALevelStudents(params).then((res) => {
					if (res.code == 0) {
						if (res.data) {
							item.tableData = res.data
							if (item.tableData.length > 0) {
								item.tableData.forEach(itemOne => {
									if (itemOne.subjectScores && itemOne.subjectScores.length >
										0) {
										itemOne.subjectScores.forEach(itemTwo => {
											this.$set(itemOne,
												`subjectScore${itemTwo.standardAlevelExtendId}`,
												itemTwo.subjectScore)
										})
									}
								})
							}
						}
					}
				})
			},
			// 获取Alevel科目
			studentsGradeALevelSubject() {
				this.aLevelSubjectData = []
				return new Promise((resolve, reject) => {
					let params = {
						studentInfoId: this.studentId
					}
					gradeALevelSubjectStudents(params).then((res) => {
						if (res.code == 0) {
							this.aLevelSubjectData = res.data
							resolve(this.aLevelSubjectData)
						} else {
							reject()
						}
					})
				})
			},
			// Ib成绩操作
			studentsGradeIbHandle(params,item) {
					// Ib列表
				gradeIbStudents(params).then((res) => {
					if (res.code == 0) {
						if (res.data) {
							let resData = res.data
							let resKeys = Object.keys(resData)
							let newArr = []
							// console.log("resKeys", resKeys)
							if (resKeys && resKeys.length > 0) {
								resKeys.forEach(itemOne => {
									let newObj = {
										recordNumber: "",
										standardIbId: "",
										studentAccountId: "",
										examDate: "",
										type: [],
										grossScore: [],
										tokEe: [],
										subjectName: [],
										level: [],
										subjectScore: []
									}
									resData[itemOne].forEach(async itemTwo => {
										newObj.examDate = itemTwo.examDate
										newObj.recordNumber = itemTwo.recordNumber
										newObj.standardIbId = itemTwo.standardIbId
										newObj.studentAccountId = itemTwo.studentAccountId
										newObj.type.push(itemTwo.type)
										newObj.type = [...new Set(newObj.type)]
										newObj.grossScore.push({
											type: itemTwo.type,
											value: itemTwo.grossScore,
										})
										newObj.grossScore = await this.reduceArrIB(newObj
											.grossScore)
										newObj.tokEe.push({
											type: itemTwo.type,
											value: itemTwo.tokEe,
										})
										newObj.tokEe = await this.reduceArrIB(newObj.tokEe)
										newObj.subjectName.push({
											type: itemTwo.type,
											value: itemTwo.subjectName,
											standardIbId: itemTwo.standardIbId
										})
										newObj.level.push({
											type: itemTwo.type,
											value: itemTwo.level,
										})
										newObj.subjectScore.push({
											type: itemTwo.type,
											value: itemTwo.subjectScore,
										})
									})
									newArr.push(newObj)
								})
							}
							// console.log("newArr", newArr)
							item.tableData = newArr
						}
					}
				})
			},
			// Gre成绩操作
			studentsGradeGreHandle(params,item) {
					// Gre列表
					gradeGreStudents(params).then((res) => {
						if (res.code == 0) {
							item.tableData = res.data
						}
					})
			},
			// Gmat成绩操作
			studentsGradeGmatHandle(params,item) {
					// Gmat列表
					gradeGmatStudents(params).then((res) => {
						if (res.code == 0) {
							item.tableData = res.data
						}
					})
			},
			//提交标化成绩
			submitStandard(){
				let editParams = JSON.parse(JSON.stringify(this.standardDetail))
				editParams.controlpanelStandards.forEach(el=>{
					
					if(el.currentTab.length==0){
						this.$message({
							message: "请选择标化~",
							type: 'warning'
						})
						this.isFinish = true
					}else{
						el.studentId = this.propsData.studentId
						el.schoolId = this.propsData.schoolId
						el.standard = JSON.stringify(el.standard)
						delete el.currentTab
						delete el.tableData
						this.isFinish = false
					}
				})
				if(this.isFinish==true){
					return
				}
				const newArr = Array.from(new Set(this.deleteIds))
				editParams.deleteIds = newArr.join(',')
				// console.log(editParams,99999)
				standarFormSave(editParams).then(res=>{
					if(res.code == 0){
						this.$message({
							message: '编辑成功',
							type: 'success'
						});
						this.closeDialog()
						this.$parent.getAcLists()
					}
				})
			},
			// 数据去重
			reduceArr(intArr) {
				return new Promise((resolve, reject) => {
					let emptyArr = []
					let emptyObj = {}
					emptyArr = intArr.reduce((item, next) => {
						emptyObj[next] ? "" : emptyObj[next] = true && item.push(next)
						return item
					}, [])
					resolve(emptyArr)
				})
			},
			// Ib数据去重
			reduceArrIB(intArr) {
				return new Promise((resolve, reject) => {
					let emptyArr = []
					let emptyObj = {}
					emptyArr = intArr.reduce((item, next) => {
						// console.log("reduceArr>>>>>", next)
						emptyObj[next.type] ? "" : emptyObj[next.type] = true && item.push(next)
						return item
					}, [])
					// console.log("reduceArr>>>>>", emptyArr)
					resolve(emptyArr)
				})
			},
			//点击计数器添加任务数
			inputChange(index){
				var params ={
					state:"",
					currentTab:[],
					tableData:[],
					standard:[],
					remark:"",
					appendixUrl:"",
					submitTime:"",
					submitUser:""
				}
				this.standardDetail.controlpanelStandards.push(params)
			},
			// 判断数组是否包含某一项
			filterArr(intArr, num) {
				return intArr.some(item => {
					return item == num
				})
			},
			beforeUpload(props) {
				// console.log(props)
				this.standardDetail.controlpanelStandards[props.number].appendixUrl = props.fileData
				
			},
			removeImg(props) {
				this.standardDetail.controlpanelStandards[props.number].appendixUrl = ""
			},
			closeDialog() {
				this.diaLogVisible = false
			},
			changeTab(item,ite,way) {
				// console.log(item.id,way)
				// console.log(item,ite,"切换ap")
				if((item.id&&way=='change')){
					return
				}
				this.getRadioData(ite,item)
				var params = {
					studentInfoId:this.studentId
				}
				item.tableData = []
				switch(ite){
					case 1:
						this.studentsGradeTofuHandle(params,item)
						break;
					case 2:
						this.studentsGradeYasiHandle(params,item)
						break;
					case 3:
						this.studentsGradeDuolinHandle(params,item)
						break;
					case 4:
						this.studentsGradeSatHandle(params,item)
						break;
					case 5:
						this.studentsGradeSat2Handle(params,item)
						break;
					case 6:
						this.studentsGradeApHandle(params,item)
						break;
					case 7:
						this.studentsGradeALevelHandle(params,item)
						break;
					case 8:
						this.studentsGradeIbHandle(params,item)
						break;
					case 9:
						this.studentsGradeGreHandle(params,item)
						break;
					case 10:
						this.studentsGradeGmatHandle(params,item)
						break;

				}
				if(item.currentTab.length==0){
					item.currentTab.push(ite)
				}else{
					item.currentTab.splice(0,1,ite)
				}
				// console.log(item.currentTab)
				// item.currentTab.push(ite.value)
				// let newArr = await this.reduceArr(item.currentTab)
				// console.log(newArr)
				// item.currentTab = [].concat(newArr)
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	.left-img{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.m-b{
		margin-bottom:10px;
	}
	/deep/.el-input :focus {
		border: 1px solid $theme_color;
	}

	/deep/.el-table__header .cell {
		padding: 0 !important;
	}

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex_column {
		display: flex;
		flex-direction: column;
	}

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.margin_top_10 {
		margin-top: 10px;
	}
	.submit-user{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top:24px;
	}
	.handle_form_user{
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.handle_form {
		max-height: 700px;
			overflow: hidden;
			overflow: auto;
		.handle_form_content {
			// max-height: 500px;
			// overflow: hidden;
			// overflow-y: auto;
		}
		.line{
			height: 1px;
			background: #DDDDDD;
			margin-top:22px;
		}
			.mt{
				cursor: pointer;
			}
			.margin_right_66 {
				margin:0 66px;
			}
			.delete-icon{
				width:14px;
				height:14px;
				margin-right:8px;
			}
			.delete-text{
				font-size:14px;
			}
			.go-icon{
				width:14px;
				height:14px;
				margin-left:8px;
				cursor: pointer;
			}
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			// padding: 0 23px;
			
			.handle_form_label {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #333333;
			}

			.handle_form_item {
				display: flex;

				.handle_form_num {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #333333;

					.form_num_green {
						color: $theme_color;
					}
				}

				.handle_form_date {
					width: 200px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					margin-top: 9px;
				}

				.handle_form_select {
					width: 200px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
				}

				.handle_form_textarea {
					width: 673px;
					height: 140px;
					background: #FFFFFF;
					border: 1px solid #EEEEEE;
					border-radius: 4px;
					outline: none;
					resize: none;
					padding: 6px 10px;
					box-sizing: border-box;
					margin: 8px 0 0 0px;
				}

				.handle_form_tab {
					display: flex;
					align-items: center;
					margin: 10px 0;

					.form_tab_item {
						background: #FFFFFF;
						border-radius: 18px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						text-align: center;
						cursor: pointer;
						margin-right: 10px;
						box-sizing: border-box;
						user-select: none;
						width: 62px;
						line-height: 26px;
						font-size: 14px;
						color: #333333;
						border: 1px solid #EEEEEE;

						&.tab_active {
							border: 1px solid $theme_color;
							color: $theme_color;
						}
					}
				}

				.handle_form_upload {
					display: flex;
					flex-direction: column;

					.form_upload_box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 128px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #EEEEEE;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #000000;
						cursor: pointer;

						.form_upload_icon {
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
					}

					.form_upload_tip {
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 17px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}
		}
		
		.handle_form_ipt {
			width: 100%;
			outline: none;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			line-height: 30px;
			color: #000000;
			box-sizing: border-box;
			border: 0;
		}

		.handle_form_table {
			width: 100%;
			margin-top: 12px;
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #FFFFFF;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFF;
				text-align: center;
				cursor: pointer;
			}
		}
	}
			.gradeTable_table {
			flex: 1;
			display: flex;
			flex-direction: column;

			.gradeTable_table_box {
				flex: 1;
			}
			}
				.gradeTable_table_inner {
					// position: absolute;
					width: 100%;
					display: flex;
					flex-direction: column;
					.border_bottom {
						height: 60px;
						display: flex;
						align-items: center;
						border-bottom: 1px solid #EBEEF5;
					}
					.gradeTable_table_value {
						height: 100%;
						.table_value_h360 {
							line-height: 360px;
							.gradeTable_table_ipt360 {
								width: 100%;
								outline: none;
								box-sizing: border-box;
								height: 360px;
								border: 0;
								text-align: center;
								padding: 0 10px;
							}
						}
						.table_value_h60 {
							height: 60px;
							.gradeTable_table_ipt60 {
								width: 100%;
								outline: none;
								box-sizing: border-box;
								height: 59px;
								border: 0;
								text-align: center;
								padding: 0 10px;
							}
						}
					}
					.gradeTable_table_value :first-child.table_value_h360 {
						border-bottom: 1px solid #EBEEF5;
					}
					.gradeTable_table_date .el-date-editor {
						width: 100%;
						position: absolute; //绝对定位
						top: 0;
						left: 10px;
						opacity: 0; //设置完全透明
					}
					.gradeTable_table_ipt {
						width: 100%;
						outline: none;
						box-sizing: border-box;
						height: 60px;
						border: 0;
						text-align: center;
						padding: 0 10px;
					}

					.gradeTable_table_select {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;

						.table_select_inner {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							height: 60px;

							span {
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								line-height: 20px;
								color: #333333;
								margin-right: 8px;
							}

							img {
								width: 16px;
								height: 16px;
							}
						}
					}

					.gradeTable_table_action {
						width: 100%;
						height: 60px;
						display: flex;
						align-items: center;
						justify-content: center;

						span {
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 0px;
							color: $theme_color;
							cursor: pointer;
							margin: 0 8px;
						}
					}

					.gradeTable_table_select .el-select {
						position: absolute; //绝对定位
						top: 0;
						// left: 0px;
						opacity: 0; //设置完全透明
					}
				}
				/deep/.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner{
				box-shadow: none !important;
				}
</style>
