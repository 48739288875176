<template>
	<div class="editDraw" v-if="editDraw">
		<div class="editDraw_inner">
			<div class="editDraw_inner_back">
				<div @click="closeDraw" class="inner_back_btn">
					<img src="@/assets/images/common/back.png">
					<span>返回</span>
				</div>
			</div>
			<div class="editDraw_inner_content">
				<div class="editDraw_inner_school">
					<div class="inner_school_name">
						<img :src="schoolDetail.logo">
						<div class="school_name_country">
							<span class="school_name_chinese">{{schoolDetail.name}}</span>
							<span v-if="schoolDetail.enName" class="school_name_english">{{schoolDetail.enName}}</span>
						</div>
					</div>
					<div @click="handleFn('schoolAdd',schoolDetail)" class="inner_school_add">
						<img src="@/assets/images/applicationProgress/add.png">
						<span>添加</span>
					</div>
				</div>
				<!-- 词条 -->
				<div class="editDraw_inner_taglist">
					<div @mouseenter="showCurrentTag('show',item)" @mouseleave="showCurrentTag('hide')"
						v-for="(item, index) in tagList" :key="index" class="editDraw_inner_tagbox">
						<span @click="handleFn('wordEdit', item)" class="editDraw_inner_tagitem">{{item.keyword}}</span>
						<div v-if="currentTag == item.id" class="editDraw_inner_tagbg">
							<span>创作者：{{item.createUser}}</span>
							<span>创作时间：{{dateFormat(item.createTime)}}</span>
						</div>
					</div>
					<div @click="handleFn('wordAdd')" class="add_btn margin_bottom10">
						<img src="@/assets/images/dashboard/add.png">
						<span>添加词条</span>
					</div>
				</div>
				<!-- 简介 -->
				<div v-if="schoolDetail.introduction" class="editDraw_inner_desc">
					<div :class="{'inner_desc_ellipsis': !showMoreDesc}" class="inner_desc_content">
						简介：{{schoolDetail.introduction}}
					</div>
					<!-- 展示更多 -->
					<div @click.stop="drawHandle('more')" class="inner_desc_more">
						<span v-if="!showMoreDesc">展开</span>
						<span v-if="showMoreDesc">收起</span>
						<img v-if="!showMoreDesc" src="@/assets/images/applicationProgress/arrow_down.png">
						<img v-if="showMoreDesc" src="@/assets/images/applicationProgress/arrow_up.png">
					</div>
				</div>
				<div class="editDraw_inner_mark">
					<div class="inner_mark_row">
						<div class="inner_mark_item">
							<span class="inner_mark_label">排名:</span>
							<span class="inner_mark_value">{{schoolDetail.ranking}}</span>
						</div>
						<div class="inner_mark_item">
							<span class="inner_mark_label">类型:</span>
							<span class="inner_mark_value">{{schoolDetail.type}}</span>
						</div>
						<div class="inner_mark_item">
							<span class="inner_mark_label">录取率:</span>
							<span class="inner_mark_value">{{schoolDetail.hiringrate}}</span>
						</div>
					</div>
					<div class="inner_mark_row">
						<div class="inner_mark_item">
							<span class="inner_mark_label">申请系统:</span>
							<span class="inner_mark_value">{{schoolDetail.applysystem}}</span>
						</div>
						<div class="inner_mark_item">
							<span class="inner_mark_label">学校官网:</span>
							<span class="inner_mark_value">{{schoolDetail.officialurl}}</span>
						</div>
					</div>
					<div class="inner_mark_row">
						<div class="inner_mark_item">
							<span class="inner_mark_label">本科:</span>
							<span class="inner_mark_value">{{schoolDetail.undergraduateBatch}}</span>
						</div>
					</div>
					<div class="inner_mark_row">
						<div class="inner_mark_item">
							<span class="inner_mark_label">转学:</span>
							<span class="inner_mark_value">{{schoolDetail.transferBatch}}</span>
						</div>
					</div>
				</div>
				<!-- Tab菜单 -->
				<div class="editDraw_inner_tab">
					<div class="inner_tab_head">
						<el-tabs v-model="currentTab" @tab-click="changeTab">
							<el-tab-pane v-for="(item,index) in tabList" :key="index" :label="item.label"
								:name="item.label">
							</el-tab-pane>
						</el-tabs>
					</div>
					<div class="inner_tab_content">
						<!-- 文书 -->
						<div v-if="currentTab == '文书'" class="inner_tab_writ">
							<div class="tab_writ_search">
								<div class="tab_writ_checkbox">
									<radioGroup :currentRadio="writData.type+''" :isCheckBox="true"
										@checkRadio="writRadio" :radioList="writRadios" />
								</div>
								<div class="tab_writ_date">
									<div class="writ_date_inner">
										<span>{{writData.date}}</span>
										<img src="@/assets/images/applicationProgress/arrow_down_dark.png">
									</div>
									<el-date-picker v-model="writData.date" @blur="dateBlur" @focus="dateFocus"
										type="year" placeholder="选择年">
									</el-date-picker>
								</div>
							</div>
							<div class="tab_writ_list">
								<div v-for="(item,index) in writData.writList" :key="index" class="tab_writ_item">
									<div class="writ_item_title">
										<span>{{index*1 + 1}}.{{item.title}}</span>
										<img @click="handleFn('writEdit', item)"
											src="@/assets/images/dashboard/edit_black.png">
									</div>
									<div class="writ_item_content">
										{{item.content}}
									</div>
								</div>
							</div>
							<div @click="handleFn('writAdd')" class="add_btn">
								<img src="@/assets/images/dashboard/add.png">
								<span>添加文书</span>
							</div>
						</div>
						<!-- 标化 -->
						<div v-if="currentTab == '标化'" class="inner_tab_standard">
							<div class="tab_standard_search">
								<radioGroup :currentRadio="standardData.type+''" :isCheckBox="true"
									@checkRadio="standardRadio" :radioList="standardRadios" />
								<div v-if="!standardEdit" @click="handleFn('standardEdit')" class="inner_tab_edit">
									<i class="el-icon-edit"></i>
									<span>编辑</span>
								</div>
							</div>
							<div class="tab_standard_content">
								<div class="table_box">
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_260">
												TOEFL/IEILS是否需要提交
											</div>
											<div class="table_value">
												<radioGroup :currentRadio="standardData.isneedTi+''"
													@checkRadio="isneedTiRadio" :radioList="radioGroupList" />
											</div>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_260">
												TOEFL最低分
											</div>
											<input :disabled="!standardEdit" v-model="standardData.toefl"
												placeholder="请输入TOEFL最低分" class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_260">
												IEILS最低分
											</div>
											<input :disabled="!standardEdit" v-model="standardData.ieils"
												placeholder="请输入IEILS最低分" class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_260">
												是否接受托福家庭版
											</div>
											<div class="table_value">
												<radioGroup :currentRadio="standardData.isneedTfFamily+''"
													@checkRadio="isneedTfFamilyRadio" :radioList="radioGroupList" />
											</div>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_260">
												是否接受雅思家庭版
											</div>
											<div class="table_value">
												<radioGroup :currentRadio="standardData.isneedYsFamily+''"
													@checkRadio="isneedYsFamilyRadio" :radioList="radioGroupList" />
											</div>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_260">
												SAT/ACT是否需要提交
											</div>
											<div class="table_value">
												<radioGroup :currentRadio="standardData.isneedSatAct+''"
													@checkRadio="isneedSatActRadio" :radioList="radioGroupList" />
											</div>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_260">
												SAT科目是否需要提交
											</div>
											<div class="table_value">
												<radioGroup :currentRadio="standardData.isneedSatsubject+''"
													@checkRadio="isneedSatsubjectRadio" :radioList="radioGroupList" />
											</div>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_260">
												SAT作文或ACT写作测试是否需要提交
											</div>
											<div class="table_value">
												<radioGroup :currentRadio="standardData.isneedSatActWrite+''"
													@checkRadio="isneedSatActWriteRadio" :radioList="radioGroupList" />
											</div>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_260">
												AP或IB是否需要提交
											</div>
											<div class="table_value">
												<radioGroup :currentRadio="standardData.isneedApIb+''"
													@checkRadio="isneedApIbRadio" :radioList="radioGroupList" />
											</div>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_260">
												SAT中位值
											</div>
											<input :disabled="!standardEdit" v-model="standardData.sat"
												placeholder="请输入SAT中位值" class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_260">
												ACT中位值
											</div>
											<input :disabled="!standardEdit" v-model="standardData.act"
												placeholder="请输入ACT中位值" class="table_value border_bottom"></input>
										</div>
									</div>
								</div>
								<div v-if="standardEdit" class="submit_btn">
									<span @click="handleFn('standardCancle')" class="submit_btn_cancle">取消</span>
									<span v-preventReClick @click="handleFn('standardSubmit')" class="submit_btn_confirm">保存</span>
								</div>
							</div>
						</div>
						<!-- 专业 -->
						<div v-if="currentTab == '专业'" class="inner_tab_major">
							<div class="flex_between">
								<div @click="handleFn('majorAdd')" class="add_btn">
									<img src="@/assets/images/dashboard/add.png">
									<span>添加专业</span>
								</div>
								<div v-if="!majorEdit&&marjorList.length > 0" @click="handleFn('majorEdit')"
									class="inner_tab_edit">
									<i class="el-icon-edit"></i>
									<span>编辑</span>
								</div>
							</div>
							<div v-for="(item,index) in marjorList" :key="index" class="tab_major_content">
								<input @focus="majorFocus($event, item)" @input="majorInput($event)"
									:disabled="!majorEdit" v-model="item.name" placeholder="请输入专业名称"
									class="tab_major_value"></input>
							</div>
							<div v-if="majorEdit" class="submit_btn">
								<span @click="handleFn('majorCancle')" class="submit_btn_cancle">取消</span>
								<span v-preventReClick @click="handleFn('majorSubmit')" class="submit_btn_confirm">保存</span>
							</div>
						</div>
						<!-- 面试 -->
						<div v-if="currentTab == '面试'" class="inner_tab_interview">
							<div class="flex_item">
								<div v-if="!interviewEdit" @click="handleFn('interviewEdit')" class="inner_tab_edit">
									<i class="el-icon-edit"></i>
									<span>编辑</span>
								</div>
							</div>
							<div class="tab_interview_content">
								<div class="table_box">
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												面试类型
											</div>
											<div class="table_value">
												<div class="table_value_select">
													<span
														class="">{{interviewData.type?interviewData.type:'请选择'}}</span>
													<img src="@/assets/images/dashboard/moreArrow.png">
												</div>
												<el-select class="table_interview_select" :disabled="!interviewEdit"
													v-model="interviewData.type" placeholder="请选择">
													<el-option v-for="item in interviewSelect" :key="item.value"
														:label="item.label" :value="item.label">
													</el-option>
												</el-select>
											</div>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												网址
											</div>
											<input :disabled="!interviewEdit" v-model="interviewData.url"
												placeholder="请输入网址" class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_100">
												备注
											</div>
											<textarea :disabled="!interviewEdit" v-model="interviewData.remark"
												placeholder="请输入备注" class="table_textarea border_bottom"></textarea>
										</div>
									</div>
								</div>
							</div>
							<div v-if="interviewEdit" class="submit_btn">
								<span @click="handleFn('interviewCancle')" class="submit_btn_cancle">取消</span>
								<span v-preventReClick @click="handleFn('interviewSubmit')" class="submit_btn_confirm">保存</span>
							</div>
						</div>
						<!-- 学校介绍 -->
						<div v-if="currentTab == '学校介绍'" class="inner_tab_desc">
							<div class="flex_item">
								<div v-if="!descEdit" @click="handleFn('descEdit')" class="inner_tab_edit">
									<i class="el-icon-edit"></i>
									<span>编辑</span>
								</div>
							</div>
							<div class="tab_desc_content">
								<div class="table_box">
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												位置信息
											</div>
											<input :disabled="!descEdit" v-model="descData.address" placeholder="暂未填写"
												class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												在校生总数
											</div>
											<input :disabled="!descEdit" v-model="descData.number" placeholder="暂未填写"
												class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												本科生总数
											</div>
											<input :disabled="!descEdit" v-model="descData.undergraduateTotal"
												placeholder="暂未填写" class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												男女比例
											</div>
											<input :disabled="!descEdit" v-model="descData.maleFemaleRatio"
												placeholder="暂未填写" class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												上一届申请人数
											</div>
											<input :disabled="!descEdit" v-model="descData.lastApplyTotal"
												placeholder="暂未填写" class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												录取比例
											</div>
											<input :disabled="!descEdit" placeholder="暂未填写" class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												学术质量
											</div>
											<input :disabled="!descEdit" v-model="descData.academicQuality"
												placeholder="暂未填写" class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												社交活动
											</div>
											<input :disabled="!descEdit" v-model="descData.socialActivities"
												placeholder="暂未填写" class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												生活质量
											</div>
											<input :disabled="!descEdit" v-model="descData.lifeQuality"
												placeholder="暂未填写" class="table_value"></input>
										</div>
									</div>
									<div class="table_td">
										<div class="table_tr">
											<div class="table_label label_200">
												类似学校
											</div>
											<input :disabled="!descEdit" v-model="descData.similarSchool"
												placeholder="暂未填写" class="table_value border_bottom"></input>
										</div>
									</div>
								</div>
							</div>
							<div v-if="descEdit" class="submit_btn">
								<span @click="handleFn('descCancle')" class="submit_btn_cancle">取消</span>
								<span v-preventReClick @click="handleFn('descSubmit')" class="submit_btn_confirm">保存</span>
							</div>
						</div>
						<!-- 案例 -->
						<div v-if="currentTab == '案例'" class="inner_tab_case">
							<div class="flex_between">
								<div @click="handleFn('caseAdd')" class="add_btn">
									<img src="@/assets/images/dashboard/add.png">
									<span>添加案例</span>
								</div>
								<div v-if="!caseEdit&&caseList.length > 0" @click="handleFn('caseEdit')"
									class="inner_tab_edit">
									<i class="el-icon-edit"></i>
									<span>编辑</span>
								</div>
							</div>
							<div class="tab_case_list">
								<div v-for="(item,index) in caseList" :key="index" class="tab_case_item">
									<div class="table_box">
										<div class="table_td">
											<div class="table_tr">
												<div class="table_label label_200">
													案例
												</div>
												<input @focus="caseFocus($event, item)"
													@input="caseInput($event, 'name')" v-model="item.name"
													:disabled="!caseEdit" placeholder="请输入案例名称"
													class="table_value"></input>
											</div>
										</div>
										<div class="table_td">
											<div class="table_tr">
												<div class="table_label label_200">
													网址
												</div>
												<input @focus="caseFocus($event, item)"
													@input="caseInput($event, 'url')" v-model="item.url"
													:disabled="!caseEdit" placeholder="请输入案例网址"
													class="table_value border_bottom"></input>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-if="caseEdit" class="submit_btn">
								<span @click="handleFn('caseCancle')" class="submit_btn_cancle">取消</span>
								<span v-preventReClick @click="handleFn('caseSubmit')" class="submit_btn_confirm">保存</span>
							</div>
						</div>
						<!-- 更改 -->
						<div v-if="currentTab == '更改'" class="inner_tab_change">
							<div class="tab_change_list">
								<div v-for="(item,index) in logList" :key="index" class="tab_change_item">
									<div class="tab_change_td">
										<div class="tab_change_tr">
											<span class="tab_change_label">更改类型：</span>
											<span class="tab_change_tag">{{item.typeName}}</span>
										</div>
									</div>
									<div class="tab_change_td">
										<div class="tab_change_tr">
											<span class="tab_change_label">更改时间：</span>
											<span class="tab_change_value">{{item.updateTime}}</span>
										</div>
										<div class="tab_change_tr">
											<span class="tab_change_label">更改人姓名：</span>
											<span class="tab_change_value">{{item.updateUser}}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="currentTab == '更改'" class="flex_content">
							<el-pagination @current-change="currentChange" :current-page.sync="pageIndex"
								:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="editDraw_dialog">
			<!-- 添加词条弹窗 -->
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeTagDialog" width="579px" :title="diaLogTagTitle"
				:visible.sync="diaLogTagVisible">
				<div class="handle_form">
					<el-form ref="tagDetail" :rules="tagRules" :model="tagDetail" label-width="90px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" prop="createUser" label="创作者姓名:">
									<input v-model="tagDetail.createUser" placeholder="请输入创作者姓名"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" prop="keyWord" label="词条内容:">
									<textarea v-model="tagDetail.keyWord" placeholder="请输入词条内容"
										class="handle_form_textarea"></textarea>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeTagDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="handleFn('tagSubmit')" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
			<!-- 文本添加弹窗 -->
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeWritDialog" width="579px" :title="diaLogWritTitle"
				:visible.sync="diaLogWritVisible">
				<div class="handle_form">
					<el-form ref="writDetail" :rules="writRules" :model="writDetail" label-width="90px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="文书标题:">
									<input v-model="writDetail.title" placeholder="请输入文书标题"
										class="handle_form_ipt"></input>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" label="文书内容:">
									<textarea v-model="writDetail.content" placeholder="请输入文书内容"
										class="handle_form_textarea"></textarea>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeWritDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="handleFn('writSubmit')" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import Bus from "./eventBus.js";
import commonFn from "@/utils/common.js";
import radioGroup from "@/components/radioGroup/index.vue";
import {
  writSchoolList,
  writSchoolAdd,
  standardSchoolList,
  standardSchoolAdd,
  majorSchoolList,
  majorSchoolAdd,
  interviewSchoolList,
  interviewSchoolAdd,
  descSchoolList,
  descSchoolAdd,
  caseSchoolList,
  caseSchoolAdd,
  logSchoolList,
  tagSchoolList,
  tagSchoolEdit,
  tagSchoolAdd,
  listChooseSchoolAdd,
  selectSchoolList
} from "@/api/applicationProgress";
export default {
  components: {
    radioGroup
  },
  props: {
    studentId: {
      type: String,
      default: function() {
        return "";
      }
    }
  },
  data() {
    return {
      showDraw: false,
      showMoreDesc: false,
      currentTab: "文书",
      schoolDetail: {},
      currentTag: null,
      tagRules: {
        createUser: [
          {
            required: true,
            message: "请输入创作者姓名",
            trigger: "change"
          }
        ],
        keyWord: [
          {
            required: true,
            message: "请输入词条内容",
            trigger: "change"
          }
        ]
      },
      diaLogTagVisible: false,
      diaLogTagTitle: "",
      tabList: [
        {
          label: "文书",
          value: 1
        },
        {
          label: "标化",
          value: 2
        },
        {
          label: "专业",
          value: 3
        },
        {
          label: "面试",
          value: 4
        },
        {
          label: "学校介绍",
          value: 5
        },
        {
          label: "案例",
          value: 6
        },
        {
          label: "更改",
          value: 7
        }
      ],
      writRadios: [
        {
          label: "1",
          value: "本科文书"
        },
        {
          label: "2",
          value: "转学文书"
        }
      ],
      writData: {
        date: "",
        type: "1",
        writList: []
      },
      writRules: {},
      writDetail: {},
      diaLogWritVisible: false,
      diaLogWritTitle: "",
      standardEdit: false,
      standardData: {
        schoolId: "",
        id: 0,
        type: "1",
        ieils: "",
        isneedTi: "0",
        toefl: "",
        isneedTfFamily: "0",
        isneedYsFamily: "0",
        isneedSatAct: "0",
        isneedSatsubject: "0",
        isneedSatActWrite: "0",
        isneedApIb: "0",
        sat: "",
        act: ""
      },
      standardRadios: [
        {
          label: "1",
          value: "本科申请"
        },
        {
          label: "2",
          value: "转学申请"
        }
      ],
      radioGroupList: [
        {
          label: "1",
          value: "是"
        },
        {
          label: "2",
          value: "否"
        },
        {
          label: "0",
          value: "可选"
        }
      ],
      editDraw: false,
      majorEdit: false,
      marjorList: [],
      marjorData: {},
      interviewEdit: false,
      interviewData: {
        id: 0,
        schoolId: "",
        remark: "",
        url: "",
        type: ""
      },
      interviewSelect: [
        {
          label: "线上面试",
          value: "1"
        },
        {
          label: "线下面试",
          value: "2"
        }
      ],
      descEdit: false,
      descData: {
        id: 0,
        schoolId: "",
        address: "",
        number: "",
        undergraduateTotal: "",
        maleFemaleRatio: "",
        lastApplyTotal: "",
        academicQuality: "",
        socialActivities: "",
        lifeQuality: "",
        similarSchool: ""
      },
      caseEdit: false,
      caseList: [],
      caseData: {},
      logList: [],
      pageIndex: 1,
      pageSize: 5,
      totalCount: 0,
      tagList: [],
      tagDetail: {},
      addSchool: {
        schoolId: "",
        studentId: ""
      }
    };
  },
  created() {
    this.writData.date = commonFn.timeFormat(new Date(), "yyyy");
  },
  mounted() {
    Bus.$on("schoolHandle", props => {
      // console.log("schoolHandle", props)
    });
    Bus.$on("drawHandle", props => {
      // console.log("drawHandle", props)
      if (props.handleType == "edit") {
        this.schoolDetail = props;
        this.currentTab = "文书";
        this.schoolDetailWrit();
        this.detailTagSchool();
      }
      this.editDraw = true;
    });
  },
  methods: {
    //获取列表
    // getShoolList(){
    // 	selectSchoolList({studentId:this.studentId}).then(res=>{
    // 		if(res.code == 0){
    // 			console.log(res.data,"==>>>>>>>信息")
    // 			this.schoolData = res.data.records
    // 		}
    // 	})
    // },
    // 日期格式化
    dateFormat(intDate) {
      return commonFn.timeFormat(intDate, "yyyy-MM-dddd");
    },
    // 获取选校列表-文书
    schoolDetailWrit() {
      let params = {
        schoolId: this.schoolDetail.id,
        date: this.writData.date,
        type: this.writData.type
      };
      writSchoolList(params).then(res => {
        if (res.code == 0) {
          this.writData.writList = res.data;
        }
      });
    },
    // 获取选校列表-标化
    detailStandardSchool() {
      let params = {
        schoolId: this.schoolDetail.id,
        type: this.standardData.type
      };
      standardSchoolList(params).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.standardData = res.data;
          } else {
            this.standardData.id = 0;
            let radioKeys = [
              "isneedTi",
              "isneedTfFamily",
              "isneedYsFamily",
              "isneedSatAct",
              "isneedSatsubject",
              "isneedSatActWrite",
              "isneedApIb"
            ];
            let iptKeys = ["ieils", "toefl", "sat", "act"];
            radioKeys.forEach(item => {
              this.standardData[item] = "0";
            });
            iptKeys.forEach(item => {
              this.standardData[item] = "";
            });
            console.log("standardData", this.standardData);
          }
        }
      });
    },
    // 获取选校列表-专业
    detailMajorSchool() {
      let params = {
        schoolId: this.schoolDetail.id
      };
      majorSchoolList(params).then(res => {
        if (res.code == 0) {
          this.marjorList = res.data;
        }
      });
    },
    // 获取选校列表-面试
    detailInterviewSchool() {
      this.interviewData = {
				id: 0,
        remark: "",
        url: "",
        type: ""
      };
      let params = {
        schoolId: this.schoolDetail.id
      };
      interviewSchoolList(params).then(res => {
        if (res.code == 0 && res.data) {
					// console.log('interviewSchoolList', 1)
          let mapObj = {
            "1": "线上面试",
            "2": "线下面试"
          };
          res.data.type = mapObj[res.data.type];
          this.interviewData = res.data;
        } else {
					// console.log('interviewSchoolList', 2)
          this.interviewData = {
						id: 0,
            remark: "",
            url: "",
            type: ""
          };
        }
      });
    },
    // 获取选校列表-介绍
    detailDescSchool() {
      let params = {
        schoolId: this.schoolDetail.id
      };
      (this.descData = {
        address: "",
        number: "",
        undergraduateTotal: "",
        maleFemaleRatio: "",
        lastApplyTotal: "",
        academicQuality: "",
        socialActivities: "",
        lifeQuality: "",
        similarSchool: ""
      }),
        descSchoolList(params).then(res => {
          if (res.code == 0 && res.data) {
            let descKeys = [
              "id",
              "address",
              "number",
              "undergraduateTotal",
              "maleFemaleRatio",
              "lastApplyTotal",
              "academicQuality",
              "socialActivities",
              "lifeQuality",
              "similarSchool"
            ];
            descKeys.forEach(item => {
              if (res.data[item]) {
                this.descData[item] = res.data[item];
              }
            });
          }
        });
    },
    // 获取选校列表-案例
    detailCaseSchool() {
      let params = {
        schoolId: this.schoolDetail.id
      };
      caseSchoolList(params).then(res => {
        if (res.code == 0 && res.data) {
          this.caseList = res.data;
        }
      });
    },
    // 获取选校列表-更改
    detailLogSchool() {
      let params = {
        schoolId: this.schoolDetail.id,
        pageNum: this.pageIndex,
        pageSize: this.pageSize
      };
      logSchoolList(params).then(res => {
        if (res.code == 0 && res.data) {
          this.logList = res.data.records;
          this.totalCount = res.data.total * 1;
        }
      });
    },
    //选择学校添加
    chooseAddSchool() {
      let params = JSON.parse(JSON.stringify(this.addSchool));
      listChooseSchoolAdd(params).then(res => {
        if (res.code == 0) {
          this.$parent.getShoolList({
            studentId: this.studentId
          });
        }
      });
    },
    // 更改分页
    currentChange(val) {
      this.pageIndex = val;
      this.detailLogSchool();
    },
    // 获取选校列表-词条
    detailTagSchool() {
      let params = {
        schoolId: this.schoolDetail.id
      };
      tagSchoolList(params).then(res => {
        if (res.code == 0 && res.data) {
          this.tagList = res.data;
        }
      });
    },
    changeTab(val) {
      // console.log("changeTab", val)
      let label = val.label;
      switch (label) {
        case "标化":
          this.standardData.schoolId = this.schoolDetail.id;
          this.detailStandardSchool();
          break;
        case "专业":
          this.detailMajorSchool();
          break;
        case "面试":
          this.interviewData.schoolId = this.schoolDetail.id;
          this.detailInterviewSchool();
          break;
        case "学校介绍":
          this.descData.schoolId = this.schoolDetail.id;
          this.detailDescSchool();
          break;
        case "案例":
          this.detailCaseSchool();
          break;
        case "更改":
          this.detailLogSchool();
          break;
      }
    },
    dateFocus() {
      // console.log("dateFocus")
    },
    dateBlur() {
      this.writData.date = commonFn.timeFormat(this.writData.date, "yyyy");
      this.schoolDetailWrit();
      // console.log("dateBlur", this.writData.date)
    },
    majorFocus(e, item) {
      // console.log("majorFocus", e, item)
      this.marjorData = {};
      this.marjorData = item;
    },
    majorInput(e) {
      // console.log("majorInput", e.target.value)
      this.marjorData.name = e.target.value;
    },
    caseFocus(e, item) {
      console.log("caseFocus", item);
      this.caseData = {};
      this.caseData = item;
    },
    caseInput(e, type) {
      // console.log("majorInput", this.caseData, e.target.value)
      this.caseData[type] = e.target.value;
    },
    closeDraw() {
      this.editDraw = false;
    },
    handleFn(type, item) {
      // console.log(item, '12321321321学校添加')
      switch (type) {
        case "schoolAdd":
          if (!this.studentId) {
            this.$message({
              message: "请先添加学生~",
              type: "warning"
            });
            return;
          }
          this.addSchool.schoolId = item.id;
          this.addSchool.studentId = this.studentId;
          this.chooseAddSchool();
          break;
        case "wordAdd":
          this.tagDetail = {};
          this.diaLogTagVisible = true;
          this.diaLogTagTitle = "词条添加";
          break;
        case "wordEdit":
          this.tagDetail = item;
          this.tagDetail.keyWord = item.keyword;
          this.diaLogTagVisible = true;
          this.diaLogTagTitle = "词条编辑";
          break;
        case "writAdd":
          this.writDetail = {};
          this.writDetail.schoolId = this.schoolDetail.id;
          this.writDetail.type = this.writData.type;
          this.writDetail.createTime = this.writData.date;
          this.diaLogWritVisible = true;
          this.diaLogWritTitle = "文书添加";
          break;
        case "writEdit":
          this.writDetail = item;
          this.diaLogWritVisible = true;
          this.diaLogWritTitle = "文书编辑";
          break;
        case "writSubmit":
          if (!this.writDetail.content && !this.writDetail.title) {
            this.diaLogWritVisible = false;
          } else {
            writSchoolAdd(this.writDetail).then(res => {
              if (res.code == 0) {
                this.$message({
                  message: "文本添加成功~",
                  type: "success"
                });
                this.diaLogWritVisible = false;
                this.schoolDetailWrit();
              }
            });
          }
          break;
        case "standardEdit":
          this.standardEdit = true;
          break;
        case "standardCancle":
          this.standardEdit = false;
          break;
        case "standardSubmit":
          let submitObj = JSON.parse(JSON.stringify(this.standardData));
          console.log("标化提交>>>>", submitObj);
          standardSchoolAdd(submitObj).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "标化编辑成功~",
                type: "success"
              });
              this.standardEdit = false;
              this.detailStandardSchool();
            }
          });
          break;
        case "majorAdd":
          this.marjorList.push({
            schoolId: this.schoolDetail.id,
            id: 0,
            name: ""
          });
          break;
        case "majorEdit":
          this.majorEdit = true;
          break;
        case "majorCancle":
          this.majorEdit = false;
          this.marjorData = {};
          break;
        case "majorSubmit":
          // let majorObj = JSON.parse(JSON.stringify(this.marjorData))
          // console.log("majorObj", majorObj)
          let majorList = JSON.parse(JSON.stringify(this.marjorList));
          // console.log("majorList", majorList, this.schoolDetail)
          let isEmpty = majorList.some(item => item.name == "");
          if (isEmpty) {
            this.$message({
              message: "请填写专业名称",
              type: "error"
            });
            return;
          }
          console.log("isEmpty", isEmpty);
          let params = {
            schoolId: this.schoolDetail.id,
            studentId: this.studentId,
            schoolProfessions: majorList
          };
          majorSchoolAdd(params).then(res => {
            if (res.code == 0) {
              let messege = "专业添加成功";
              this.marjorData.name = "";
              this.$message({
                message: messege,
                type: "success"
              });
              this.majorEdit = false;
              this.detailMajorSchool();
            }
          });
          break;
        case "interviewEdit":
          this.interviewEdit = true;
          break;
        case "interviewCancle":
          this.interviewEdit = false;
          break;
        case "interviewSubmit":
          let interviewObj = JSON.parse(JSON.stringify(this.interviewData));
          console.log("interviewObj", interviewObj);
          let mapObj = {
            "线上面试": "1",
            "线下面试": "2"
          };
					interviewObj.type = mapObj[interviewObj.type];
					interviewObj.schoolId = this.schoolDetail.id;
          interviewSchoolAdd(interviewObj).then(res => {
            if (res.code == 0) {
              let messege = "";
              if (interviewObj.id == 0) {
                messege = "面试添加成功~";
              } else {
                messege = "面试编辑成功~";
              }
              this.$message({
                message: messege,
                type: "success"
              });
              this.interviewEdit = false;
              this.detailInterviewSchool();
            }
          });
          break;
        case "descEdit":
          this.descEdit = true;
          break;
        case "descCancle":
          this.descEdit = false;
          break;
        case "descSubmit":
          let descObj = JSON.parse(JSON.stringify(this.descData));
          descObj.schoolId = this.schoolDetail.id;
          console.log("descObj", descObj);
          descSchoolAdd(descObj).then(res => {
            if (res.code == 0) {
              let messege = "";
              if (descObj.id == 0) {
                messege = "学校介绍添加成功~";
              } else {
                messege = "学校介绍编辑成功~";
              }
              this.$message({
                message: messege,
                type: "success"
              });
              this.descEdit = false;
              // this.detailDescSchool();
            }
          });
          break;
        case "caseAdd":
          this.caseList.push({
            schoolId: this.schoolDetail.id,
            id: 0,
            name: "",
            url: ""
          });
          break;
        case "caseEdit":
          this.caseEdit = true;
          break;
        case "caseCancle":
          this.caseEdit = false;
          this.caseData = {};
          break;
        case "caseSubmit":
          // let caseObj = JSON.parse(JSON.stringify(this.caseData))
          // console.log("caseObj", caseObj)
          let caseList = JSON.parse(JSON.stringify(this.caseList));
          let isEmptyC = caseList.some(
            item => item.name == "" || item.url == ""
          );
          if (isEmptyC) {
            this.$message({
              message: "请填写完整案例",
              type: "error"
            });
            return;
          }
          let paramsC = {
            schoolId: this.schoolDetail.id,
            studentId: this.studentId,
            schoolCases: caseList
          };
          console.log("paramsC", paramsC);
          caseSchoolAdd(paramsC).then(res => {
            if (res.code == 0) {
              let messege = "案例编辑成功";
              this.$message({
                message: messege,
                type: "success"
              });
              this.caseEdit = false;
              this.detailCaseSchool();
            }
          });
          break;
        case "tagSubmit":
          this.$refs["tagDetail"].validate(valid => {
            if (valid) {
              let tagObj = JSON.parse(JSON.stringify(this.tagDetail));
              if (this.diaLogTagTitle == "词条添加") {
                console.log("词条添加", tagObj);
                tagObj.schoolId = this.schoolDetail.id;
                tagSchoolAdd(tagObj).then(res => {
                  if (res.code == 0) {
                    this.$message({
                      message: "词条添加成功~",
                      type: "success"
                    });
                    this.diaLogTagVisible = false;
                    this.detailTagSchool();
                  }
                });
              } else {
                tagSchoolEdit(tagObj).then(res => {
                  if (res.code == 0) {
                    this.$message({
                      message: "词条编辑成功~",
                      type: "success"
                    });
                    this.diaLogTagVisible = false;
                    this.detailTagSchool();
                  }
                });
              }
            }
          });
          break;
      }
    },
    closeTagDialog() {
      this.diaLogTagVisible = false;
    },
    closeWritDialog() {
      this.diaLogWritVisible = false;
    },
    drawHandle(type) {
      switch (type) {
        case "more":
          this.showMoreDesc = !this.showMoreDesc;
          break;
      }
    },
    // 文书
    writRadio(props) {
      // console.log("checkRadio", props)
      this.writData.type = props.label;
      this.schoolDetailWrit();
    },
    // 标化
    standardRadio(props) {
      this.standardData.type = props.label;
      this.detailStandardSchool();
    },
    // TOEFL/IEILS是否需要提交
    isneedTiRadio(props) {
      if (!this.standardEdit) return;
      this.standardData.isneedTi = props.label;
    },
    // 是否接受托福家庭版
    isneedTfFamilyRadio(props) {
      if (!this.standardEdit) return;
      this.standardData.isneedTfFamily = props.label;
    },
    // 是否接受雅思家庭版
    isneedYsFamilyRadio(props) {
      if (!this.standardEdit) return;
      this.standardData.isneedYsFamily = props.label;
    },
    // SAT/ACT是否需要提交
    isneedSatActRadio(props) {
      if (!this.standardEdit) return;
      this.standardData.isneedSatAct = props.label;
    },
    // SAT科目是否需要提交
    isneedSatsubjectRadio(props) {
      if (!this.standardEdit) return;
      this.standardData.isneedSatsubject = props.label;
    },
    // SAT作文或ACT写作测试是否需要提交
    isneedSatActWriteRadio(props) {
      if (!this.standardEdit) return;
      this.standardData.isneedSatActWrite = props.label;
    },
    // AP或IB是否需要提交
    isneedApIbRadio(props) {
      if (!this.standardEdit) return;
      this.standardData.isneedApIb = props.label;
    },
    showCurrentTag(type, item) {
      switch (type) {
        case "show":
          this.currentTag = item.id;
          break;
        case "hide":
          this.currentTag = null;
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/index.scss";

/deep/.el-tabs__nav {
  width: 100%;
  display: flex;
  align-items: center;
}

/deep/.el-tabs__item {
  // width: 56px;
  flex: 1;
  text-align: center;
  padding: 0;
  font-weight: 300;

  &.is-active {
    font-weight: bold !important;
  }
}

/deep/.tab_writ_date .el-input__inner {
  height: 26px;
  cursor: pointer;
}

/deep/.table_interview_select .el-input__inner {
  height: 31px;
}

input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #999999 !important;
}

textarea::-webkit-input-placeholder {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #999999 !important;
}

/deep/.inner_tab_content input:focus {
  outline: none;
  border: 1px solid $theme_color !important;
}

/deep/.inner_tab_content textarea:focus {
  outline: none;
  border: 1px solid $theme_color !important;
}

.flex_item {
  display: flex;
  align-items: center;
}

.flex_content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label_260 {
  width: 260px;
}

.label_200 {
  width: 200px;
}

.label_100 {
  width: 200px;
  height: 100px !important;
}

.border_bottom {
  border-bottom: 1px solid #eeeeee !important;
}

.margin_bottom10 {
  margin-bottom: 10px;
}

.submit_btn {
  display: flex;
  margin-top: 20px;

  .submit_btn_cancle {
    width: 80px;
    line-height: 34px;
    border: 1px solid $theme_color;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: $theme_color;
    margin-left: auto;
    cursor: pointer;
    margin-right: 8px;
  }

  .submit_btn_confirm {
    width: 80px;
    line-height: 34px;
    background: $theme_color;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
}

.add_btn {
  padding: 0 10px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed $theme_color;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 15px;

  img {
    width: 11px;
    height: 11px;
    margin-right: 10px;
  }

  span {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 20px;
    color: $theme_color;
  }
}

.table_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .table_td {
    width: 100%;
    display: flex;
    align-items: center;

    .table_tr {
      width: 100%;
      display: flex;
      align-items: center;

      .table_label {
        padding: 0 15px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        height: 31px;
        color: #666666;
        background: #ececec;
        border-right: 1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
        display: flex;
        align-items: center;
      }

      .table_textarea {
        flex: 1;
        height: 100px;
        padding: 8px 20px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        outline: none;
        position: relative;
        border: 1px solid #eeeeee;
        border-bottom: 0;
        display: flex;
        align-items: center;
        resize: none;
      }

      .table_value {
        flex: 1;
        height: 31px;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        outline: none;
        position: relative;
        border: 1px solid #eeeeee;
        border-bottom: 0;
        display: flex;
        align-items: center;

        .table_value_select {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 31px;
            color: #333333;
          }

          img {
            width: 16px;
            height: 16px;
          }
        }
      }

      .table_value .el-select {
        width: 100%;
        position: absolute; //绝对定位
        top: 0;
        left: 0px;
        opacity: 0; //设置完全透明
      }
    }
  }
}

.editDraw {
  width: 626px;
  height: 875px;
  background: #ffffff;
  box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  position: absolute;
  top: -20px;
  z-index: 2000;
  right: -40px;

  .editDraw_inner {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .editDraw_inner_back {
      width: 100%;
      background: #ffffff;

      .inner_back_btn {
        margin: 10px 0 20px 0;
        width: 100px;
        height: 40px;
        background: #ffffff;
        box-shadow: 0px 4px 6px rgba(51, 51, 51, 0.16);
        cursor: pointer;
        border-radius: 0px 20px 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 15px;
          height: 12px;
          margin-right: 10px;
        }

        span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 20px;
          color: #333333;
        }
      }
    }

    .editDraw_inner_content {
      flex: 1;
      padding: 0px 32px 20px 32px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      overflow-y: auto;

      .editDraw_inner_school {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .inner_school_name {
          flex: 1;
          display: flex;
          align-items: center;

          img {
            width: 100px;
            height: 100px;
            border: 1px solid #eeeeee;
            border-radius: 2px;
            margin-right: 20px;
          }

          .school_name_country {
            flex: 1;
            display: flex;
            flex-direction: column;

            .school_name_chinese {
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              line-height: 29px;
              color: #333333;
              margin-bottom: 8px;
              word-break: break-all;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }

            .school_name_english {
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 29px;
              color: #666666;
              word-break: break-all;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }
        }

        .inner_school_add {
          width: 79px;
          height: 26px;
          background: $theme_color;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            width: 11px;
            height: 11px;
            margin-right: 8px;
          }

          span {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }

      .editDraw_inner_taglist {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .editDraw_inner_tagbox {
          position: relative;
          margin: 0 10px 10px 0;

          .editDraw_inner_tagitem {
            line-height: 26px;
            background: #f6fbfa;
            border: 1px solid $theme_color;
            border-radius: 2px;
            box-sizing: border-box;
            padding: 3px 15px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: $theme_color;
            cursor: pointer;
          }

          .editDraw_inner_tagbg {
            width: 234px;
            // height: 25px;
            position: absolute;
            left: 50px;
            background-image: url("../../../assets/images/applicationProgress/tag_bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 10px;
            z-index: 99;

            span {
              margin-top: 5px;
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 17px;
              color: #ffffff;
            }
          }
        }
      }

      .editDraw_inner_desc {
        display: flex;
        align-items: flex-end;
        margin-bottom: 20px;

        .inner_desc_content {
          flex: 1;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 24px;
          color: #666666;
          word-break: break-all;

          &.inner_desc_ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
          }
        }

        .inner_desc_more {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin: 0 0 3px 2px;

          span {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 17px;
            color: $theme_color;
            margin-right: 4px;
          }

          img {
            width: 14px;
            height: 8px;
          }
        }
      }

      .editDraw_inner_mark {
        margin-bottom: 10px;

        .inner_mark_row {
          display: flex;
          align-items: center;

          .inner_mark_item {
            margin-right: 40px;
            display: flex;
            // align-items: center;
            margin-bottom: 10px;

            .inner_mark_label {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 20px;
              color: #999999;
              margin-right: 10px;
            }

            .inner_mark_value {
              flex: 1;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 20px;
              color: #333333;
              word-break: break-all;
            }
          }
        }
      }

      .editDraw_inner_tab {
        border-top: 1px solid #eeeeee;
        display: flex;
        flex-direction: column;

        .inner_tab_head {
        }

        .inner_tab_content {
          .inner_tab_edit {
            width: 64px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 20px;
            color: #666666;
            border: 1px solid #cccccc;
            border-radius: 2px;
            cursor: pointer;
            margin-left: auto;
          }

          .inner_tab_writ {
            display: flex;
            flex-direction: column;

            .tab_writ_search {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 4px;

              .tab_writ_checkbox {
              }

              .tab_writ_date {
                position: relative;
                cursor: pointer;

                .writ_date_inner {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 81px;
                  height: 26px;
                  border: 1px solid #cccccc;
                  border-radius: 4px;

                  span {
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 20px;
                    color: #666666;
                    margin-right: 8px;
                  }

                  img {
                    width: 16px;
                    height: 16px;
                  }
                }

                .el-date-editor {
                  width: 81px;
                  height: 26px;
                  position: absolute;
                  top: 0;
                  opacity: 0;
                }
              }
            }

            .tab_writ_list {
              display: flex;
              flex-direction: column;
              border: 1px solid #eeeeee;
              margin-bottom: 10px;

              .tab_writ_item {
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                padding: 10px 10px;
                border-bottom: 1px solid #eeeeee;

                .writ_item_title {
                  display: flex;
                  align-items: center;

                  span {
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    line-height: 20px;
                    color: #333333;
                    margin-right: 4px;
                  }

                  img {
                    width: 14px;
                    height: 14px;
                    cursor: pointer;
                  }
                }

                .writ_item_content {
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  line-height: 20px;
                  color: #666666;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                }
              }
            }

            .tab_writ_list :last-child.tab_writ_item {
              border-bottom: 0;
            }
          }

          .inner_tab_standard {
            display: flex;
            flex-direction: column;

            .tab_standard_search {
              height: 46px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            .tab_standard_content {
            }
          }

          .inner_tab_major {
            display: flex;
            flex-direction: column;

            .tab_major_content {
              display: flex;
              flex-direction: column;
              border: 1px solid #eeeeee;
              margin-top: 10px;

              .tab_major_value {
                height: 31px;
                background: #ffffff;
                border: 0;
                border-bottom: 1px solid #eeeeee;
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
                box-sizing: border-box;
                padding: 0 20px;
              }
            }

            .tab_major_content :last-child.tab_major_value {
              border-bottom: 0;
            }
          }

          .inner_tab_interview {
            .tab_interview_content {
              margin-top: 12px;
            }
          }

          .inner_tab_desc {
            .tab_desc_content {
            }
          }

          .inner_tab_case {
            .tab_case_list {
              .tab_case_item {
                margin-top: 10px;
              }
            }
          }

          .inner_tab_change {
            .tab_change_list {
              margin-bottom: 12px;

              .tab_change_item {
                padding: 10px 16px 2px 16px;
                border: 1px solid #eeeeee;

                .tab_change_td {
                  display: flex;
                  align-items: center;
                  margin-bottom: 8px;

                  .tab_change_tr {
                    flex: 1;
                    display: flex;
                    // align-items: center;

                    .tab_change_label {
                      font-size: 14px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      line-height: 20px;
                      color: #333333;
                    }

                    .tab_change_value {
                      flex: 1;
                      font-size: 14px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      line-height: 20px;
                      color: #333333;
                    }

                    .tab_change_tag {
                      line-height: 20px;
                      background: #eef6f4;
                      border: 1px solid $theme_color;
                      border-radius: 2px;
                      text-align: center;
                      padding: 0 16px;
                      box-sizing: border-box;
                      font-size: 14px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: $theme_color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .editDraw_dialog {
    .handle_form {
      .handle_form_row {
        display: flex;
        margin-top: 25px;

        .handle_form_item {
          flex: 1;
          display: flex;
          position: relative;
          margin-right: 20px;

          .handle_form_select {
            width: 407px;
          }

          .handle_form_ipt {
            width: 407px;
            line-height: 36px;
            border: 1px solid #eeeeee;
            outline: none;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #000000;
            padding: 0 10px;
            box-sizing: border-box;
          }

          .handle_form_textarea {
            width: 407px;
            height: 120px;
            background: #ffffff;
            border: 1px solid #eeeeee;
            border-radius: 4px;
            padding: 10px 10px;
            outline: none;
            resize: none;
            box-sizing: border-box;
          }
        }
      }

      .handle_form_btn {
        display: flex;
        align-items: center;
        margin: 40px 0 0px 0;

        .handle_form_cancel {
          margin-left: auto;
          width: 76px;
          line-height: 34px;
          background: #ffffff;
          border: 1px solid $theme_color;
          border-radius: 4px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: $theme_color;
          text-align: center;
          cursor: pointer;
          margin-right: 16px;
        }

        .handle_form_confirm {
          width: 76px;
          line-height: 34px;
          background: $theme_color;
          border-radius: 4px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #fff;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
