<template>
	<div class="upLoadFile">
		<div class="upLoadFile_list">
			<div v-if="fileData" class="flex-item">
				<div class="upLoadFile_list_item">
					<el-image
						fit="contain "
						class="upLoadFile_list_img"
						:src="fileData" 
						:preview-src-list="fileDataArr">
					</el-image>
					<!-- <img :src="fileData" class="upLoadFile_list_img" > -->
					<span @click="removeImg" class="upLoadFile_list_delete"><i class="el-icon-delete"></i></span>
				</div>
			</div>
			<div v-if="fileData.length < limitUpLoad" class="upLoadFile_list_item">
				<img src="@/assets/images/common/upLoad.png" class="upLoadFile_list_icon">
				<span class="upLoadFile_list_text">点击上传</span>
				<span class="upLoadFile_list_tip">{{fileTip}}</span>
				<el-upload class="upLoadFile_list_btn" drag 
					:before-upload="beforeUpload" :action="upLoadUrl"></el-upload>
			</div>
		</div>
		 <el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" :visible.sync="dialogVisible">
			<img width="100%" :src="fileData">
		</el-dialog>
	</div>
</template>

<script>
	import {
		getImgSrc,
		upLoadFile
	} from "@/api/OSS";
	export default {
		props: {
			limitSize: {
				//限制多少MB
				type: Number,
				default: 5
			},
			limitUpLoad: {
				//限制多少MB
				type: Number,
				default: 1
			},
			fileList: {
				type: String,
				default: function() {
					return "";
				}
			},
             fileNum:{
                type:Number,
                default:0
            },
			fileTip: {
				type: String,
				default: function() {
					return "只能上传jpg/png，且不超过10mb";
				}
			}
		},
		data() {
			return {
				upLoadUrl: "",
				dialogVisible: false,
				fileData: "",
				fileDataArr:[]
			}
		},
		watch: {
			fileList: {
				handler(val) {
					console.log("fileList", val)
					this.fileData = ""
					this.fileData = val
					this.fileDataArr = [val]
				},
				deep: true,
				immediate:true,
			}
		},
		methods: {
			removeImg() {
				this.fileData=""
				this.fileDataArr = []
				this.$emit("removeImg", {fileData:this.fileData,number:this.fileNum});
			},
			// 上传按钮   限制图片大小
			beforeUpload(file) {
				// console.log("beforeUpload", file);
				const isLt5M = file.size / 1024 / 1024 < this.limitSize;
				if (!isLt5M) {
					this.$message.error("上传文件大小不能超过 5MB!");
					return false;
				}
				let fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				let fileName = file.name;
				let fileType = file.type;
				if (fileSuffix != "png" && fileSuffix != "jpg" && fileSuffix != "jpeg") {
					this.$message({
						message: "上传文件只能是jpg、png格式!",
						type: "warning"
					});
					return false;
				}
				getImgSrc({
					fileName: fileName
				}).then((res) => {
					if (res.code != 0) return;
					this.upLoadUrl = res.data.uploadUrl;
					console.log("获取文件上传地址>>>>>测速", res.data.uploadUrl);
					upLoadFile(file, this.upLoadUrl, fileType).then((res) => {
						const fileUrl = res.config.url.substring(
							0,
							res.config.url.indexOf("?")
						);
						if (this.limitUpLoad == 1) {
							this.fileData = []
						}
						this.fileData = fileUrl
						this.fileDataArr = [fileUrl]
						// console.log("文件上传成功fileData>>>>>", this.fileData);
						this.$emit("beforeUpload", {fileData:this.fileData,number:this.fileNum});
					});
				});
			},
			// 图片预览
			// handlePictureCardPreview(file) {
			// 	console.log(file)
			// 	this.fileData = file.url;
			// 	this.dialogVisible = true;
			// },
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../styles/index.scss";
	/deep/.el-upload-dragger {
		width: 240px;
		height: 140px;
		border-radius: 4px;
	}
	
	.flex-item {
		display: flex;
		align-items: center;
	}

	.upLoadFile {
		.upLoadFile_list {
			display: flex;
			align-items: center;

			.upLoadFile_list_item {
				width: 240px;
				height: 140px;
				background: #F5F5F5;
				border-radius: 4px;
				margin-right: 15px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				position: relative;

				.upLoadFile_list_icon {
					width: 50px;
					height: 50px;
				}

				.upLoadFile_list_text {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: $theme_color;
					margin: 6px 0 3px 0;
				}

				.upLoadFile_list_tip {
					font-size: 12px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 17px;
					color: #999999;
				}

				.upLoadFile_list_btn {
					position: absolute;
					top: 0;
					right: 0;
					opacity: 0;
				}
				
				.upLoadFile_list_img {
					width: 240px;
					height: 140px;
					border-radius: 4px;
				}
				
				.upLoadFile_list_delete {
					width: 24px;
					height: 24px;
					position: absolute;
					top: 0;
					right: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					background: rgba(0, 0, 0, 0.4);
					border-radius: 0px 4px 0px 0px;
					color: #FFFFFF;
				}
			}
		}
	}
</style>
