<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="579px" :title="diaLogTitle" :visible.sync="diaLogVisible">
		<div class="handle_form">
			<el-form ref="statusDetail" :label-position="'left'" :rules="statusRules" :model="statusDetail">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="网址:">
							<input class="handle_form_ipt form_ipt_493" v-model="statusDetail.siteUrl"/>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_column" label-width="90px" label="系统账号:">
							<input class="handle_form_ipt" v-model="statusDetail.account"/>
						</el-form-item>
					</div>
					<div class="handle_form_item">
						<el-form-item class="flex_column" label="系统密码:" label-width="90px">
							<input class="handle_form_ipt" v-model="statusDetail.password"/>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_column" label="提交状态:">
							<el-select v-model="statusDetail.state" class="handle_form_select" clearable
								placeholder="请选择">
								<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
					<div class="handle_form_item">
						<el-form-item class="flex_column" label="联系邮箱:" label-width="90px">
							<input class="handle_form_ipt" v-model="statusDetail.email"/>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_column" label="提交截图:">
							<dragUpLoad ref="dragUpLoad"  @beforeUpload="beforeUpload" @removeImg="removeImg"/>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_column" label="备注:">
							<textarea class="handle_form_textarea" v-model="statusDetail.remark"></textarea>
						</el-form-item>
					</div>
				</div>
			</el-form>
			<div class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span v-preventReClick @click="submitState" class="handle_form_confirm">确定</span>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import Bus from "./eventBus.js";
	import dragUpLoad from "@/components/dragUpLoad/index.vue";
	import {
		stateList,
		stateSave
	} from "@/api/acTable"
	export default {
		components: {
			dragUpLoad
		},
		props: {},
		watch: {},
		mounted() {
			Bus.$on("handleStatusForm", props => {
				this.diaLogVisible = true
				this.propsData = props.row
				this.getStateList()
				// console.log("handleStatusForm", props)
			})
		},
		data() {
			return {
				diaLogTitle: "状态编辑",
				diaLogVisible: false,
				statusDetail: {},
				statusRules: {},
				mainTeachers:[
					{
					value: '0',
					label: '不完整'
					}, {
					value: '1',
					label: '完整'
					}
				]
			}
		},
		methods: {
			//查看状态
			getStateList(){
				var params = {
					studentId: this.propsData.studentId,
					schoolId:this.propsData.schoolId
				}
				stateList(params).then(res=>{
					if(res.code == 0){
						if(res.data){
								this.statusDetail = res.data
								if(res.data.url){
									this.$refs.dragUpLoad.fileData = [res.data.url]
								} else {
									this.$refs.dragUpLoad.fileData = []
								}
							
						}
					}
				})
			},
			beforeUpload(props) {
				console.log(props,'propsqweqw')
				if (props.length > 0) {
					this.statusDetail.url = props[0]
					this.$refs.dragUpLoad.fileData = props
				} else {
					this.statusDetail.url = ""
					this.$refs.dragUpLoad.fileData = []
				}
			},
			removeImg(props) {
				if (props.length > 0) {
					this.statusDetail.url = props[0]
					this.$refs.dragUpLoad.fileData = props
				} else {
					this.statusDetail.url = ""
					this.$refs.dragUpLoad.fileData = []
				}
			},
			//附件材料编辑
			submitState(){
				this.statusDetail.studentId = this.propsData.studentId
				this.statusDetail.schoolId = this.propsData.schoolId
				stateSave(this.statusDetail).then(res=>{
					if(res.code == 0){
						this.$message({
							message: '编辑成功',
							type: 'success'
						});
						this.$parent.getAcLists()
						this.closeDialog()
					}
				})
			},
			closeDialog() {
				this.statusDetail = {}
				this.diaLogVisible = false
				// this.$emit('closeDialog', 'commom')
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";

	/deep/.el-date-editor.el-input :focus {
		border: 1px solid $theme_color;
	}


	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}
	
	.flex_column {
		display: flex;
		flex-direction: column;
	}
	
	.form_ipt_493 {
		width: 483px !important;
	}

	.handle_form {
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			justify-content: space-between;
			// padding: 0 23px;

			.handle_form_item {
				display: flex;

				.handle_form_ipt {
					width: 256px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 40px;
					color: #000000;
					padding: 0 10px;
					box-sizing: border-box;
					margin-top: 8px;
				}
				
				.handle_form_date {
					width: 256px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 40px;
					color: #000000;
					margin-top: 9px;
				}

				.handle_form_select {
					width: 256px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 40px;
					color: #000000;
					margin-top: 9px;
				}

				.handle_form_textarea {
					width: 531px;
					height: 120px;
					background: #FFFFFF;
					border: 1px solid #EEEEEE;
					border-radius: 4px;
					outline: none;
					resize: none;
					padding: 6px 10px;
					box-sizing: border-box;
					margin: 8px 0 0 0px;
				}
				
				.handle_form_upload {
					display: flex;
					flex-direction: column;
				
					.form_upload_box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 128px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #EEEEEE;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #000000;
						cursor: pointer;
				
						.form_upload_icon {
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
					}
				
					.form_upload_tip {
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 17px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #FFFFFF;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFF;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>
