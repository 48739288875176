<template>
	<div class="acTable">
		<div class="acTable_content">
			<div class="acTable_list">
				<div class="acTable_list_box">
					<el-table class="acTable_list_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
						:data="acControlData" :border="false">
						<el-table-column fixed width="250px" prop="name" label="学校名称">
							<template slot-scope="scope">
								<div class="list_inner_slot">
									<span class="list_name_chinese">{{scope.row.name}}</span>
									<span class="list_name_english">{{scope.row.enName}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="batch" label="申请批次">
						</el-table-column>
						<el-table-column width="200px" prop="customEnd" label="自定截止">
						</el-table-column>
						<el-table-column width="250px" prop="college" label="专业/学院">
							<template slot-scope="scope">
								<el-tooltip popper-class="tooltip_bg" class="item" effect="dark"
									:content="scope.row.profession&&!scope.row.college?scope.row.profession:!scope.row.profession&&scope.row.college?scope.row.college:scope.row.profession&&scope.row.college?scope.row.profession+'/'+scope.row.college:''"
									placement="top-start">
									<div class="knowledgeBase_table_introduction">
										{{scope.row.profession}}
										<span v-if="scope.row.college&&scope.row.profession">/</span>
										<span v-if="scope.row.college">{{scope.row.college}}</span>
									</div>
								</el-tooltip>
							</template>
						</el-table-column>
						<el-table-column width="350px" prop="applyUrl" label="申请系统">
						</el-table-column>
						<el-table-column width="120px" prop="common" label="通用">
							<template slot-scope="scope" v-if="scope.row.common">
								<div @click="acTableHandle(scope, 'common')">
									<div class="list_inner_slot" v-if="scope.row.common.result||scope.row.common.state">
										<div class="flex_item">
											<img v-if="scope.row.common.state == '已提交'" class="slot_icon"
												src="@/assets/images/applicationProgress/ac_icon1.png">
											<img v-if="scope.row.common.result == 'Admit'" class="slot_icon"
												src="@/assets/images/applicationProgress/ac_icon2.png">
											<span>{{scope.row.common.time}}</span>
										</div>
										<div>
											<span v-if="scope.row.common.result == 'Waitlist'"
												class="slot_warn_tag">Waitlist</span>
											<span v-if="scope.row.common.result == 'Admit'"
												class="slot_green_tag">Admit</span>
											<span v-if="scope.row.common.result == 'Reject'"
												class="slot_danger_tag">Reject</span>
										</div>
									</div>
									<div v-else>-</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="100px" prop="standard" label="标化">
							<template slot-scope="scope" v-if="scope.row.standardize">
								<div @click="acTableHandle(scope, 'standard')" class="list_inner_slot">
									<div v-if="scope.row.standardize.count>0">
										<span v-if="scope.row.standardize.finish<scope.row.standardize.count"
											class="slot_warn_tag warn_tag_bg">进行中{{scope.row.standardize.finish}}/{{scope.row.standardize.count}}</span>
										<span v-if="scope.row.standardize.finish==scope.row.standardize.count"
											class="slot_green_tag green_tag_bg">已完成{{scope.row.standardize.finish}}/{{scope.row.standardize.count}}</span>
									</div>
									<span v-else>-</span>
									<!-- <span v-else class="slot_gray_tag gray_tag_bg">-</span> -->
									<span class="write-w">{{scope.row.standardize.updateTime}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="100px" prop="score" label="成绩单" >
							<template slot-scope="scope">
								<div @click="acTableHandle(scope, 'grade')">
									<div class="list_inner_slot" v-if="scope.row.score">
										<div v-if="scope.row.score.count>0">
											<span v-if="scope.row.score.finish<scope.row.score.count"
												class="slot_warn_tag warn_tag_bg">进行中{{scope.row.score.finish}}/{{scope.row.score.count}}</span>
											<span v-if="scope.row.score.finish==scope.row.score.count"
												class="slot_green_tag green_tag_bg">已完成{{scope.row.score.finish}}/{{scope.row.score.count}}</span>
										</div>
										<span v-else>-</span>
										<!-- <span v-else class="slot_gray_tag gray_tag_bg">-</span> -->
										<span class="write-w">{{scope.row.score.updateTime}}</span>
									</div>
								</div>

							</template>
						</el-table-column>
						<el-table-column width="100px" prop="recommendation" label="推荐信">
							<template slot-scope="scope" v-if="scope.row.recommendation">
								<div class="list_inner_slot" @click="acTableHandle(scope, 'letter')">
									<div v-if="scope.row.recommendation.count>0">
										<span v-if="scope.row.recommendation.finish<scope.row.recommendation.count"
											class="slot_warn_tag warn_tag_bg">进行中{{scope.row.recommendation.finish}}/{{scope.row.recommendation.count}}</span>
										<span v-if="scope.row.recommendation.finish==scope.row.recommendation.count"
											class="slot_green_tag green_tag_bg">已完成{{scope.row.recommendation.finish}}/{{scope.row.recommendation.count}}</span>
									</div>
									<span v-else>-</span>
									<!-- <span v-else class="slot_gray_tag gray_tag_bg">-</span> -->
									<span class="write-w">{{scope.row.recommendation.updateTime}}</span>

								</div>
							</template>
						</el-table-column>
						<el-table-column width="100px" prop="funds" label="资金证明">
							<template slot-scope="scope" v-if="scope.row.funds">
								<div @click="acTableHandle(scope, 'prove')" class="list_inner_slot">
									<div v-if="scope.row.funds.count>0">
										<span v-if="scope.row.funds.finish<scope.row.funds.count"
											class="slot_warn_tag warn_tag_bg">进行中{{scope.row.funds.finish}}/{{scope.row.funds.count}}</span>
										<span v-if="scope.row.funds.finish==scope.row.funds.count"
											class="slot_green_tag green_tag_bg">已完成{{scope.row.funds.finish}}/{{scope.row.funds.count}}</span>
									</div>
									<span v-else>-</span>
									<!-- <span v-else class="slot_gray_tag gray_tag_bg">-</span> -->
									<span class="write-w">{{scope.row.funds.updateTime}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="100px" prop="interview" label="面试">
							<template slot-scope="scope" v-if="scope.row.interview">
								<div class="list_inner_slot" @click="acTableHandle(scope, 'interview')">
									<div v-if="scope.row.interview.count>0">
										<span v-if="scope.row.interview.finish<scope.row.interview.count"
											class="slot_warn_tag warn_tag_bg">进行中{{scope.row.interview.finish}}/{{scope.row.interview.count}}</span>
										<span v-if="scope.row.interview.finish==scope.row.interview.count"
											class="slot_green_tag green_tag_bg">已完成{{scope.row.interview.finish}}/{{scope.row.interview.count}}</span>
									</div>
									<span v-else>-</span>
									<!-- <span v-else class="slot_gray_tag gray_tag_bg">-</span> -->
									<span class="write-w">{{scope.row.interview.updateTime}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="100px" prop="extramaterial" label="附加材料">
							<template slot-scope="scope" v-if="scope.row.extramaterial">
								<div @click="acTableHandle(scope, 'materials')">
									<div class="list_inner_slot">
										<div v-if="scope.row.extramaterial.count>0">
											<span v-if="scope.row.extramaterial.finish<scope.row.extramaterial.count"
												class="slot_warn_tag warn_tag_bg">进行中{{scope.row.extramaterial.finish}}/{{scope.row.extramaterial.count}}</span>
											<span v-if="scope.row.extramaterial.finish==scope.row.extramaterial.count"
												class="slot_green_tag green_tag_bg">已完成{{scope.row.extramaterial.finish}}/{{scope.row.extramaterial.count}}</span>
										</div>
										<span v-else>-</span>
										<!-- <span v-else class="slot_gray_tag gray_tag_bg">-</span> -->
										<span class="write-w">{{scope.row.extramaterial.updateTime}}</span>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="100px" prop="writ" label="文书">
							<template slot-scope="scope">
								<div @click="acTableHandle(scope, 'writ')" class="list_inner_slot">
									<div class="list_inner_slot">
										<div v-if="scope.row.paperwork.count>0">
											<span v-if="scope.row.paperwork.finish<scope.row.paperwork.count"
												class="slot_warn_tag warn_tag_bg">进行中{{scope.row.paperwork.finish}}/{{scope.row.paperwork.count}}</span>
											<span v-if="scope.row.paperwork.finish==scope.row.paperwork.count"
												class="slot_green_tag green_tag_bg">已完成{{scope.row.paperwork.finish}}/{{scope.row.paperwork.count}}</span>
										</div>
										<span v-else>-</span>
										<!-- <span v-else class="slot_gray_tag gray_tag_bg">-</span> -->
										<span class="write-w">{{scope.row.paperwork.updateTime}}</span>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="100px" prop="email" label="邮寄">
							<template slot-scope="scope" v-if="scope.row.email">
								<div @click="acTableHandle(scope, 'mail')">
									<div class="list_inner_slot">
										<div v-if="scope.row.email.count>0">
											<span v-if="scope.row.email.finish<scope.row.email.count"
												class="slot_warn_tag warn_tag_bg">邮寄中{{scope.row.email.finish}}/{{scope.row.email.count}}</span>
											<span v-if="scope.row.email.finish==scope.row.email.count"
												class="slot_green_tag green_tag_bg">已完成{{scope.row.email.finish}}/{{scope.row.email.count}}</span>
										</div>
										<span v-else>-</span>
										<!-- <span v-else class="slot_gray_tag gray_tag_bg">-</span> -->
										<span class="write-w">{{scope.row.email.updateTime}}</span>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="状态">
							<template slot-scope="scope">
								<div @click="acTableHandle(scope, 'status')">
									<div v-if="scope.row.state.result" class="list_inner_slot">
										<span v-if="scope.row.state.result == 0"
											class="slot_warn_tag warn_tag_bg">不完整</span>
										<span v-if="scope.row.state.result == 1"
											class="slot_green_tag green_tag_bg">完整</span>
										<span>{{scope.row.state.time}}</span>
									</div>
									<span v-else>-</span>
									<!-- <span v-else class="slot_gray_tag gray_tag_bg">-</span> -->
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="knowledgeBase_table_page">
						<el-pagination @current-change="currentChange" :current-page.sync="pageNumA" :page-size="pageSizeA"
							layout="total, prev, pager, next" :total="totalCountA">
						</el-pagination>
					</div>
			</div>
		</div>
	</div>
</template>

<script>
import Bus from "./eventBus.js";
import { acList } from "@/api/acTable";
export default {
  props: {
    studentId: {
      type: String,
      default: function() {
        return "";
      }
    },
    acControlData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    intPageSizeA: {
      type: Number,
      default: function() {
        return 10;
      }
    },
    intPageNumA: {
      type: Number,
      default: function() {
        return 1;
      }
    },
    intTotalCountA: {
      type: Number,
      default: function() {
        return 0;
      }
    }
  },
  watch: {
    intPageSizeA: {
      handler(val) {
        this.pageSizeA = val;
      },
      immediate: true
	},
	intPageNumA: {
      handler(val) {
        this.pageNumA = val;
      },
      immediate: true
	},
	intTotalCountA: {
      handler(val) {
        this.totalCountA = val;
      },
      immediate: true
	},
  },
  data() {
    return {
      pageSizeA: 10,
      pageNumA: 1,
      totalCountA: 0
      // acControlData: []
    };
  },
  created() {
    // this.getAcLists()
  },
  methods: {
    // getAcLists(){
    // 	var params= {
    // 		pageSize:this.pageSize,
    // 		pageNum:this.pageNum,
    // 		studentId:this.studentId
    // 	}
    // 	acList(params).then(res=>{
    // 		if(res.code == 0){
    // 			this.acControlData = res.data.records
    // 		}
    // 	})
    // },
    headStyle() {
      return "text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;";
    },
    rowStyle() {
      return "text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;";
	},
	currentChange(val) {
		this.$emit("currentChangeA", val)
	},
    acTableHandle(scope, type) {
      let handleMap = {
        common: "handleCommonForm",
        standard: "handleStandardForm",
        grade: "handleGradeForm",
        letter: "handleLetterForm",
        prove: "handleProveForm",
        interview: "handleInterviewForm",
        materials: "handleMaterialsForm",
        writ: "handleWritForm",
        mail: "handleMailForm",
        status: "handleStatusForm"
      };
      Bus.$emit(handleMap[type], {
        ...scope
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/index.scss";
.write-w {
  width: 90px;
}
// 自定义滚动条样式
/deep/.acTable_list ::-webkit-scrollbar {
  width: 3px;
  height: 15px;
}

/deep/.acTable_list ::-webkit-scrollbar-thumb {
  //滑块部分
  background-color: rgba(91, 168, 151, 1);
}

input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #999999 !important;
}

.flex_item {
  display: flex;
  align-items: center;
}

.knowledgeBase_table_page {
  text-align: center;
  margin-top: 20px;
}

.handle_form {
  .handle_form_row {
    display: flex;

    .handle_form_item {
      flex: 1;
      display: flex;
      position: relative;
      margin-right: 20px;

      .handle_form_select {
        width: 320px;
      }

      .handle_form_ipt {
        width: 320px;
        line-height: 36px;
        border: 1px solid #eeeeee;
        outline: none;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        padding: 0 10px;
        box-sizing: border-box;
      }

      .handle_form_textarea {
        width: 483px;
        height: 120px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        padding: 10px 10px;
        outline: none;
        resize: none;
        box-sizing: border-box;
        margin-left: 20px;
      }
    }
  }

  .handle_form_btn {
    display: flex;
    align-items: center;
    margin: 40px 0 0px 0;

    .handle_form_cancel {
      margin-left: auto;
      width: 76px;
      line-height: 34px;
      background: #ffffff;
      border: 1px solid $theme_color;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: $theme_color;
      text-align: center;
      cursor: pointer;
      margin-right: 16px;
    }

    .handle_form_confirm {
      width: 76px;
      line-height: 34px;
      background: $theme_color;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
}

.acTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;

  .acTable_content {
    width: 100%;

    .acTable_list {
      .acTable_list_box {
        .acTable_list_inner {
          border-left: 1px solid #eeeeee;
          border-right: 1px solid #eeeeee;

          .list_inner_slot {
            height: 60px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
            .list_name_chinese {
              height: 35px;
              line-height: 35px;
            }

            .list_name_english {
              height: 35px;
              line-height: 35px;
            }
          }

          .slot_icon {
            width: 14px;
            height: 14px;
            margin-right: 3px;
          }

          .slot_green_tag {
            line-height: 23px;
            padding: 0 8px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: $theme_color;
            border-radius: 1px;

            &.green_tag_bg {
              background: #eef6f4;
            }
          }

          .slot_danger_tag {
            line-height: 23px;
            padding: 0 8px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #d03b3b;
            border-radius: 1px;

            &.danger_tag_bg {
              background: #faebeb;
            }
          }

          .slot_warn_tag {
            line-height: 23px;
            padding: 0 8px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #d0743b;
            border-radius: 1px;

            &.warn_tag_bg {
              background: #faf1eb;
            }
          }

          .slot_gray_tag {
            line-height: 23px;
            padding: 0 8px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #99a89c;
            border-radius: 1px;

            &.gray_tag_bg {
              background: #f5f6f5;
            }
          }
        }
      }
    }
  }
}

.knowledgeBase_table_introduction {
  line-height: 20px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  overflow: hidden;
  cursor: pointer;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 1;
}

.nocommon {
  cursor: pointer;
}
</style>
