<template>
	<div class="interviewTable">
		<div v-if="studentId">
			<div class="interviewTable_add">
				<div class="interviewTable_add_inner">
					<img src="@/assets/images/applicationProgress/circle_add.png" class="interviewTable_step_btn" @click="addInterview">
				</div>
			</div>
		</div>
		<div class="interviewTable_step">
			<div v-for="(item,index) in interviewTable" :key="index" class="interviewTable_step_item">
				<img src="@/assets/images/applicationProgress/step.png" class="interviewTable_step_btn" >
				<div class="interviewTable_step_content">
					<div class="handle_form">
						<el-form label-width="95px" ref="interviewItem" :rules="interviewRules" :model="item">
							<div class="handle_form_row just-content">
								<div class="student-name">{{studentName}}</div>
								<div class="delete-box" @click="handleDelete(item,index)">
									<img class="delete-icon" src="@/assets/images/common/delete-icon.png" alt="">
									<span>删除</span>
								</div>
							</div>
							<div class="handle_form_row">
								<div class="handle_form_item">
									<el-form-item class="flex_item" label="预计面试日期:">
										<div class="handle_form_date" :class="{'date_disable': item.status==1}">
											<span class="form_date_value handle_form_empty">{{item.planTime?item.planTime:'请选择'}}</span>
											<div v-if="item.status!==1">
											<img src="@/assets/images/applicationProgress/date.png"
												class="form_date_icon">
											<el-date-picker :clearable="false" @change="dateSelect($event, 'planTime', item)" type="date" placeholder="请选择" v-model="item.planTime">
											</el-date-picker>
											</div>
										</div>
									</el-form-item>
								</div>
							</div>
							<div class="handle_form_row">
								<div class="handle_form_item">
									<el-form-item class="flex_item" label="面试官:">
										<el-select v-model="item.interviewer" class="handle_form_select" clearable
											placeholder="请选择" :disabled="item.status==1">
											<el-option v-for="item in mainTeachers" :key="item.id"
											:label="item.realname" :value="item.id">
										</el-option>
										</el-select>
									</el-form-item>
								</div>
							</div>
							<div class="handle_form_row interview">
								<div class="handle_form_item">
									<el-form-item class="flex_item" label="面试模式:">
									   <radioGroup :isDisabled="item.status==1?true:false" :radioIndex="index" :currentRadio="item.type+''" @checkRadio="checkRadio"  :radioList="writRadios" />
									</el-form-item>
								</div>
							</div>
							<div class="handle_form_row">
								<div class="handle_form_item">
									<el-form-item class="flex_item" label="实际面试日期:">
										<div class="handle_form_date" :class="{'date_disable': item.status==1}">
											<span class="form_date_value handle_form_empty">{{item.actualTime?item.actualTime:'请选择'}}</span>
											<div v-if="item.status!==1">
											<img src="@/assets/images/applicationProgress/date.png"
												class="form_date_icon">
											<el-date-picker type="date"  @change="dateSelect($event, 'actualTime', item)" placeholder="请选择" v-model="item.actualTime">
											</el-date-picker>
											</div>
										</div>
									</el-form-item>
								</div>
							</div>
							<div  class="handle_form_row" v-if="item.interviewType=='add'">
								<div class="handle_form_item" @click="startInterview('start',item)" v-preventReClick>
									<span class="handle_form_btn">开始面试</span>
								</div>
							</div>
							<div v-else>
								<div v-if="item.status == 0" class="handle_form_row">
									<div class="handle_form_item" @click="startInterview('end',item)" v-preventReClick>
										<span class="handle_form_btn">结束面试</span>
									</div>
								</div>
							</div>
							<div class="handle_form_row">
								<div class="handle_form_item">
									<el-form-item class="" label="面试资料/视频:">
										<uploadFile :fileNum="index"  @successUpload="successUpload" :fileSrc="item.videoUrl" :fileType="item.status"/>
									</el-form-item>
								</div>
							</div>
							<!-- <div v-if="item.type==1" class="handle_form_row">
								<div class="handle_form_item">
									<el-form-item class="" label="面试资料/视频:">
										<uploadFile :fileNum="index"  @successUpload="successUpload" :fileSrc="item.videoUrl"/>
									</el-form-item>
								</div>
							</div> -->
							<div class="handle_form_row">
								<div class="handle_form_item">
									<el-form-item class="" label="备注:">
										<textarea :disabled="item.status==1" class="handle_form_textarea" v-model="item.remark"></textarea>
									</el-form-item>
								</div>
							</div>
						</el-form>
					</div>
				</div>
			</div>
			</div>
			<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
		<div v-if="interviewTable.length==0" class="nodta-box">
			<img src="@/assets/images/common/empty1.png" class="nodata">
			<div>暂无面试数据</div>
		</div>
	</div>
</template>

<script>
	import radioGroup from "@/components/radioGroup/index.vue";
	import commonFn from "@/utils/common.js";
	import uploadFile from "@/components/uploadFile/interview.vue";
	import {
		schoolAuditionList,
		schoolAuditionSave,
		InterviewDelete
	} from "@/api/acTable"
	import {
		listTeacher,
	} from "@/api/common";
	export default {
		components: {
			radioGroup,
			uploadFile
		},
		props:{
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			},
			studentName: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		data() {
			return {
				writRadios: [{
						label: 1,
						value: "线上面试"
					},
					{
						label: 2,
						value: "现场面试"
					}
				],
				interviewRules: {},
				interviewTable: [],
				mainTeachers:[],
				radioType:1,
				interviewType:"",
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				deleteObj:{}
			}
		},
		created(){
			this.texcherList()
			// this.getschoolAuditionList()
		},
		methods: {
			closeMessege() {
				this.messegeVisible = false
			},
			confirmMessege() {
				InterviewDelete(this.deleteObj).then(res=>{
					if(res.code==0){
						this.$message({
							message: '面试删除成功',
							type: 'success'
						});
						this.messegeVisible = false
						this.getschoolAuditionList()
					}
				})
				// this.differentTabFn('tabelDelete', this.deleteObj)
			},
			// 时间选择
			dateSelect(value, key, item) {
				item[key] = commonFn.timeFormat(value, "yyyy-MM-dd")
				this.$forceUpdate()
			},
			// 获取老师列表
			texcherList() {
				let params = {
					pageIndex: 1,
					pageSize: 99999
				}
				listTeacher(params).then((res) => {
					this.mainTeachers = res.data
				})
			},
			getschoolAuditionList(){
				schoolAuditionList({studentId:this.studentId}).then(res=>{
					if(res.code == 0){
						if(res.data&&res.data.length>0){
							res.data.forEach(el=>{
								if(el.videoUrl){
									el.videoUrl = JSON.parse(el.videoUrl)
								}
							})
						}
						this.interviewTable = res.data
					}
				})
			},
			successUpload(data){
				this.interviewTable[data.number].videoUrl = data.fileData
			},
			//删除
			handleDelete(item,index){
				if(!item.id){
					this.interviewTable.splice(index,1)
				}else{
					this.messegeVisible = true
					this.messegeTip = "信息提示"
					this.messegeContent = "确定删除当前选中面试吗？"
					this.messegeType = "warn"
					this.deleteObj = {
						id:item.id
					}
				}
			},
			addInterview(){
				let params = {
					actualTime:"",
					interviewer:"",
					planTime:"",
					remark:"",
					videoUrl:[],
					type:1,
					fileId:0,
					interviewType:"add",
					studentId:this.studentId
				}
				this.interviewTable.unshift(params)
			},
			startInterview(type,item){
				if(type=='end'){
					item.status =1
				}
				let editParams = JSON.parse(JSON.stringify(item))
				editParams.videoUrl=JSON.stringify(editParams.videoUrl) 
				console.log(editParams)
				schoolAuditionSave(editParams).then(res=>{
					if(res.code == 0){
						if(type=='start'){
							 this.$message({
								message: '面试添加成功',
								type: 'success'
							});
						}else if(type=='end'){
							 this.$message({
								message: '操作成功',
								type: 'success'
							});
						}
						item.interviewType=''
						this.getschoolAuditionList()
					}
				})
			},
			
			checkRadio(props) {
				console.log(props)
				this.interviewTable[props.currentIndex].type = props.label
				this.radioType = props.label
			},
		}
	}
</script>

<style lang="scss" scoped="">
	@import "../../../styles/index.scss";
	
	/deep/.el-input__inner {
		width:360px;
	}
	
	// 自定义滚动条样式
	.interviewTable::-webkit-scrollbar {
	   display: none;
	}

	/deep/.el-date-editor.el-input :focus {
		border: 1px solid $theme_color;
	}

	/deep/.el-icon-date {
		display: none;
	}

	/deep/.el-form-item__label {
		width: 90px;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex_column {
		display: flex;
		flex-direction: column;
	}

	.handle_form {
		.just-content{
			justify-content: space-between;
			align-items: center;
		}
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			.student-name{
				font-weight:bold;
				font-size:16px;
			}
			.delete-box{
				display: flex;
				justify-content: flex-start;
				align-items: center;
				cursor: pointer;
				span {
					font-size: 16px;
				}
				.delete-icon{
					width:16px;
					height:16px;
					margin-right:8px;
				}
			}
			.handle_form_label {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #333333;
			}

			.handle_form_user {
				margin-left: auto;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #333333;
			}

			.handle_form_item {
				// flex: 1;
				display: flex;
				.interview-cover{
					width:300px;
					height:200px;
					background:red;
				}
				.handle_form_btn {
					width:360px;
					line-height: 34px;
					background: $theme_color;
					border: 1px solid #EEEEEE;
					border-radius: 4px;
					text-align: center;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					cursor: pointer;
					margin-left: 110px;
				}

				.handle_form_ipt {
					width:360px;
					margin-left: 10px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 30px;
					color: #000000;
					padding: 0 10px;
					box-sizing: border-box;
				}

				.handle_form_date {
					width:360px;
					height: 34px;
					margin-left: 10px;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #000000;
					border: 1px solid #EEEEEE;
					border-radius: 4px;
					position: relative;
					padding: 0 10px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					box-sizing: border-box;
					&.date_disable {
						background-color: rgba(239, 239, 239, 0.3);
					}

					.form_date_value {
						flex: 1;
					}

					.form_date_icon {
						width: 15px;
						height: 16px;
						margin-left: 10px;
					}
				}

				.handle_form_date .el-date-editor {
					max-width: 360px;
					position: absolute; //绝对定位
					top: 0;
					left: 0px;
					opacity: 0; //设置完全透明
				}

				.handle_form_empty {
					color: #999999;
				}

				.handle_form_full {
					color: #000000;
				}


				.handle_form_select {
					width:360px;
					margin-left: 10px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
				}

				.handle_form_textarea {
					width:360px;
					height: 120px;
					background: #FFFFFF;
					border: 1px solid #EEEEEE;
					border-radius: 4px;
					outline: none;
					resize: none;
					padding: 6px 10px;
					box-sizing: border-box;
					margin: 0px 0 0 0px;
					margin-left: 10px;
				}

				.handle_form_upload {
					display: flex;
					flex-direction: column;

					.form_upload_box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 128px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #EEEEEE;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: $theme_color;
						cursor: pointer;
						margin-left: 10px;

						.form_upload_icon {
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
					}

					.form_upload_tip {
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 17px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}
		}
	}

	.interviewTable {
		// width: 700px;
		height: 836px;
		overflow: hidden;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		padding-left: 13px;

		.interviewTable_step_btn {
			width: 26px;
			height: 26px;
			position: absolute;
			left: -13px;
			top: 0;
			cursor: pointer;
		}

		.interviewTable_add {
			width: 551px;
			height: 33px;
			padding: 13px 0 0px 32px;
			border-left: 1px solid #EEEEEE;
			position: relative;

			.interviewTable_add_inner {
				height: 33px;
			}
		}

		.interviewTable_step {
			display: flex;
			flex-direction: column;

			.interviewTable_step_item {
				// width: 565px;
				width: 590px;
				height: 100%;
				border-left: 1px solid #EEEEEE;
				position: relative;
				padding: 13px 0 20px 32px;

				.interviewTable_step_content {
					border: 1px solid $theme_color;
					border-radius: 10px;
					padding: 15px 20px;
					// box-sizing: border-box;
				}
			}
		}
	}
	.nodta-box{
		width:300px;
		height:250px;
		margin:auto auto;
		text-align: center;
		color:#999999;
		font-size:14px;
	}
	.nodata{
		width:300px;
		height:250px;
		// text-align: center;
		
	}
</style>
