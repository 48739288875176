<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2022-04-29 10:04:30
-->
<template>
	<div class="student_content">
		<div class="student_search">
			<input type="text" placeholder="请输入学生姓名" class="search-ipt" placeholder-class="search-place" v-model="studentName">
			<img class="search-icon" src="@/assets/images/dashboard/search.png" alt="" @click="searchStudent">
		</div>
		<div class="student_list" v-if="intList.length > 0">
			<div @click="selectStudent(item, index)" :class="{'item_active_green': currentIndex == index&&item.appStatus == 1, 'item_active_yellow': currentIndex == index&&item.appStatus == 2,'item_active_gray': currentIndex == index&&item.appStatus == 3,}" v-for="(item, index) in intList" :key="index"
				class="student_item">
				<div class="student_item_left">
					<span
						:class="{'item_mark_green': item.appStatus == 1,'item_mark_yellow': item.appStatus == 2,'item_mark_gray': item.appStatus == 3}"
						class="student_item_mark">{{appTypeName(item.appType)}}</span>
					<span class="student_item_name">{{item.name}}</span>
				</div>
				<div class="" style="display: flex;align-items: center;">
					<img v-if="item.isACUpdateStatus==1" src="../../assets/images/common/new.png" class="student_item_new">
					<div v-if="item.updateNotifyId" class="student_item_right">有更新</div>
				</div>
			</div>
		</div>
		<div v-if="intList.length == 0" class="student_empty">
			<img src="@/assets/images/common/empty1.png" >
			<span>暂无学生</span>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    intList: {
      type: Array,
      default: function() {
        return [];
      }
    },
    studentIndex: {
      type: Number,
      default: function() {
        return 0;
      }
    }
  },
  watch: {
    studentIndex: {
      handler(val) {
        this.currentIndex = val;
      },
      immediate: true
    }
  },
  data() {
    return {
      studentName: "",
      currentIndex: 0
    };
  },
  methods: {
    ...mapActions(["studentUpdateClear"]),
    appTypeName(val) {
      let mapObj = {
        "1": "高",
        "2": "本",
        "3": "转",
        "4": "研"
      };
      return mapObj[val];
    },
    selectStudent(item, index) {
      this.currentIndex = index;
      if (item.updateNotifyId) {
        this.studentUpdateClear({ updateNotifyId: item.updateNotifyId }).then(
          res => {
            if (res.code == 0) {
              item.updateNotifyId = undefined;
              this.$forceUpdate();
            }
          }
        );
      }
      console.log("selectStudent", item);
      this.$emit("selectStudent", item);
    },
    searchStudent() {
      this.$emit("searchStudent", this.studentName);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../styles/index.scss";

.item_mark_green {
  background: $theme_color;
}

.item_mark_yellow {
  // background: rgba(181, 177, 129, 1);
  background: #d0743b;
}

.item_mark_gray {
  background: rgba(153, 168, 156, 1);
}

input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #cccccc !important;
}

.student_content {
  width: 230px;
  display: flex;
  flex-direction: column;

  .student_search {
    width: 220px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.16);
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    box-sizing: border-box;
    margin-bottom: 10px;

    .search-ipt {
      width: 170px;
      line-height: 20px;
      border: 0;
      outline: none;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }

    .search-icon {
      width: 16px;
      height: 16px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .student_empty {
    display: flex;
    flex-direction: column;
    height: calc(836px - 46px);
    align-items: center;
    justify-content: center;
    img {
      width: 200px;
      height: 200px;
    }

    span {
      font-size: 15px;
      color: #999999;
    }
  }

  .student_list {
    display: flex;
    flex-direction: column;
    height: calc(836px - 46px);
    overflow: hidden;
    overflow-y: auto;

    .student_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      box-sizing: border-box;
      border: 1px solid #eeeeee;
      cursor: pointer;
      margin-right: 7px;

      .student_item_left {
        display: flex;
        align-items: center;

        .student_item_mark {
          width: 24px;
          line-height: 24px;
          border-radius: 50%;
          text-align: center;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          margin-right: 15px;
        }

        .student_item_name {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 24px;
          color: #333333;
        }
      }

      .student_item_right {
        min-width: 42px;
        line-height: 24px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #d0743b;
      }

      .student_item_new {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }

      &.item_active_green {
        background: rgba(91, 168, 151, 0.1);
        border-color: $theme_color;
        border-top: 1px solid $theme_color !important;
      }
      &.item_active_yellow {
        background: rgba(208, 116, 59, 0.1);
        border-color: rgba(208, 116, 59, 1);
        border-top: 1px solid rgba(208, 116, 59, 1) !important;
      }
	  &.item_active_gray {
        background: rgba(153, 168, 156, 0.1);
        border-color: rgba(153, 168, 156, 1);
        border-top: 1px solid rgba(153, 168, 156, 1) !important;
      }
    }
  }

  .student_list :not(:first-child).student_item {
    border-top: 0;
  }
}
</style>
