<template>
	<div class="upLoadFile">
		<div class="upLoadFile_list" v-if="shapeUpLoad == 'square'">
			<div v-if="fileData" class="flex-item">
				<div  class="upLoadFile_list_item square_width">
					<el-image
						fit="cover"
						class="upLoadFile_list_img"
						:src="fileData" 
						:preview-src-list="[fileData]">
					</el-image>
					<!-- <img :src="fileData" class="upLoadFile_square_img"> -->
					<span @click="removeImg" class="upLoadFile_list_delete"><i class="el-icon-delete"></i></span>
				</div>
			</div>
			<div v-else class="upLoadFile_list_item square_width">
				<img src="@/assets/images/dashboard/add.png" class="upLoadFile_list_icon">
				<span class="upLoadFile_list_tip">{{fileTip}}</span>
				<el-upload class="upLoadFile_square_btn" :show-file-list="false" :limit="limitUpLoad"
					:before-upload="beforeUpload" :action="upLoadUrl">
					<div class="square_width"></div>
				</el-upload>
			</div>
		</div>
		<div class="upLoadFile_list" v-if="shapeUpLoad == 'circle'">
			<div v-if="fileData.length > 0" class="flex-item">
				<div v-for="(item,index) in fileData" class="upLoadFile_list_item circle_width">
					<img :src="item" class="upLoadFile_circle_img">
					<div @click="reUpload" class="upLoadFile_circle_shadow">
						<!-- <img src="@/assets/images/setup/upLoad_light.png" > -->
						<span>修改头像</span>
					</div>
				</div>
			</div>
			<div v-if="fileData.length < limitUpLoad" class="upLoadFile_list_item circle_width">
				<el-upload ref="circleUpLoad" class="upLoadFile_circle_btn" :show-file-list="false" :limit="limitUpLoad"
					:before-upload="beforeUpload" :action="upLoadUrl">
					<img src="@/assets/images/setup/upLoad.png" class="circle_width">
				</el-upload>
			</div>
		</div>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload"></el-upload>
		<el-dialog :modal="false" class="dialog_bg" :visible.sync="dialogVisible" :append-to-body="false" :modal-append-to-body="false" :width="dialogWidth">
			<img width="100%" :src="imgSrc">
		</el-dialog>
	</div>
</template>

<script>
	import {
		getImgSrc,
		upLoadFile
	} from "@/api/OSS";
	export default {
		props: {
			limitSize: {
				//限制多少MB
				type: Number,
				default: 5
			},
			limitUpLoad: {
				//限制多少张
				type: Number,
				default: 1
			},
			shapeUpLoad: {
				//上传按钮形状
				type: String,
				default: "square" //方形
			},
			fileList: {
				type: String,
				default: function() {
					return "";
				}
			},
			fileNum:{
				type:Number,
				default:0
    		},
			dialogWidth: {
				type: String,
				default: '50%'
			},
			fileTip: {
				type: String,
				default: function() {
					return "点击上传图片";
				}
			}
		},
		data() {
			return {
				upLoadUrl: "",
				fileData: "",
				dialogVisible: false,
				imgSrc:""
			}
		},
		watch: {
			fileList: {
				handler(val) {
					// this.fileData = []
					this.fileData = val
				},
				deep: true
			}
		},
		methods: {
			removeImg() {
				this.fileData=""
				this.$emit("removeImg", this.fileData);
			},
			reUpload() {
				document.querySelector(".uploadImage input").click();
			},
			// 上传按钮   限制图片大小
			beforeUpload(file) {
				console.log("beforeUpload", file);
				const isLt5M = file.size / 1024 / 1024 < this.limitSize;
				if (!isLt5M) {
					this.$message.error("上传文件大小不能超过 5MB!");
					return false;
				}
				let fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
				let fileName = file.name;
				let fileType = file.type;
				if (fileSuffix != "png" && fileSuffix != "jpg" && fileSuffix != "jpeg") {
					this.$message({
						message: "上传文件只能是jpg、png格式!",
						type: "warning"
					});
					return false;
				}
				getImgSrc({
					fileName: fileName
				}).then((res) => {
					if (res.code != 0) return;
					this.upLoadUrl = res.data.uploadUrl;
					console.log("获取文件上传地址>>>>>", res.data.uploadUrl);
					upLoadFile(file, this.upLoadUrl, fileType).then((res) => {
						const fileUrl = res.config.url.substring(
							0,
							res.config.url.indexOf("?")
						);
						if (this.limitUpLoad == 1) {
							this.fileData = ""
						}
						this.fileData=fileUrl;
						console.log("文件上传成功fileData>>>>>", this.fileData);
						this.$emit("beforeUpload", {fileData:this.fileData,number:this.fileNum});
					});
				});
			},
			// 图片预览
			handlePictureCardPreview(file) {
				this.imgSrc = file;
				this.dialogVisible = true;
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../styles/index.scss";
	.dialog_bg /deep/ .el-dialog__header {
	padding: 0;
	}
	.dialog_bg /deep/ .el-dialog__body {
	padding: 20px;
	}
	/deep/.upLoadFile_square_btn .el-upload-dragger {
		width: 120px;
		height: 112px;
		border-radius: 4px;
	}

	/deep/.upLoadFile_circle_btn .el-upload-dragger {
		width: 80px;
		height: 80px;
		border-radius: 40px;
		position: relative;
		border: none;
	}

	.flex-item {
		display: flex;
		align-items: center;
	}

	.upLoadFile {
		.upLoadFile_list {
			display: flex;
			align-items: center;

			.square_width {
				width: 120px;
				height: 112px;
				background: #EEF6F4;
				border-radius: 4px;
				margin-right: 15px;
			}

			.circle_width {
				width: 80px;
				height: 80px;
				border-radius: 40px;
				position: relative;
			}

			.upLoadFile_list_item {

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				position: relative;

				.upLoadFile_list_icon {
					width: 22px;
					height: 22px;
				}

				.upLoadFile_list_tip {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: $theme_color;
					margin-top: 16px;
				}

				.upLoadFile_square_btn {
					position: absolute;
					top: 0;
					right: 0;
					opacity: 0;
				}

				.upLoadFile_square_img {
					width: 120px;
					height: 112px;
					border-radius: 4px;
				}

				.upLoadFile_circle_img {
					width: 80px;
					height: 80px;
					border-radius: 40px;
				}

				.upLoadFile_circle_shadow {
					width: 0;
					height: 0;
					border-radius: 40px;
					background-color: rgba(0, 0, 0, 0.4);
					position: absolute;
					top: 0;
					left: 0;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					overflow: hidden;

					img {
						width: 20px;
						height: 18px;
						margin-bottom: 2px;
					}

					span {
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 17px;
						color: #FFFFFF;
					}
				}

				.upLoadFile_list_delete {
					width: 24px;
					height: 24px;
					position: absolute;
					top: 0;
					right: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					background: rgba(0, 0, 0, 0.4);
					border-radius: 0px 4px 0px 0px;
					color: #FFFFFF;
				}
			}

			.upLoadFile_list_item:hover .upLoadFile_circle_shadow {
				width: 80px;
				height: 80px;
			}
		}
	}
</style>
