<template>
	<div class="radio_group">
		<div v-for="(item,index) in radioList" @click="checkRadio(item)" :key="index" class="radio_item">
			<img v-if="currentRadio == item.label&&!isCheckBox" :style="radioStyle" src="@/assets/images/common/radioed.png" class="radio_check">
			<img v-if="currentRadio == item.label&&isCheckBox" :style="radioStyle" src="@/assets/images/common/checkboxed.png" class="radio_check">
			<img v-if="currentRadio != item.label&&!isCheckBox" :style="radioStyle" src="@/assets/images/common/radio.png" class="radio_check">
			<img v-if="currentRadio != item.label&&isCheckBox" :style="radioStyle" src="@/assets/images/common/checkbox.png" class="radio_check">
			<span class="radio_label">{{item.value}}</span>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			radioStyle: {
				type: Object,
				default: function() {
					return {
						'width': '16px',
						'height': '16px'
					}
				}
			},
			currentRadio: {
				type: String,
				default: function() {
					return ""
				}
			},
			radioList: {
				type: Array,
				default: function() {
					return []
				}
			},
			isMoreSelct: {
				type: Boolean,
				default: function() {
					return false
				}
			},
			isCheckBox: {
				type: Boolean,
				default: function() {
					return false
				}
			},
			radioIndex: {
				type: Number,
				default: function() {
					return undefined
				}
			},
			isDisabled:{
				type: Boolean,
				default: function() {
					return false
				}
			},
		},
		data() {
			return {}
		},
		methods: {
			checkRadio(item) {
				if(this.isDisabled){
					return
				}
				if (this.isMoreSelct) {
					// 多选
					let selectArr = []
					item.check = !item.check
					selectArr = this.radioList.filter((oitem) => {
						return oitem.check == true
					})
					// console.log("多选", selectArr)
					item.currentIndex = this.radioIndex
					this.$emit("checkRadio", selectArr)
				} else {
					// 单选
					let checked = item.value
					this.radioList.map((item) => {
						if (item.value == checked) {
							item.check = true
						} else {
							item.check = false
						}
					})
					item.currentIndex = this.radioIndex
					this.$emit("checkRadio", item)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.radio_group {
		display: flex;
		align-items: center;

		.radio_item {
			display: flex;
			align-items: center;
			margin-right: 20px;

			.radio_check {
				margin-right: 10px;
				cursor: pointer;
			}

			.radio_label {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 16px;
				color: #666666;
			}
		}
	}
</style>
