<template>
	<div>
		<div>
			<div>
				<div class="write-flex" v-if="showSchoolDelete>0||commonWrite.length>0">
					<div class="delete-write">删除文书</div>
					<div></div>
				</div>
				<div>
					<!-- 通用文书 -->
					<div class="writTable_inner" v-if="commonWrite.length>0">
						<!-- 一级标题 -->
						<!-- 二级内容-学校 -->
						<div class="flex_column">
							<div class="writTable_second_head">
								<div @click="handleFn('commomWrite')" class="second_head_item width_flex flex_start">
									<img v-if="isOpen" class="second_head_icon"
										src="@/assets/images/applicationProgress/arrow_up_circle.png">
									<img v-if="!isOpen" class="second_head_icon"
										src="@/assets/images/applicationProgress/arrow_down_circle.png">
									<span>通用文书</span>
								</div>
								<div class="second_head_item flex_content width_130">
									<span class="school-teacher"></span>
								</div>
								<div class="second_head_item flex_content width_130">
									<span></span>
								</div>
								<div class="second_head_item flex_content width_130">
									<span></span>
								</div>
								<div class="second_head_item flex_content width_130">
									<span></span>
								</div>
								<div class="second_head_item flex_content width_130">
									<span></span>
								</div>
								<div class="second_head_item flex_content width_300">
									<span></span>
								</div>
								<div class="second_head_item flex_content width_130">
									<span></span>
								</div>
								<div class="second_head_item flex_content width_130">
									<!-- <span class="writTable_btn"  @click="handleFn('addCommonWrit')">添加文书</span>
                            <span class="writTable_btn" @click="handleFnDelete(oitem)">删除</span> -->
								</div>
							</div>
							<div class="" v-if="isOpen">
								<div class="writTable_third_head">
									<div v-for="(item, index) in thirdHead" :key="index"
										class="third_head_item flex_content">
										{{item.value}}
									</div>
								</div>
								<div v-for="(oitem, index) in commonWrite" :key="index" class="writTable_third_content">
									<div class="third_content_item flex_content">
										{{oitem.paperworkTitle}}
									</div>
									<div class="third_content_item flex_content">
										<span>{{oitem.paperworkFlowTitle?oitem.paperworkFlowTitle:'-'}}</span>
									</div>
									<div class="third_content_item flex_content">
										{{oitem.lastUpdateTime?oitem.lastUpdateTime:'--'}}{{oitem.createUser?oitem.createUser:""}}
									</div>
									<div class="third_content_item flex_content">
										{{oitem.lastPlanTime?oitem.lastPlanTime:'--'}}{{oitem.planUser?oitem.planUser:""}}
									</div>
									<div class="third_content_item flex_content">
										{{oitem.createTime?oitem.createTime:'--'}}
									</div>
									<div class="third_content_item flex_content">
										{{oitem.version?oitem.version:'--'}}版
									</div>
									<div class="third_content_item flex_content">
										<span v-if="oitem.progressRate==100" class="green">已完成</span>
										<span v-if="oitem.progressRate==50" class="red">进行中</span>
										<span v-if="oitem.progressRate==0" class="gray">未开始</span>
									</div>
									<div class="third_content_item flex_content">
										{{oitem.deleteTime?oitem.deleteTime:""}}
										{{oitem.deleteUser?oitem.deleteUser:'--'}}
									</div>
									<div class="third_content_item flex_content">
										<span class="writTable_btn"
											@click="writTableHandle(oitem,'commonWrit')">查看文书流</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="writTable_inner" v-if="showSchoolDelete>0">
						<!-- 一级标题 -->
						<div class="writTable_first_head">
							<span v-for="(item, index) in firstHead" :key="index" :class="item.class"
								class="first_head_item">{{item.value}}</span>
						</div>
						<!-- 二级内容-学校 -->
						<div class="flex_column" v-for="(itemAll, index) in schoolList" :key="index">
							<div class="writTable_second_head" v-if="itemAll.showDeleteWrite == true">
								<div @click="handleFn('showSchoolWrit', itemAll)" class="second_head_item width_flex">
									<img v-if="itemAll.showWrit" class="second_head_icon"
										src="@/assets/images/applicationProgress/arrow_up_circle.png">
									<img v-if="!itemAll.showWrit" class="second_head_icon"
										src="@/assets/images/applicationProgress/arrow_down_circle.png">
									<el-tooltip popper-class="tooltip_bg" class="item" effect="dark" :content="itemAll.name"
									placement="top-start">
									<span
										class="head_item_school">{{itemAll.name?itemAll.name:'--'}}</span>
								</el-tooltip>
								</div>
								<div class="second_head_item flex_content width_130">
									<el-tooltip v-if="itemAll.teacher" popper-class="tooltip_bg" class="item"
										effect="dark" :content="itemAll.teacher" placement="top-start">
										<span class="school-teacher">{{itemAll.teacher}}</span>
									</el-tooltip>
									<span v-else class="school-teacher">--</span>
								</div>
								<div class="second_head_item flex_content width_130">
									<span>{{itemAll.batch?itemAll.batch:'--'}}</span>
								</div>
								<div class="second_head_item flex_content width_130">
									<span>{{itemAll.customEnd?itemAll.customEnd:'--'}}</span>
								</div>
								<div class="second_head_item flex_content width_130">
									<el-tooltip v-if="itemAll.profession" popper-class="tooltip_bg" class="item"
										effect="dark" :content="itemAll.profession" placement="top-start">
										<span class="school-teacher">{{itemAll.profession}}</span>
									</el-tooltip>
									<span v-else class="school-teacher">--</span>
								</div>
								<div class="second_head_item flex_content width_300">
								<el-tooltip v-if="itemAll.remark" popper-class="tooltip_bg" class="item" effect="dark"
									:content="itemAll.name" placement="top-start">
									<span class="head_item_remark">{{itemAll.remark?itemAll.remark:'--'}}</span>
								</el-tooltip>
								<span v-else class="school-teacher">--</span>
							</div>
								<div class="second_head_item flex_content width_130">
									<span>
										<!-- {{itemAll.progressRate?itemAll.progressRate:'--'}}% -->
									</span>
								</div>
								<div class="second_head_item flex_content width_130">
								</div>
							</div>
							<div class="" v-if="itemAll.showWrit">
								<div class="writTable_third_head">
									<div v-for="(item, index) in thirdHead" :key="index"
										class="third_head_item flex_content">
										{{item.value}}
									</div>
								</div>
								<div v-for="(oitem, oindex) in itemAll.writList" :key="oindex"
									class="writTable_third_content">
									<div class="third_content_item flex_content">
										{{oitem.paperworkTitle}}
									</div>
									<div class="third_content_item flex_content">
										<span>{{oitem.paperworkFlowTitle?oitem.paperworkFlowTitle:'-'}}</span>
									</div>
									<div class="third_content_item flex_content">
										{{oitem.lastUpdateTime?oitem.lastUpdateTime:'--'}}{{oitem.createUser?oitem.createUser:""}}
									</div>
									<div class="third_content_item flex_content">
										{{oitem.lastPlanTime?oitem.lastPlanTime:'--'}}{{oitem.planUser?oitem.planUser:""}}
									</div>
									<div class="third_content_item flex_content">
										{{oitem.createTime?oitem.createTime:'--'}}
									</div>
									<div class="third_content_item flex_content">
										{{oitem.version?oitem.version:'--'}}版
									</div>
									<div class="third_content_item flex_content">
										<span v-if="oitem.progressRate==100" class="green">已完成</span>
										<span v-if="oitem.progressRate==50" class="red">进行中</span>
										<span v-if="oitem.progressRate==0" class="gray">未开始</span>
									</div>
									<div class="third_content_item flex_content">
										{{oitem.deleteTime?oitem.deleteTime:""}}
										{{oitem.deleteUser?oitem.deleteUser:'--'}}
									</div>
									<div class="third_content_item flex_content">
										<span class="writTable_btn" @click="writTableHandle(oitem,itemAll)">查看文书流</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		listSchoolWritOfschool,
		listSchoolWritOfwrit,
		commonWorkPross,
	} from "@/api/applicationProgress"
	import Bus from "./eventBus.js"
	export default {
		data() {
			return {
				firstHead: [{
						prop: "",
						value: "学校",
						class: "width_flex"
					},
					{
						prop: "",
						value: "文书老师",
						class: "width_130"
					},
					{
						prop: "",
						value: "申请批次",
						class: "width_130"
					},
					{
						prop: "",
						value: "截止日期",
						class: "width_130"
					},
					{
						prop: "",
						value: "专业",
						class: "width_130"
					},
					{
						prop: "",
						value: "备注",
						class: "width_300"
					},
					{
						prop: "",
						value: "进度",
						class: "width_130"
					},
					{
						prop: "",
						value: "操作",
						class: "width_130"
					}
				],
				thirdHead: [{
						prop: "",
						value: "文书标题"
					},
					{
						prop: "",
						value: "最新版本"
					},
					{
						prop: "",
						value: "最近更新时间"
					},
					{
						prop: "",
						value: "下一稿时间截点"
					},
					{
						prop: "",
						value: "开始时间"
					},
					{
						prop: "",
						value: "版本"
					},
					{
						prop: "",
						value: "状态"
					},
					{
						prop: "",
						value: "删除人"
					},
					{
						prop: "",
						value: "操作"
					},
				],
				schoolList: [],
				diaLogTitle: "",
				diaLogVisible: false,
				commonWrite: [],
				isOpen: false,
				commonPross: 0, //通用文书进度
			}
		},
		computed: {
			showDeleteWrit() {
				if (this.schoolList.length == 0 && this.commonWrite.length == 0) {
					return false
				} else {
					return true
				}
			},
			showSchoolDelete() {
				return this.schoolList.filter(d => d.showDeleteWrite === true).length
			}
		},
		props: {
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		mounted() {
			Bus.$on("handleWritFlowSchool", props => {
				this.schoolWritListOfschools()
				this.commitWriteLists()
				// this.getPaperWorkProsslist()
			})
		},
		created() {
			// this.sendType(false)
		},
		methods: {
			sendType(val) {
				console.log(val, 'qweqwewqewqewqewqewqewqe')
				Bus.$emit("sendProps", val)
			},
			//文书下载
			uploadFiles(item) {
				if (item.fileUrl) {
					window.open(item.fileUrl);
				}
			},
			//学校文书-学校列表
			schoolWritListOfschools() {
				return new Promise((resolve, reject) => {
					this.schoolList = []
					let params = {
						studentId: this.studentId
					}
					listSchoolWritOfschool(params).then((res) => {
						if (res.code == 0) {
							let resData = res.data
							if (resData && resData.length > 0) {
								resData.forEach(async item => {
									this.$set(item, "showWrit", false)
									await this.schoolWritListOfwrit(item
										.schoolId, 2, item)
								})
							}
							this.schoolList = resData
						}
					})
				})
			},
			// 学校文书-文书删除列表
			schoolWritListOfwrit(id, type, obj) {
				return new Promise((resolve, reject) => {
					let params = {
						schoolId: id,
						studentId: this.studentId,
						type: type,
						isDelete: -1,
					}
					listSchoolWritOfwrit(params).then((res) => {
						if (res.code == 0) {
							if (res.data.length > 0) {
								obj.showDeleteWrite = true
								this.$set(obj, "writList", res.data)
							} else {
								obj.showDeleteWrite = false
							}
						}
					})
				})
			},
			//查询通用文书进度接口
			// getPaperWorkProsslist(){
			// 	var params = {
			// 		studentId:this.studentId
			// 	}
			// 	commonWorkPross(params).then(res=>{
			// 		if(res.code == 0){
			// 			this.commonPross = res.data
			// 		}
			// 	})
			// },
			//查询删除通用文书
			commitWriteLists() {
				this.commonWrite = []
				var params = {
					studentId: this.studentId,
					type: 1,
					schoolId: 0,
					isDelete: -1,
				}
				listSchoolWritOfwrit(params).then((res) => {
					if (res.code == 0) {
						this.commonWrite = res.data
					}
				})
			},
			handleFn(type, item) {
				switch (type) {
					case "commomWrite":
						this.isOpen = !this.isOpen
						break;
					case "showSchoolWrit":
						item.showWrit = !item.showWrit;

						break;
				}
			},
			writTableHandle(item, itemAll) {
				console.log(item, itemAll)
				var type = ""
				if (itemAll == 'commonWrit') {
					type = "通用文书"
				} else {
					type = itemAll.name
				}
				Bus.$emit("handleWritFlowForm", {
					handleType: type,
					...item
				})
			}
		}
	}
</script>

<style lang="scss" scoped="">
	@import "../../../styles/index.scss";

	.write-flex {
		display: flex;
	}

	.delete-write {
		font-size: 16px;
		color: #333333;
		border-bottom: 1px solid #5BA897
	}

	.green {
		color: #5BA897
	}

	.red {
		color: #D0743B
	}

	.gray {
		color: #999999
	}

	.flex_column {
		display: flex;
		flex-direction: column;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex_content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.flex_start {
		display: flex;
		align-items: center;
		justify-content: flex-start !important;
		padding-left: 40px;
	}

	.head_item_school {
		max-width: 450px;
		line-height: 20px;
		padding-left: 40px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.head_item_remark {
		width: 300px;
		line-height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.handle_form {
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			// padding: 0 23px;

			.handle_form_item {
				// flex: 1;
				display: flex;

				.handle_form_ipt {
					width: 400px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					padding: 0 10px;
					box-sizing: border-box;
					margin-right: 150px;
				}

				.handle_form_select {
					width: 200px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					margin-top: 9px;
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #FFFFFF;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFF;
				text-align: center;
				cursor: pointer;
			}
		}
	}

	.writTable_btn {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		margin-right: 20px;
		color: $theme_color;
		cursor: pointer;
		user-select: none;
	}

	.writTable {

		.writTable_inner {
			width: 100%;
			border: 1px solid #EEEEEE;
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;

			.writTable_first_head {
				display: flex;
				align-items: center;
				background: #ECECEC;

				.first_head_item {
					// flex: 1;
					height: 44px;
					text-align: center;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #666666;

					&.width_130 {
						width: 130px !important;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					&.width_300 {
						width: 300px !important;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					&.width_flex {
						flex: 1;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

				}

			}

			.writTable_second_head {
				display: flex;
				align-items: center;
				height: 60px;
				background-color: #FAFAFA;
				cursor: pointer;

				.second_head_item {
					.school-teacher {
						line-height: 20px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #333333;
						overflow: hidden;
						cursor: pointer;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
					}

					// flex: 1;
					height: 60px;
					// line-height: 60px;
					text-align: center;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #666666;
					position: relative;

					.second_head_icon {
						position: absolute;
						width: 16px;
						height: 16px;
						left: 16px;
						top: 22px;
					}

					&.width_130 {
						width: 130px !important;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					&.width_300 {
						width: 300px !important;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					&.width_flex {
						flex: 1;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}

			.writTable_third_head {
				display: flex;
				align-items: center;
				background: #ffffff;

				.third_head_item {
					flex: 1;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 48px;
					color: #999999;
				}
			}

			.writTable_third_content {
				display: flex;
				align-items: center;
				background: #ffffff;

				.third_content_item {
					flex: 1;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 36px;
					color: #333333;
				}
			}
		}
	}

	.nodta-box {
		width: 300px;
		height: 250px;
		margin: 0 auto;
		text-align: center;
		color: #999999;
		font-size: 14px;
		margin-top: 200px;

		img {
			width: 300px;
			height: 250px;
		}
	}
</style>
