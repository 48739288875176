<template>
	<div class="writTable">
		<!-- v-if="schoolList.length>0&&showWrit||commonWrite.length>0&&showWrit" -->
		<div v-if="studentId">
			<div v-if="showWrit">
				<!-- 通用文书 -->
				<div class="writTable_inner">
					<!-- 一级标题 -->
					<!-- 二级内容-学校 -->
					<div class="flex_column">
						<div class="writTable_second_head">
							<div @click="handleFn('commomWrite')" class="second_head_item width_flex flex_start">
								<img v-if="isOpen" class="second_head_icon"
									src="@/assets/images/applicationProgress/arrow_up_circle.png">
								<img v-if="!isOpen" class="second_head_icon"
									src="@/assets/images/applicationProgress/arrow_down_circle.png">
								<span>通用文书</span>
							</div>
							<div class="second_head_item flex_content width_130">
								<span class="school-teacher"></span>
							</div>
							<div class="second_head_item flex_content width_130">
								<span></span>
							</div>
							<div class="second_head_item flex_content width_130">
								<span></span>
							</div>
							<div class="second_head_item flex_content width_130">
								<span></span>
							</div>
							<div class="second_head_item flex_content width_300">
								<span></span>
							</div>
							<div class="second_head_item flex_content width_130">
								<span>{{commonPross}}%</span>
							</div>
							<div class="second_head_item flex_content width_130">
								<span class="writTable_btn" @click="handleFn('addCommonWrit')">添加文书</span>
								<!-- <span class="writTable_btn" @click="handleFnDelete(oitem)">删除</span> -->
							</div>
						</div>
						<div class="" v-if="isOpen">
							<div class="writTable_third_head">
								<div v-for="(item, index) in thirdHead" :key="index"
									class="third_head_item flex_content">
									{{item.value}}
								</div>
							</div>
							<div v-for="(oitem, index) in commonWrite" :key="index" class="writTable_third_content">
								<div class="third_content_item flex_content">
									{{oitem.paperworkTitle}}
								</div>
								<div class="third_content_item flex_content" @click="uploadFiles(oitem)">
									<span>{{oitem.paperworkFlowTitle?oitem.paperworkFlowTitle:'-'}}</span>
								</div>
								<div class="third_content_item flex_content">
									{{oitem.lastUpdateTime?oitem.lastUpdateTime:'-'}}
									{{oitem.createUser?oitem.createUser:""}}
								</div>
								<div class="third_content_item flex_content">
									{{oitem.lastPlanTime?oitem.lastPlanTime:'--'}} {{oitem.planUser?oitem.planUser:""}}
								</div>
								<div class="third_content_item flex_content">
									{{oitem.createTime?oitem.createTime:'--'}}
								</div>
								<div class="third_content_item flex_content">
									{{oitem.version?oitem.version:'--'}}<span v-if="oitem.version">版</span>
								</div>
								<div class="third_content_item flex_content">
									<span v-if="oitem.progressRate==100" class="green">已完成</span>
									<span v-if="oitem.progressRate==50" class="red">进行中</span>
									<span v-if="oitem.progressRate==0" class="gray">未开始</span>
								</div>
								<div class="third_content_item flex_content">
									<span class="writTable_btn"
										@click="writTableHandle(oitem,'commonWrit')">查看文书流</span>
									<span class="writTable_btn min-w"
										@click="handleFn('schoolWritProgressRate', oitem,index)">编辑</span>
									<span class="writTable_btn" @click="handleFnDelete(oitem)">删除</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="writTable_inner" v-if="schoolList.length>0">
					<!-- 一级标题 -->
					<div class="writTable_first_head">
						<span v-for="(item, index) in firstHead" :key="index" :class="item.class"
							class="first_head_item">{{item.value}}</span>
					</div>
					<!-- 二级内容-学校 -->
					<div class="flex_column" v-for="(itemAll, index) in schoolList" :key="index">
						<div class="writTable_second_head">
							<div @click="handleFn('showSchoolWrit', itemAll)" class="second_head_item width_flex">
								<img v-if="itemAll.showWrit" class="second_head_icon"
									src="@/assets/images/applicationProgress/arrow_up_circle.png">
								<img v-if="!itemAll.showWrit" class="second_head_icon"
									src="@/assets/images/applicationProgress/arrow_down_circle.png">
								<el-tooltip popper-class="tooltip_bg" class="item" effect="dark" :content="itemAll.name"
									placement="top-start">
									<span
										class="head_item_school">{{itemAll.name?itemAll.name:'--'}}</span>
								</el-tooltip>
							</div>
							<div class="second_head_item flex_content width_130">
								<el-tooltip v-if="itemAll.teacher" popper-class="tooltip_bg" class="item" effect="dark"
									:content="itemAll.teacher" placement="top-start">
									<span class="school-teacher">{{itemAll.teacher}}</span>
								</el-tooltip>
								<span v-else class="school-teacher">--</span>
							</div>
							<div class="second_head_item flex_content width_130">
								<span>{{itemAll.batch?itemAll.batch:'--'}}</span>
							</div>
							<div class="second_head_item flex_content width_130">
								<span>{{itemAll.customEnd?itemAll.customEnd:'--'}}</span>
							</div>
							<div class="second_head_item flex_content width_130">
								<el-tooltip v-if="itemAll.profession" popper-class="tooltip_bg" class="item"
									effect="dark" :content="itemAll.profession" placement="top-start">
									<span class="school-teacher">{{itemAll.profession}}</span>
								</el-tooltip>
								<span v-else class="school-teacher">--</span>
							</div>
							<div class="second_head_item flex_content width_300">
								<el-tooltip v-if="itemAll.remark" popper-class="tooltip_bg" class="item" effect="dark"
									:content="itemAll.name" placement="top-start">
									<span class="head_item_remark">{{itemAll.remark?itemAll.remark:'--'}}</span>
								</el-tooltip>
								<span v-else class="school-teacher">--</span>
							</div>
							<div class="second_head_item flex_content width_130">
								<span
									@click="handleFn('schoolProgressRate', itemAll, index)">{{itemAll.progressRate?itemAll.progressRate:'--'}}%</span>
							</div>
							<div class="second_head_item flex_content width_130">
								<span class="writTable_btn"
									@click="handleFn('addSchoolWrit', itemAll, index)">添加文书</span>
								<!-- <span class="writTable_btn" @click="handleFnDelete(oitem)">删除</span> -->
							</div>
						</div>
						<div class="" v-if="itemAll.showWrit">
							<div class="writTable_third_head">
								<div v-for="(item, index) in thirdHead" :key="index"
									class="third_head_item flex_content">
									{{item.value}}
								</div>
							</div>
							<div v-for="(oitem, oindex) in itemAll.writList" :key="oindex"
								class="writTable_third_content">
								<div class="third_content_item flex_content">
									{{oitem.paperworkTitle}}
								</div>
								<div class="third_content_item flex_content" @click="uploadFiles(oitem)">
									<span>{{oitem.paperworkFlowTitle?oitem.paperworkFlowTitle:'-'}}</span>
								</div>
								<div class="third_content_item flex_content">
									{{oitem.lastUpdateTime?oitem.lastUpdateTime:'--'}}
									{{oitem.createUser?oitem.createUser:""}}
								</div>
								<div class="third_content_item flex_content">
									{{oitem.lastPlanTime?oitem.lastPlanTime:'--'}} {{oitem.planUser?oitem.planUser:""}}
								</div>
								<div class="third_content_item flex_content">
									{{oitem.createTime?oitem.createTime:'--'}}
								</div>
								<div class="third_content_item flex_content">
									{{oitem.version?oitem.version:'--'}}<span v-if="oitem.version">版</span>
								</div>
								<div class="third_content_item flex_content">
									<span v-if="oitem.progressRate==100" class="green">已完成</span>
									<span v-if="oitem.progressRate==50" class="red">进行中</span>
									<span v-if="oitem.progressRate==0" class="gray">未开始</span>
								</div>
								<div class="third_content_item flex_content">
									<span class="writTable_btn" @click="writTableHandle(oitem,itemAll)">查看文书流</span>
									<span class="writTable_btn min-w"
										@click="handleFn('schoolWritProgressRate', oitem,index,oindex)">编辑</span>
									<span class="writTable_btn" @click="handleFnDelete(oitem)">删除</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 删除的学校 -->
			<delete-write :studentId="studentId" ref="deleteWrite"></delete-write>
			<div v-if="!showWrit" element-loading-spinner="el-icon-loading" element-loading-text="数据加载中~"
				v-loading.lock="!showWrit" class="nodta-box">
			</div>
		</div>
		<div v-else class="nodta-box">
			<img src="@/assets/images/common/empty1.png" class="nodata">
			<div>暂无学校文书</div>
		</div>
		<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false"
			@close="closeDialog" width="579px" :title="diaLogTitle" :visible.sync="diaLogVisible">
			<div class="handle_form">
				<el-form ref="diaDetail" :rules="diaRules" :model="diaDetail" label-width="90px">
					<div v-if="diaLogTitle == '通用文书添加'" class="handle_form_row">
						<div class="handle_form_item">
							<el-form-item prop="paperworkTitle" class="flex_item" label="文书标题:">
								<input class="handle_form_ipt" v-model="diaDetail.paperworkTitle"
									placeholder="请输入文书标题" />
							</el-form-item>
						</div>
					</div>
					<div v-if="diaLogTitle == '学校进度编辑'" class="handle_form_row">
						<div class="handle_form_item">
							<el-form-item prop="progressRate" class="flex_item" label="文书进度:">
								<input class="handle_form_ipt" v-model="diaDetail.progressRate" placeholder="请输入文书进度" />
							</el-form-item>
						</div>
					</div>
					<div v-if="diaLogTitle == '文书进度编辑'" class="handle_form_row">
						<div class="handle_form_item">
							<el-form-item prop="progressRate" class="flex_item" label="文书进度:">
								<el-select v-model="diaDetail.progressRate" placeholder="请选择文书进度">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
								<!-- <input class="handle_form_ipt" v-model="diaDetail.progressRate" placeholder="请选择文书进度" /> -->
							</el-form-item>
						</div>
					</div>
				</el-form>
				<div class="handle_form_btn">
					<span @click="closeDialog" class="handle_form_cancel">取消</span>
					<span v-preventReClick @click="submitDialog" class="handle_form_confirm">确定</span>
				</div>
			</div>
		</el-dialog>
		<messege-box ref="messegeBox" @confirmMessege="confirmMessege" @closeMessege="closeMessege"
			:isOpen="messegeVisible" :messegeTip="messegeTip" :messegeContent="messegeContent"
			:messegeType="messegeType" />
	</div>
</template>

<script>
	import {
		listSchoolWritOfschool,
		listSchoolWritOfwrit,
		AddSchoolWrit,
		updateSchoolProgressRate,
		updateSchoolWritProgressRate,
		commonWorkPross,
		commonWorkDelete
	} from "@/api/applicationProgress";
	import deleteWrite from "./deleteWrite.vue";
	import Bus from "./eventBus.js";
	export default {
		components: {
			deleteWrite
		},
		data() {
			return {
				firstHead: [{
						prop: "",
						value: "学校",
						class: "width_flex"
					},
					{
						prop: "",
						value: "文书老师",
						class: "width_130"
					},
					{
						prop: "",
						value: "申请批次",
						class: "width_130"
					},
					{
						prop: "",
						value: "截止日期",
						class: "width_130"
					},
					{
						prop: "",
						value: "专业",
						class: "width_130"
					},
					{
						prop: "",
						value: "备注",
						class: "width_300"
					},
					{
						prop: "",
						value: "进度",
						class: "width_130"
					},
					{
						prop: "",
						value: "操作",
						class: "width_130"
					}
				],
				thirdHead: [{
						prop: "",
						value: "文书标题"
					},
					{
						prop: "",
						value: "最新版本"
					},
					{
						prop: "",
						value: "最近更新时间"
					},
					{
						prop: "",
						value: "下一稿时间截点"
					},
					{
						prop: "",
						value: "开始时间"
					},
					{
						prop: "",
						value: "版本"
					},
					{
						prop: "",
						value: "状态"
					},
					{
						prop: "",
						value: "操作"
					}
				],
				options: [{
						value: "0",
						label: "未开始"
					},
					{
						value: "50",
						label: "进行中"
					},
					{
						value: "100",
						label: "已完成"
					}
				],
				value: "",
				schoolList: [],
				diaLogTitle: "",
				diaLogVisible: false,
				diaDetail: {},
				diaRules: {
					paperworkTitle: [{
						required: true,
						message: "请输入文书标题",
						trigger: "change"
					}],
					progressRate: [{
						required: true,
						message: "请输入文书进度",
						trigger: "change"
					}]
				},
				currentFirst: undefined,
				currentSecond: undefined,
				writeType: "",
				commonWrite: [],
				isOpen: false,
				commonPross: 0, //通用文书进度
				messegeVisible: false,
				messegeTip: "",
				messegeContent: "",
				messegeType: "",
				deleteObj: {},
				writeData: {},
				showSchool: true,
				showCommon: true,
				showDelete: false
			};
		},
		props: {
			studentId: {
				type: String,
				default: function() {
					return "";
				}
			}
		},
		computed: {
			showWrit() {
				if (!this.showSchool || !this.showCommon) {
					return false;
				} else {
					return true;
				}
			}
		},
		watch: {
			diaLogVisible: {
				handler(val) {
					if (!val) {
						this.diaDetail = {};
						this.currentFirst = undefined;
						this.currentSecond = undefined;
						this.$nextTick(() => {
							this.$refs.diaDetail.clearValidate();
						});
					}
				},
				deep: true
			}
		},
		mounted() {
			Bus.$on("handleWritFlowSchool", props => {
				console.log("进来了", props, this.studentId);
				this.showDelete = false;
				this.schoolWritListOfschool();
				this.commitWriteList();
				this.getPaperWorkPross();
			});
			// Bus.$on("sendProps", props => {
			// 	console.log("sendProps", props)
			// 	this.showDelete = props
			// })
		},
		created() {},
		methods: {
			//文书下载
			uploadFiles(item) {
				if (item.fileUrl) {
					window.open(item.fileUrl);
				}
			},
			//学校文书-学校列表
			schoolWritListOfschool() {
				return new Promise((resolve, reject) => {
					this.schoolList = [];
					let params = {
						studentId: this.studentId
					};
					this.showSchool = false;
					listSchoolWritOfschool(params).then(res => {
						if (res.code == 0) {
							let resData = res.data;
							if (resData && resData.length > 0) {
								resData.forEach(async item => {
									this.$set(item, "showWrit", false);
									await this.schoolWritListOfwrit(item.schoolId, 2, item);
								});
							} else {
								this.showSchool = true;
							}
							// console.log("resData", resData)
							this.schoolList = resData;
						} else {
							this.showSchool = true;
						}
					});
				});
			},
			// 学校文书-文书列表
			schoolWritListOfwrit(id, type, obj) {
				return new Promise((resolve, reject) => {
					let params = {
						schoolId: id,
						studentId: this.studentId,
						type: type
					};
					listSchoolWritOfwrit(params).then(res => {
						// console.log("学校文书-文书列表", res)
						if (res.code == 0) {
							// newObj.writList = res.data
							this.$set(obj, "writList", res.data);
							this.showSchool = true;
							resolve(obj);
						} else {
							this.showSchool = true;
							reject();
						}
					});
				});
			},
			//查询通用文书进度接口
			getPaperWorkPross() {
				var params = {
					studentId: this.studentId
				};
				commonWorkPross(params).then(res => {
					if (res.code == 0) {
						this.commonPross = res.data;
					}
				});
			},
			//查询通用文书
			commitWriteList() {
				var params = {
					studentId: this.studentId,
					type: 1,
					schoolId: 0
				};
				this.showCommon = false;
				listSchoolWritOfwrit(params).then(res => {
					if (res.code == 0) {
						this.commonWrite = res.data;
						this.showCommon = true;
					} else {
						this.showCommon = true;
					}
				});
			},
			closeMessege() {
				this.messegeVisible = false;
			},
			confirmMessege() {
				commonWorkDelete(this.deleteObj).then(res => {
					if (res.code == 0) {
						this.messegeVisible = false;
						this.$message({
							message: "学生文书删除成功~",
							type: "success"
						});
						if (this.writeData.type == 1) {
							this.commitWriteList();
							this.getPaperWorkPross();
							this.$refs.deleteWrite.commitWriteLists();
							// this.$refs.deleteWrite.getPaperWorkProsslist()
						} else {
							this.schoolWritListOfschool();
							this.$refs.deleteWrite.schoolWritListOfschools();
						}
					}
				});
				// this.differentTabFn('tabelDelete', this.deleteObj)
			},
			//文书删除
			handleFnDelete(item) {
				console.log(item);
				this.writeData = item;
				this.messegeVisible = true;
				this.messegeTip = "信息提示";
				this.messegeContent = "确定删除当前选中文书吗？";
				this.messegeType = "warn";
				this.deleteObj = {
					id: item.id
				};
			},
			handleFn(type, item, indexFirst, indexSecond) {
				switch (type) {
					case "commomWrite":
						this.isOpen = !this.isOpen;
						break;
					case "showSchoolWrit":
						item.showWrit = !item.showWrit;
						break;
					case "addSchoolWrit":
						// console.log("addSchoolWrit", item)
						let params = {
							studentId: item.studentId,
							schoolId: item.schoolId,
							paperworkTitle: "",
							remark: "",
							type: "2",
							title: ""
						};
						if (item.writList) {
							if (item.writList.length == 0) {
								params.paperworkTitle = "Supplement1";
							} else {
								let titleVal = item.writList.map(item => {
									if (
										item.paperworkTitle &&
										item.paperworkTitle.includes("Supplement")
									) {
										return item.paperworkTitle.replace("Supplement", "");
									}
								});
								let maxNum = Math.max(...titleVal);
								params.paperworkTitle = `Supplement${maxNum + 1}`;
							}
						} else {
							params.paperworkTitle = "Supplement1";
						}
						console.log("addSchoolWrit", indexFirst, params);
						AddSchoolWrit(params).then(res => {
							if (res.code == 0) {
								this.$message({
									message: "文书添加成功~",
									type: "success"
								});
								item.writList.push(res.data);
								this.$forceUpdate();
							}
						});
						break;
					case "addCommonWrit":
						this.diaDetail = {
							studentId: this.studentId,
							schoolId: 0,
							paperworkTitle: "",
							paperworkFlowTitle: "",
							remark: "",
							type: "1",
							title: ""
						};
						this.currentFirst = indexFirst;
						this.diaLogTitle = "通用文书添加";
						this.diaLogVisible = true;
						break;
					case "schoolProgressRate":
						this.diaDetail = {
							id: item.id,
							progressRate: ""
						};
						this.currentFirst = indexFirst;
						this.diaLogTitle = "学校进度编辑";
						this.diaLogVisible = true;
						break;
					case "schoolWritProgressRate":
						console.log("文书进度", item, indexFirst, indexSecond);
						this.diaDetail = {
							id: item.id,
							progressRate: item.progressRate
						};
						this.writeType = item.type;
						this.currentFirst = indexSecond;
						this.currentSecond = indexFirst;
						this.diaLogTitle = "文书进度编辑";
						this.diaLogVisible = true;
						break;
				}
			},
			submitDialog() {
				this.$refs["diaDetail"].validate(valid => {
					if (valid) {
						if (this.diaLogTitle == "通用文书添加") {
							AddSchoolWrit(this.diaDetail).then(res => {
								if (res.code == 0) {
									this.$message({
										message: "通用文书添加成功~",
										type: "success"
									});
									this.commitWriteList();
									this.getPaperWorkPross();
									this.diaLogVisible = false;
								}
							});
						} else if (this.diaLogTitle == "文书进度编辑") {
							updateSchoolWritProgressRate(this.diaDetail).then(res => {
								if (res.code == 0) {
									console.log(
										this.schoolList,
										this.currentFirst,
										this.currentSecond
									);
									if (this.writeType == 1) {
										this.commonWrite[this.currentSecond].progressRate =
											res.data.progressRate;
										this.getPaperWorkPross();
									} else {
										// this.schoolList[this.currentFirst].writList[this.currentSecond]
										// 	.progressRate = res.data.progressRate
										this.schoolWritListOfschool();
									}
									this.diaLogVisible = false;
									this.$forceUpdate();
								}
							});
						} else if (this.diaLogTitle == "学校进度编辑") {
							updateSchoolProgressRate(this.diaDetail).then(res => {
								if (res.code == 0) {
									this.schoolList[this.currentFirst].progressRate =
										res.data.progressRate;
									this.diaLogVisible = false;
									this.$forceUpdate();
								}
							});
						}
					}
				});
			},
			closeDialog() {
				this.diaLogVisible = false;
			},
			writTableHandle(item, itemAll) {
				console.log(item, itemAll);
				var type = "";
				if (itemAll == "commonWrit") {
					type = "通用文书";
				} else {
					type = itemAll.name;
				}
				Bus.$emit("handleWritFlowForm", {
					handleType: type,
					...item
				});
			}
		}
	};
</script>

<style lang="scss" scoped="">
	@import "../../../styles/index.scss";

	.green {
		color: #5ba897;
	}

	.red {
		color: #d0743b;
	}

	.gray {
		color: #999999;
	}

	.flex_column {
		display: flex;
		flex-direction: column;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex_content {
		display: flex;
		align-items: center;
		justify-content: center;
	}

  .flex_start {
    display: flex;
		align-items: center;
		justify-content: flex-start !important;
    padding-left: 40px;
  }

	.head_item_school {
		max-width: 450px;
		line-height: 20px;
		padding-left: 40px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.head_item_remark {
		width: 300px;
		line-height: 20px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.handle_form {
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			// padding: 0 23px;

			.handle_form_item {
				// flex: 1;
				display: flex;

				.handle_form_ipt {
					width: 400px;
					border: 1px solid #eeeeee;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					padding: 0 10px;
					box-sizing: border-box;
					margin-right: 150px;
				}

				.handle_form_select {
					width: 200px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					margin-top: 9px;
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #ffffff;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #fff;
				text-align: center;
				cursor: pointer;
			}
		}
	}

	.min-w {
		margin: 10px;
		display: inline-block;
	}

	.writTable_btn {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: $theme_color;
		cursor: pointer;
		user-select: none;
	}

	.writTable {
		.writTable_inner {
			width: 100%;
			border: 1px solid #eeeeee;
			display: flex;
			flex-direction: column;
			margin-bottom: 20px;

			.writTable_first_head {
				display: flex;
				align-items: center;
				background: #ececec;

				.first_head_item {
					// flex: 1;
					height: 44px;
					text-align: center;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #666666;

					&.width_130 {
						width: 130px !important;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					&.width_300 {
						width: 300px !important;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					&.width_flex {
						flex: 1;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

				}
			}

			.writTable_second_head {
				display: flex;
				align-items: center;
				height: 60px;
				background-color: #fafafa;
				cursor: pointer;

				.second_head_item {
					.school-teacher {
						line-height: 20px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #333333;
						overflow: hidden;
						cursor: pointer;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
					}

					// flex: 1;
					height: 60px;
					line-height: 60px;
					text-align: center;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #666666;
					position: relative;

					.second_head_icon {
						position: absolute;
						width: 16px;
						height: 16px;
						left: 16px;
						top: 22px;
					}

					&.width_130 {
						width: 130px !important;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					&.width_300 {
						width: 300px !important;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
					}

					&.width_flex {
						flex: 1;
						line-height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}

			.writTable_third_head {
				display: flex;
				align-items: center;
				background: #ffffff;

				.third_head_item {
					flex: 1;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 48px;
					color: #999999;
				}
			}

			.writTable_third_content {
				display: flex;
				align-items: center;
				background: #ffffff;

				.third_content_item {
					flex: 1;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 36px;
					text-align: center;
					color: #333333;
				}
			}
		}
	}

	.nodta-box {
		width: 300px;
		height: 250px;
		margin: 0 auto;
		text-align: center;
		color: #999999;
		font-size: 14px;
		margin-top: 200px;

		img {
			width: 300px;
			height: 250px;
		}
	}
</style>
