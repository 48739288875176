<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="981px" :title="diaLogTitle" :visible.sync="diaLogVisible">
		<div class="handle_form">
			<el-form ref="writDetail" :rules="writRules" :model="writDetail" label-width="90px">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="任务完成情况:">
							<div class="handle_form_num">
								<span class="form_num_green">{{writDetail.finishCount}}</span>
								<span>/{{writDetail.count}}</span>
							</div>
						</el-form-item>
					</div>
					<!-- <div class="handle_form_item">
						<el-form-item class="flex_item" label="任务总数:">
							<el-input-number :min="1" :max="1000"></el-input-number>
						</el-form-item>
					</div> -->
				</div>
				<div class="" v-for="(item,index) in writDetail.controlPanelPaperworkDTOS" :key="index">
					<div class="handle_form_row flex_between">
						<div class="handle_form_item">
							<el-form-item label-width="60px" class="" label="">
								<span class="handle_form_label">{{item.paperworkTitle}}</span>
							</el-form-item>
						</div>
						<div class="handle_form_item">
							<el-form-item class="flex_item" label="状态:">
								<el-select v-model="item.state" class="handle_form_select" clearable
									placeholder="请选择" @change="writeChange($event,item)">
									<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
										:value="item.value" >
									</el-option>
								</el-select>
							</el-form-item>
						</div>
					</div>
					<div class="handle_form_row">
						<div class="handle_form_user" v-for="(el,index) in item.list" :key="index">
							<span class="margin_right_66">终稿人姓名：{{el.finalVersionUser}}</span>
							<span>终稿文书时间：{{el.finalVersionTime?el.finalVersionTime:"-"}}</span>
						</div>
					</div>
					<div class="handle_form_row">
						<div>
							<span class="write-submituser" v-if="item.stateUpdateUser">提交人：{{item.stateUpdateUser}}</span>
							<span class="write-submituser" v-if="item.stateUpdateTime">提交时间：{{item.stateUpdateTime}}</span></div>
					</div>
					<div class="handle_form_delete_box">
						<div class="handle_form_row">
							<div class="handle_form_link">
								<span class="margin_right_66" @click="goWriteForm(item,'write')">跳转到学校库文书</span>
								<span @click="goWriteForm(item,'writeFlow')">跳转到文书流</span>
							</div>
						</div>
						<!-- <div class="handle_form_row flex-start delete-left" @click="handleDelete(item,index)">
							<img class="delete-icon" src="@/assets/images/common/delete-icon.png" alt="">
							<span class="delete-text">删除任务</span>
						</div> -->
					</div>
					<div class="bottom_line"></div>
				</div>
			</el-form>
			<div class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span v-preventReClick @click="submitWriteForm" class="handle_form_confirm">确定</span>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import Bus from "./eventBus.js";
	import dragUpLoad from "@/components/dragUpLoad/index.vue";
	import {
		writeFormList,
		writeFromSave
	} from "@/api/acTable"
		import {
		detailSchool
	} from "@/api/applicationProgress"
	export default {
		components: {
			dragUpLoad
		},
		props: {},
		watch: {},
		mounted() {
			Bus.$on("handleWritForm", props => {
				this.propsData = props.row
				this.diaLogVisible = true
				this.getWriteFormList()
				// console.log("handleWritForm", props)
			})
		},
		data() {
			return {
				diaLogTitle: "文书详情",
				diaLogVisible: false,
				writDetail: {},
				writRules: {},
				mainTeachers:[
					{
						value: '未开始',
						label: '未开始'
						},
						{
						value: '进行中',
						label: '进行中'
						}, {
						value: '已完成',
						label: '已完成'
						}
				],
				propsData:[],
				editParams:{
					schoolPaperworkStateVOS:[],
				},
				deleteIds:[],
				
			}
		},
		methods: {
			//点击删除
			handleDelete(item,index){
				console.log(item)
				this.deleteIds.push(item.paperworkId)
				this.writDetail.controlPanelPaperworkDTOS.splice(index,1)
				this.writDetail.count--
				console.log(this.deleteIds)
			},
			//查询学校详情
			getSchoolDetail(id,type){
				detailSchool({schoolId:id}).then(res=>{
					if(res.code ==0){
						this.schoolDetailData = res.data
					}else{
						 this.$message({
							message: res.msg,
							type: 'warning'
							});
						}
				})
			},
			//查看文书
			getWriteFormList(){
				var params = {
					studentId: this.propsData.studentId,
					schoolId:this.propsData.schoolId
				}
				writeFormList(params).then(res=>{
					if(res.code == 0){
						if(res.data){
							this.writDetail = res.data
							
						}
					}
				})
			},
			writeChange(value,item){
				this.editParams.schoolPaperworkStateVOS = []
				item.state=value
				this.writDetail.controlPanelPaperworkDTOS.forEach(el=>{
					let obj = {
						id:el.paperworkId,
						state:el.state||""
					}
					this.editParams.schoolPaperworkStateVOS.push(obj)
				})

			},

			goWriteForm(item,type){
				switch(type){
					case 'write':
						this.$parent.changeCurrentTab(this.propsData.studentId)
						// Bus.$emit("changeCurrentTab", {
						// 	studentId: this.propsData.studentId,
						// })
						break;
					case 'writeFlow':
						this.$parent.changeCurrentTab(this.propsData.studentId)
						this.$nextTick(()=>{
							this.writTableHandle(item)
						})
						
						break;
				}
			},
			//
			writTableHandle(item) {
				console.log(item,'item得数据')
				var type = ""
				if(item.type==1){
					type = "通用文书"
				}else {
					type = this.propsData.name
				}
				Bus.$emit("handleWritFlowForm", {
					handleType: type,
					...item
				})
			},
			//保存文书
			submitWriteForm(){
				this.editParams.schoolId = this.propsData.schoolId
				this.editParams.studentId = this.propsData.studentId
				// console.log(this.editParams)
				// return
				writeFromSave(this.editParams).then(res=>{
					if(res.code == 0){
						this.$message({
							message: '编辑成功',
							type: 'success'
						});
						this.closeDialog()
						this.$parent.getAcLists()
					}
				})
			},
			closeDialog() {
				this.diaLogVisible = false
				// this.$emit('closeDialog', 'commom')
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";
	.write-submituser{
		display: inline-block;
		margin-right:66px;
		color: #333333;
		font-family: Source Han Sans CN;
		font-weight: 400;
	}
	/deep/.el-date-editor.el-input :focus {
		border: 1px solid $theme_color;
	}

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.flex_between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.margin_right_66 {
		margin-right: 66px;
	}
	
	.bottom_line {
		width: 100%;
		height: 1px;
		margin-top: 20px;
		background-color: #EEEEEE;
	}

	.handle_form {
		max-height:600px;
		overflow: hidden;
		overflow-y: scroll;
		.delete-left{
				cursor: pointer;
			}
			.delete-icon{
				width:14px;
				height:14px;
				margin-right:8px;
			}
			.delete-text{
				font-size:14px;
			}
			.handle_form_delete_box{
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			.handle_form_label {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #333333;
			}
			.handle_form_link {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: $theme_color;
				span {
					cursor: pointer;
				}
			}
			
			.handle_form_user {
				// margin-left: auto;
				margin-right:23px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				line-height: 20px;
				color: #333333;
			}

			.handle_form_item {
				// flex: 1;
				display: flex;

				.handle_form_num {
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 20px;
					color: #333333;

					.form_num_green {
						color: $theme_color;
					}
				}

				.handle_form_ipt {
					width: 240px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					padding: 0 10px;
					box-sizing: border-box;
					margin-right: 150px;
				}

				.handle_form_date {
					width: 240px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
				}

				.handle_form_select {
					width: 200px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
				}

				.handle_form_textarea {
					width: 930px;
					height: 120px;
					background: #FFFFFF;
					border: 1px solid #EEEEEE;
					border-radius: 4px;
					outline: none;
					resize: none;
					padding: 6px 10px;
					box-sizing: border-box;
					margin: 8px 0 0 0px;
				}

				.handle_form_upload {
					display: flex;
					flex-direction: column;

					.form_upload_box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 128px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #EEEEEE;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #000000;
						cursor: pointer;

						.form_upload_icon {
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
					}

					.form_upload_tip {
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 17px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #FFFFFF;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFF;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>
