<template>
	<div class="addDraw" v-if="showDraw">
		<div class="addDraw_inner">
			<div class="addDraw_inner_back">
				<div @click="closeDraw" class="inner_back_btn">
					<img src="@/assets/images/common/back.png">
					<span>返回</span>
				</div>
			</div>
			<div class="addDraw_inner_content">
				<div class="addDraw_inner_title">
					<span>所有学校</span>
				</div>
				<div class="addDraw_inner_filter">
					<div class="inner_filter_list">
						<span class="inner_filter_label">
							排名:
						</span>
						<div class="inner_filter_value">
							<span @click="changeFilter('rank', item)" v-for="(item, index) in rankList" :key="index"
								:class="{'inner_filter_select': filterData.rank == item}">{{item}}</span>
						</div>
					</div>
					<div class="inner_filter_list">
						<span class="inner_filter_label">
							性质:
						</span>
						<div class="inner_filter_value">
							<span @click="changeFilter('nature', item)" v-for="(item, index) in natureList" :key="index"
								:class="{'inner_filter_select': filterData.nature == item}">{{item}}</span>
						</div>
					</div>
					<div class="inner_filter_list">
						<span class="inner_filter_label">
							构成:
						</span>
						<div class="inner_filter_value">
							<span @click="changeFilter('structure', item)" v-for="(item, index) in structureList"
								:key="index"
								:class="{'inner_filter_select': filterData.structure == item}">{{item}}</span>
						</div>
					</div>
					<div class="inner_filter_list">
						<span class="inner_filter_label">
							类型:
						</span>
						<div class="inner_filter_value">
							<span @click="changeFilter('type', item)" v-for="(item, index) in typeList" :key="index"
								:class="{'inner_filter_select': filterData.type == item}">{{item}}</span>
						</div>
					</div>
					<div class="inner_filter_list">
						<span class="inner_filter_label">
							地区:
						</span>
						<div class="inner_filter_value">
							<span @click="changeFilter('country', item)" v-for="(item, index) in regionList"
								:key="index"
								:class="{'inner_filter_select': filterData.country == item}">{{item}}</span>
						</div>
					</div>
					<div class="inner_filter_list">
						<span class="inner_filter_label">
							环境:
						</span>
						<div class="inner_filter_value">
							<span @click="changeFilter('environment', item)" v-for="(item, index) in environmentList"
								:key="index"
								:class="{'inner_filter_select': filterData.environment == item}">{{item}}</span>
						</div>
					</div>
				</div>
				<div class="addDraw_inner_list">
					<div v-for="(item,index) in schoolList" :key="index"  @click="drawHandle('edit', item)"
						class="addDraw_inner_item">
						<img :src="item.logo" class="inner_item_logo">
						<div class="inner_item_desc">
							<div class="item_desc_top">
								<div class="flex_item">
									<span class="inner_item_rank">{{index*1+1}}</span>
									<div class="item_desc_name">{{item.name}}</div>
								</div>
								<div @click.stop="drawHandle('add', item)" class="item_desc_btn">
									<img src="@/assets/images/applicationProgress/add.png">
									<span>添加</span>
								</div>
							</div>
							<div class="item_desc_middle">
								<img v-if="item.address" src="@/assets/images/applicationProgress/map.png" class="item_desc_icon">
								<span>{{item.address}}</span>
							</div>
							<div class="item_desc_bottom">
								<div class="flex_item">
									<img v-if="item.type" src="@/assets/images/applicationProgress/icon1.png" class="item_desc_icon">
									<span class="item_desc_value">{{item.type}}</span>
								</div>
								<div class="flex_item">
									<img v-if="item.undergraduateBatch" src="@/assets/images/applicationProgress/icon2.png" class="item_desc_icon">
									<span class="item_desc_value item_desc_ellipsis">{{item.undergraduateBatch}}</span>
								</div>
								<div class="flex_item">
									<img v-if="item.number" src="@/assets/images/applicationProgress/icon3.png" class="item_desc_icon">
									<span class="item_desc_value">{{item.number}}</span>
								</div>
								<div class="flex_item">
									<img v-if="item.cost" src="@/assets/images/applicationProgress/icon4.png" class="item_desc_icon">
									<span class="item_desc_value">{{item.cost}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Bus from "./eventBus.js";
	import {
		allSchoolList,
		listChooseSchoolAdd
	} from "@/api/applicationProgress"
	export default {
		props:{
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		watch: {},
		data() {
			return {
				showDraw: false,
				filterData: {
					rank: "所有",
					rankStartRang: "-1",
					rankEndRang: "-1",
					nature: "所有",
					structure: "所有",
					type: "所有",
					country: "所有",
					environment: "所有"
				},
				rankList: ["所有", "1-20", "21-36", "37-50", "50-70", "70-100", "100+", "非排名学校", ],
				natureList: ["所有", "综合性大学", "文理学院", "非排名学校", ],
				structureList: ["所有", "男女学校", "女子学院", "男子学院", ],
				typeList: ["所有", "私立", "公立", ],
				regionList: ["所有", "美国", "英国", "加拿大", "其他", ],
				environmentList: ["所有", "城市", "城郊", "乡村", ],
				schoolList: [
				],
			}
		},
		mounted() {
			Bus.$on("schoolHandle", props => {
				this.showDraw = true
				// console.log("schoolHandle", props)
				this.schoolListAll()
			})
		},
		methods: {
			// 获取所有学校列表
			schoolListAll() {
				allSchoolList(this.filterData).then((res) => {
					if (res.code == 0) {
						this.schoolList = res.data
					}
				})
			},
			//选择学校添加
			chooseAddSchool(params){
				let editParams = JSON.parse(JSON.stringify(params))
				listChooseSchoolAdd(editParams).then(res=>{
					if(res.code == 0){
						this.$parent.getShoolList()
					}
				})
			},
			closeDraw() {
				this.showDraw = false
			},
			changeFilter(type, item) {
				if (type == 'rank') {
					console.log("changeFilter", item)
					if (item == '所有') {
						this.filterData.rankStartRang = '-1'
						this.filterData.rankEndRang = '-1'
					} else if (item == '100+') {
						this.filterData.rankStartRang = '101'
						this.filterData.rankEndRang = '100+'
					} else if (item == '非排名学校') {
						this.filterData.rankStartRang = '0'
						this.filterData.rankEndRang = '0'
					} else {
						let rank = item.split("-")
						console.log("rankStartRang", rank)
						this.filterData.rankStartRang = rank[0]
						this.filterData.rankEndRang = rank[1]
					}
				}
				this.filterData[type] = item;
				this.schoolListAll()
			},
			drawHandle(type, item) {
				if(type == 'add'){
					console.log(item)
					if(!this.studentId){
						this.$message({
							message: "请先添加学生~",
							type: 'warning'
						})
						return
					}
					var addParams = {
						schoolId:item.id,
						studentId:this.studentId
					}
					this.chooseAddSchool(addParams)
				}else{
					Bus.$emit("drawHandle", {
					handleType: type,
					...item
				})
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";

	.flex_item {
		display: flex;
		align-items: center;
	}
	
	.flex-1 {
		flex: 1;
	}

	.addDraw {
		width: 626px;
		height: 875px;
		background: #FFFFFF;
		box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.1);
		border-radius: 0px;
		position: absolute;
		top: -20px;
		z-index: 2000;
		right: -40px;

		.addDraw_inner {
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;

			.addDraw_inner_back {
				width: 100%;
				background: #FFFFFF;

				.inner_back_btn {
					width: 100px;
					height: 40px;
					background: #FFFFFF;
					box-shadow: 0px 4px 6px rgba(51, 51, 51, 0.16);
					cursor: pointer;
					border-radius: 0px 20px 20px 0px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 10px 0 10px 0;

					img {
						width: 15px;
						height: 12px;
						margin-right: 10px;
					}

					span {
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						line-height: 20px;
						color: #333333;
					}
				}
			}

			.addDraw_inner_content {
				flex: 1;
				padding: 0px 32px 20px 32px;
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				overflow: hidden;
				overflow-y: auto;

				.addDraw_inner_title {
					display: flex;
					align-items: center;
					justify-content: center;

					span {
						width: 92px;
						line-height: 34px;
						background: $theme_color;
						border-radius: 4px;
						text-align: center;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						user-select: none;
					}
				}

				.addDraw_inner_filter {
					display: flex;
					flex-direction: column;
					margin: 20px 0 8px 0;

					.inner_filter_list {
						display: flex;
						align-items: center;
						margin-bottom: 14px;

						.inner_filter_label {
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 20px;
							color: #333333;
						}

						.inner_filter_value {
							display: flex;
							align-items: center;

							span {
								font-size: 14px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								line-height: 20px;
								color: #666666;
								margin-left: 10px;
								cursor: pointer;
								user-select: none;
							}

							.inner_filter_select {
								color: $theme_color;
								font-weight: bold;
							}
						}
					}
				}

				.addDraw_inner_list {
					width: 100%;
					display: flex;
					flex-direction: column;

					.addDraw_inner_item {
						width: 100%;
						padding: 20px 0;
						border-top: 1px solid #DCDCDC;
						display: flex;
						cursor: pointer;

						.inner_item_logo {
							width: 100px;
							height: 100px;
							border: 1px solid #EEEEEE;
							border-radius: 2px;
							margin-right: 10px;
						}

						.inner_item_desc {
							flex: 1;
							display: flex;
							flex-direction: column;

							.item_desc_top {
								display: flex;
								align-items: center;
								justify-content: space-between;

								.item_desc_btn {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 77px;
									line-height: 26px;
									background: $theme_color;
									border-radius: 2px;
									cursor: pointer;
									user-select: none;

									img {
										width: 11px;
										height: 11px;
										margin-right: 8px;
									}

									span {
										font-size: 14px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										color: #FFFFFF;
									}
								}

								.inner_item_rank {
									width: 18px;
									line-height: 18px;
									background: $theme_color;
									border-radius: 2px;
									margin-right: 10px;
									text-align: center;
									font-size: 14px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									color: #FFFFFF;
								}

								.item_desc_name {
									flex: 1;
									font-size: 16px;
									font-family: Source Han Sans CN;
									font-weight: bold;
									line-height: 18px;
									color: #333333;
								}
							}

							.item_desc_icon {
								width: 16px;
								height: 16px;
								margin-right: 10px;
							}

							.item_desc_middle {
								display: flex;
								align-items: flex-start;
								margin: 6px 0 10px 0;

								span {
									flex: 1;
									font-size: 14px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									line-height: 20px;
									color: #666666;
									word-break: break-all;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 2;
								}
							}

							.item_desc_bottom {
								display: flex;
								align-items: center;

								.item_desc_value {
									font-size: 14px;
									font-family: Source Han Sans CN;
									font-weight: 400;
									line-height: 20px;
									color: #666666;
									margin-right: 20px;
								}

								.item_desc_ellipsis {
									max-width: 130px;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 1;
								}
							}
						}
					}
				}
			}
		}
	}
</style>
