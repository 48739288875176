/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-04-20 09:02:50
 * @LastEditTime: 2021-10-20 14:14:01
 */
import axios from '@/utils/request'
const Api = {
	schoolSelectList: "/api/backyard/schoolSelection/normal/list",
	chooseSchoolList: "/api/backyard/schoolSelection/normal/list",
	chooseSchoolDel: "/api/backyard/schoolSelection/delete",
	chooseSchoolUpdate: "/api/backyard/schoolSelection/update",
	chooseSchoolHistory: "/api/backyard/schoolSelection/delete/list",
	chooseSchoolAdd: "/api/backyard/schoolSelection/add",
	schoolListAll: "/api/backyard/school/listall",
	schoolListWrit: "/api/backyard/schoolCulture/list",
	schoolAddWrit: "/api/backyard/schoolCulture/save",
	schoolListstandard: "/api/backyard/schoolStandardize/query",
	schoolAddstandard: "/api/backyard/schoolStandardize/save",
	schoolListmajor: "/api/backyard/schoolProfession/list",
	schoolAddmajor: "/api/backyard/schoolProfession/save",
	schoolListinterview: "/api/backyard/schoolInterview/detail",
	schoolAddinterview: "/api/backyard/schoolInterview/save",
	schoolListDesc: "/api/backyard/schoolIntroduce/detail",
	schoolAdddesc: "/api/backyard/schoolIntroduce/save",
	schoolListCase: "/api/backyard/schoolCase/list",
	schoolAddcase: "/api/backyard/schoolCase/save",
	schoolListLog: "/api/backyard/schoolLog/query",
	schoolListTag: "/api/backyard/schoolKeyword/query",
	schoolAddtag: "/api/backyard/schoolKeyword/save",
	schoolEdittag: "/api/backyard/schoolKeyword/update",
	schoolWritListOfschool: "/api/backyard/schoolPaperwork/list",
	schoolWritListOfwrit: "/api/backyard/schoolPaperwork/list/spw",
	schoolWritAdd: "/api/backyard/schoolPaperwork/save",
	schoolWritRemarkAdd:"/api/backyard/schoolPaperwork/saveremark",
	schoolProgressRateUpdate: "/api/backyard/schoolPaperwork/list/uspr",
	schoolWritProgressRateUpdate: "/api/backyard/schoolPaperwork/list/upr",
	schoolWriteflowQuery:"/api/backyard/schoolPaperworkFlow/listflows",
	schoolWritFlowTitle:"/api/backyard/schoolPaperwork/detail",
	writeFlieVersion:"/api/backyard/schoolPaperworkFlow/cv",
	workFlowAdd:"/api/backyard/schoolPaperworkFlow/create",
	finalVersionSet:"/api/backyard/schoolPaperworkFlow/setfinal",
	schoolDetail:"/api/backyard/school/detail",
	standardMax:"/api/studentInfo/getStandardMax",
	prossCommonWork:"/api/backyard/schoolPaperwork/common",
	deleteCommonWork:"/api/backyard/schoolPaperwork/delete",
	queryTeacher:"/api/backyard/schoolPaperworkFlow/getteachers"

}
//查看文书流老师
export function teacherQuery(params) {
	return axios({
		method: 'get',
		url: Api.queryTeacher,
		params: params
	})
}
//删除文书
export function commonWorkDelete(params) {
	return axios({
		method: 'get',
		url: Api.deleteCommonWork,
		params: params
	})
}
//查询通用文书进度
export function commonWorkPross(params) {
	return axios({
		method: 'get',
		url: Api.prossCommonWork,
		params: params
	})
}
//获取最高成绩
export function maxStandard(params) {
	return axios({
		method: 'post',
		url: Api.standardMax,
		data: params
	})
}
//通过id获取学校列表
export function selectSchoolList(params) {
	return axios({
		method: 'get',
		url: Api.schoolSelectList,
		params: params
	})
}
//通过id查学校详情
export function detailSchool(params) {
	return axios({
		method: 'get',
		url: Api.schoolDetail,
		params: params
	})
}
// 选校列表-选校添加所有选校
export function allSchoolList(params) {
	return axios({
		method: 'get',
		url: Api.schoolListAll,
		params: params
	})
}
// 选校列表-文书
export function writSchoolList(params) {
	return axios({
		method: 'get',
		url: Api.schoolListWrit,
		params: params
	})
}
// 选校列表-文书添加
export function writSchoolAdd(params) {
	return axios({
		method: 'post',
		url: Api.schoolAddWrit,
		params: params
	})
}
// 选校列表-标化列表
export function standardSchoolList(params) {
	return axios({
		method: 'get',
		url: Api.schoolListstandard,
		params: params
	})
}
// 选校列表-标化更新
export function standardSchoolAdd(params) {
	return axios({
		method: 'post',
		url: Api.schoolAddstandard,
		params: params
	})
}
// 选校列表-专业列表
export function majorSchoolList(params) {
	return axios({
		method: 'get',
		url: Api.schoolListmajor,
		params: params
	})
}
// 选校列表-专业更新
export function majorSchoolAdd(params) {
	return axios({
		method: 'post',
		url: Api.schoolAddmajor,
		data: params
	})
}
// 选校列表-面试列表
export function interviewSchoolList(params) {
	return axios({
		method: 'get',
		url: Api.schoolListinterview,
		params: params
	})
}
// 选校列表-面试更新
export function interviewSchoolAdd(params) {
	return axios({
		method: 'post',
		url: Api.schoolAddinterview,
		params: params
	})
}
// 选校列表-介绍列表
export function descSchoolList(params) {
	return axios({
		method: 'post',
		url: Api.schoolListDesc,
		params: params
	})
}
// 选校列表-介绍更新
export function descSchoolAdd(params) {
	return axios({
		method: 'post',
		url: Api.schoolAdddesc,
		params: params
	})
}
// 选校列表-案例列表
export function caseSchoolList(params) {
	return axios({
		method: 'post',
		url: Api.schoolListCase,
		params: params
	})
}
// 选校列表-案例列表
export function logSchoolList(params) {
	return axios({
		method: 'get',
		url: Api.schoolListLog,
		params: params
	})
}
// 选校列表-案例更新
export function caseSchoolAdd(params) {
	return axios({
		method: 'post',
		url: Api.schoolAddcase,
		data: params
	})
}
// 选校列表-词条列表
export function tagSchoolList(params) {
	return axios({
		method: 'get',
		url: Api.schoolListTag,
		params: params
	})
}
// 选校列表-词条添加
export function tagSchoolAdd(params) {
	return axios({
		method: 'get',
		url: Api.schoolAddtag,
		params: params
	})
}
// 选校列表-词条编辑
export function tagSchoolEdit(params) {
	return axios({
		method: 'get',
		url: Api.schoolEdittag,
		params: params
	})
}
// 选校列表-查看列表
export function listChooseSchool(params) {
	return axios({
		method: 'get',
		url: Api.chooseSchoolList,
		params: params
	})
}
// 选校列表-删除记录
export function hisChooseSchoolHis(params) {
	return axios({
		method: 'get',
		url: Api.chooseSchoolHistory,
		params: params
	})
}
//选校添加
export function listChooseSchoolAdd(params) {
	return axios({
		method: 'get',
		url: Api.chooseSchoolAdd,
		params: params
	})
}
//选校删除
export function delChooseSchool(params) {
	return axios({
		method: 'get',
		url: Api.chooseSchoolDel,
		params: params
	})
}
//选校更新
export function updateChooseSchool(params) {
	return axios({
		method: 'post',
		url: Api.chooseSchoolUpdate,
		params: params
	})
}

//学校文书-学校列表
export function listSchoolWritOfschool(params) {
	return axios({
		method: 'get',
		url: Api.schoolWritListOfschool,
		params: params
	})
}

//学校文书-文书列表
export function listSchoolWritOfwrit(params) {
	return axios({
		method: 'get',
		url: Api.schoolWritListOfwrit,
		params: params
	})
}
//查看文书标题
export function writFlowSchoolTitle(params) {
	return axios({
		method: 'get',
		url: Api.schoolWritFlowTitle,
		params: params
	})
}
//学校文书-文书添加
export function AddSchoolWrit(params) {
	return axios({
		method: 'post',
		url: Api.schoolWritAdd,
		data: params
	})
}
//学校文书-备注添加
export function addSchoolWritRemark(params) {
	return axios({
		method: 'post',
		url: Api.schoolWritRemarkAdd,
		data: params
	})
}
//查看文书流版本号
export function versionWriteFlie(params) {
	return axios({
		method: 'get',
		url: Api.writeFlieVersion,
		params: params
	})
}
//新建学校文书流
export function AddWorkFlow(params) {
	return axios({
		method: 'post',
		url: Api.workFlowAdd,
		data: params
	})
}
//设为终稿
export function SetFinalVersion(params) {
	return axios({
		method: 'get',
		url: Api.finalVersionSet,
		params: params
	})
}
//学校文书-更新学校进度
export function updateSchoolProgressRate(params) {
	return axios({
		method: 'get',
		url: Api.schoolProgressRateUpdate,
		params: params
	})
}
//学校文书-更新学校进度
export function updateSchoolWritProgressRate(params) {
	return axios({
		method: 'get',
		url: Api.schoolWritProgressRateUpdate,
		params: params
	})
}
//根据文书id查看文书流
export function querySchoolWriteflow(params) {
	return axios({
		method: 'get',
		url: Api.schoolWriteflowQuery,
		params: params
	})
}
