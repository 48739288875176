<template>
	<div class="chooseTable">
		<div class="chooseTable_tab" v-if="scoreArr.length>0">
			<div class="score-title">标化成绩：</div>
			<div class="chooseTable_tab_item" v-for="(item,index) in scoreArr" :key="index">
				<span class="tab_item_label tab_item_active" >{{item.name}}</span>
				<span class="tab_item_value tab_item_active" >({{item.value}})</span> 
				<span class="tab_item_value tab_item_active" v-if="index !== scoreArr.length-1">、</span>
				<!-- <span :class="{'tab_item_active': currentTab == index}" class="tab_item_label">{{item.label}}</span>
				<span :class="{'tab_item_active': currentTab == index}" class="tab_item_value">({{item.value}})</span> -->
			</div>
		</div>
		<div class="chooseTable_search">
			<div class="handle_form">
				<el-form class="flex_item" ref="searchForm" :model="searchForm" label-width="70px" size="mini">
					<div class="handle_form_row">
						<div class="handle_form_item">
							<el-form-item class="flex_item" label="">
								<input placeholder="输入选择学校" class="handle_form_ipt" v-model="searchForm.name"></input>
							</el-form-item>
						</div>
						<div class="handle_form_item">
							<el-form-item class="flex_item" label="">
								<input placeholder="输入学校批次" class="handle_form_ipt" v-model="searchForm.batch"></input>
							</el-form-item>
						</div>
					</div>
				</el-form>
			</div>
			<div class="handle_search">
				<span @click="handleFn('search')" class="handle_search_confirm">查询</span>
				<span @click="handleFn('reset')" class="handle_search_cancel">重置</span>
				<span @click="handleFn('add')" class="handle_search_confirm">+添加学校</span>
			</div>
		</div>
		<div class="chooseTable_content">
			<div class="chooseTable_list">
				<div class="chooseTable_list_box">
					<el-table class="chooseTable_list_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
						:data="schoolData" :border="false">
						<el-table-column fixed="left" width="250px" prop="name" label="学校名称">
							<template slot-scope="scope">
								<div class="chooseTable_list_name">
									<div class="list_name_inner">
										<div class="ranking">
											<span v-text="getIndex(scope.$index)"></span>
										</div>
										<span class="list_name_chinese">{{scope.row.name}}</span>
										<div class="list_name_english">{{scope.row.enName}}</div>
									</div>
									<div class="list_name_handle">
										<span class="list_name_text" @click="goPath('web',scope.row)">官网</span>
										<span class="list_name_text" @click="goPath('edit',scope.row)">资料</span>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="100px" prop="batch" label="批次/截止">
							<template slot-scope="scope">
								<span>{{scope.row.batch}}</span>
								<span v-if="scope.row.batch&&scope.row.end">/</span>
								<span v-if="scope.row.end">{{scope.row.end}}</span>
							</template>
						</el-table-column>
						<el-table-column width="100px" prop="customEnd" label="自定截止">
						</el-table-column>
						<el-table-column width="100px" prop="schoolLevel" label="学校档次">
						</el-table-column>
						<el-table-column width="250px" prop="profession" label="专业">
						</el-table-column>
						<el-table-column width="250px" prop="college" label="学院">
						</el-table-column>
						<el-table-column width="250px" prop="applyUrl" label="申请系统">
						</el-table-column>
						<el-table-column prop="state" label="选校状态">
							<template slot-scope="scope">
								<span
									:class="{'tag_yellow': scope.row.state == '0', 'tag_green': scope.row.state == '1' }"
									class="chooseTable_list_tag">{{scope.row.state==0?"待确认":"已确认"}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="remark" label="备注">
						</el-table-column>
						<el-table-column prop="createTime" label="添加日期">
							<template slot-scope="scope">
								<div class="chooseTable_list_date">
									<span>{{forMatDate(scope.row.createTime, 1)}}</span>
									<span>{{forMatDate(scope.row.createTime, 2)}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="150px" prop="action" label="操作">
							<template slot-scope="scope">
								<div class="chooseTable_list_action">
									<span @click="handleFn('edit',scope.row)">编辑</span>
									<span @click="handleFn('delete',scope.row)">删除</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
        <div class="knowledgeBase_table_page">
						<el-pagination @current-change="currentChangeS" :current-page.sync="pageIndexS" :page-size="10"
							layout="total, prev, pager, next" :total="totalCountS">
						</el-pagination>
					</div>
			</div>
			<div v-if="historyData.length>0">
				<div class="chooseTable_title">
					<span class="chooseTable_title_left">删除记录</span>
					<span class="chooseTable_title_right">
						<!-- <span class="chooseTable_btn">保存</span> -->
					</span>
				</div>
				<div class="chooseTable_list">
					<div class="chooseTable_list_box">
						<el-table class="chooseTable_list_inner" :header-cell-style="headStyle" :cell-style="rowStyle"
							:data="historyData" :border="false">
							<el-table-column fixed="left" prop="name" label="学校名称">
								<template slot-scope="scope">
									<div class="chooseTable_list_name">
										<div class="list_name_inner">
											<div class="ranking" >
												<span v-text="getIndexTwo(scope.$index)"></span>
											</div>
											<span class="list_name_chinese">{{scope.row.name}}</span>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="batch" label="批次/截止">
								<template slot-scope="scope">
									<span>{{scope.row.batch}}</span>
									<span v-if="scope.row.batch&&scope.row.end">/</span>
									<span v-if="scope.row.end">{{scope.row.end}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="customEnd" label="内部截止">
							</el-table-column>
							<el-table-column prop="schoolLevel" label="学校档次">
							</el-table-column>
							<el-table-column width="250px" prop="profession" label="专业">
							</el-table-column>
							<el-table-column width="250px" prop="college" label="学院">
							</el-table-column>
							<el-table-column width="250px" prop="applyUrl" label="递交申请系统">
							</el-table-column>
							<el-table-column prop="state" label="选校状态">
								<template slot-scope="scope">
									<span
										:class="{'tag_yellow': scope.row.state == '0', 'tag_green': scope.row.state == '1' }"
										class="chooseTable_list_tag">{{scope.row.state == "0"?'待确认':'已确认'}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="remark" label="备注">
							</el-table-column>
							<el-table-column prop="createTime" label="添加日期">
							</el-table-column>
							<el-table-column prop="reason" label="删除原因">
							</el-table-column>
							<el-table-column prop="deleteTime" label="删除时间">
							</el-table-column>
						</el-table>
					</div>
					<div class="knowledgeBase_table_page">
						<el-pagination @current-change="currentChange" :current-page.sync="searchForm.pageNum" :page-size="searchForm.pageSize"
							layout="total, prev, pager, next" :total="totalCount">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		<div class="chooseTable_dialog">
			<!-- 删除选校 -->
			<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" :width="dialogType == 'edit' ? '551px' : '579px'" :title="diaLogTitle" :visible.sync="diaLogVisible">
				<div class="handle_form">
					<el-form v-if="dialogType == 'delete'" ref="diaDetail" :rules="diaRules" :model="diaDetail"
						label-width="90px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" label="">
									<textarea placeholder="输入原因…" v-model="delParams.reason" class="handle_form_textarea"></textarea>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<el-form v-if="dialogType == 'edit'" ref="diaDetail" :rules="diaRules" :model="diaDetail"
						label-width="70px">
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="批次:">
									<input class="handle_form_ipt form_width407" v-model="diaDetail.batch" placeholder="请输入学校批次"/>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="截止日期:">
								<div class="handle_form_date">
									<span class="table_date"><i
									class="form_date_icon el-icon-date"></i>{{diaDetail.end?diaDetail.end: '请选择'}}</span>
									<el-date-picker @change="dateSelect"
									v-model="diaDetail.end" type="date"
									placeholder="选择日期">
									</el-date-picker>
								</div>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="自定截止:">
								<div class="handle_form_date">
									<span class="table_date"><i
									class="form_date_icon el-icon-date"></i>{{diaDetail.customEnd?diaDetail.customEnd: '请选择'}}</span>
									<el-date-picker @change="personSelect"
									v-model="diaDetail.customEnd" type="date"
									placeholder="选择日期">
									</el-date-picker>
								</div>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="学校档次:" >
									<el-select class="handle_form_select form_width407" clearable
										placeholder="请选择" v-model="diaDetail.schoolLevel">
										<el-option v-for="item in mainTeachers1" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="专业:">
									<input class="handle_form_ipt form_width407" v-model="diaDetail.profession" placeholder="请输入专业名称"/>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="学院:">
									<input class="handle_form_ipt form_width407" v-model="diaDetail.college" placeholder="请输入学院名称"/>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="申请系统:">
									<input class="handle_form_ipt form_width407" v-model="diaDetail.applyUrl" placeholder="请输入申请系统网址"/>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="flex_item" label="选校状态:" >
									<el-select class="handle_form_select form_width407" clearable
										placeholder="请选择" v-model="diaDetail.state">
										<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
							</div>
						</div>
						<div class="handle_form_row">
							<div class="handle_form_item">
								<el-form-item class="" label="备注:">
									<textarea placeholder="请输入" v-model="diaDetail.remark" class="handle_form_textarea form_width407"></textarea>
								</el-form-item>
							</div>
						</div>
					</el-form>
					<div class="handle_form_btn">
						<span @click="closeDialog" class="handle_form_cancel">取消</span>
						<span v-preventReClick @click="handleSubmit(dialogType)" class="handle_form_confirm">确定</span>
					</div>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import Bus from "./eventBus.js";
import commonFn from "@/utils/common.js";
import {
  delChooseSchool,
  hisChooseSchoolHis,
  updateChooseSchool,
  detailSchool,
  maxStandard
} from "@/api/applicationProgress";
export default {
  props: {
    studentId: {
      type: String,
      default: function() {
        return "";
      }
    },
    schoolData: {
      type: Array,
      default: function() {
        return [];
      }
    },
    intPageIndexS: {
      type: Number,
      default: function() {
        return 1;
      }
    },
    totalCountS: {
      type: Number,
      default: function() {
        return 0;
      }
    }
  },
  data() {
    return {
      currentTab: 0,
      max: {},
      searchForm: {
        name: "",
        batch: "",
        studentId: "",
        pageNum: 1,
        pageSize: 10
      },
      // schoolData: [],
      historyData: [
        // 	{
        // 	name: "悉尼大学",
        // 	batch: "EA（11.1）",
        // 	abort: "12.1",
        // 	level: "Reach",
        // 	major: "电子商务",
        // 	college: "传媒学院",
        // 	system: "http://usfb.fyw.crs",
        // 	status: "0",
        // 	desc: "无",
        // 	createTime: "2022-5-21",
        // 	deleteTime: "2022-5-21",
        // 	reason: "无"
        // }
      ],
      diaDetail: {},
      diaRules: {},
      diaLogVisible: false,
      diaLogTitle: "",
      dialogType: "",
      delParams: {
        id: "",
        reason: ""
      },
      mainTeachers: [
        {
          value: "0",
          label: "待确认"
        },
        {
          value: "1",
          label: "已确认"
        }
      ],
      mainTeachers1: [
        {
          value: "Reach",
          label: "Reach"
        },
        {
          value: "Super Reach",
          label: "Super Reach"
        },
        {
          value: "Solid",
          label: "Solid"
        },
        {
          value: "Safety",
          label: "Safety"
        }
      ],
      totalCount: 0,
      pageSize: 999,
      currenPage: 1,
      schoolDetailData: {},
      scoreArr: [],
      pageIndexS: undefined,
    };
  },
  created() {
    // console.log(this.studentId)
  },
  watch: {
    studentId: {
      handler(val) {
        // console.log("studentId233", val)
        if (val) {
          this.searchForm.studentId = val;
          this.historyList();
          this.getHigScore();
        }
      },
      immediate: true
    },
    schoolData: {
      handler(val) {
        // console.log(val,'wqeqeqwewqe数据')
      },
      immediate: true
    },
    intPageIndexS: {
      handler(val) {
        this.pageIndexS = val
      },
      immediate: true
    },
  },
  mounted() {
    Bus.$on("schoolWrite", props => {
      this.showDraw = true;
      // console.log("schoolHandle", props)
      this.schoolListAll();
    });
  },
  methods: {
    forMatDate(intDate, type) {
      if (intDate) {
        let newDate = new Date(intDate);
        let year = newDate.getFullYear();
        let month = this.addZero(newDate.getMonth() + 1);
        let day = this.addZero(newDate.getDate());
        let hours = this.addZero(newDate.getHours());
        let minutes = this.addZero(newDate.getMinutes());
        if (type == 1) {
          return year + "-" + month + "-" + day;
        } else {
          return hours + ":" + minutes;
        }
      }
    },
    addZero(num) {
      if (num < 10) return "0" + num;
      return num;
    },
    //获取最高成绩
    getHigScore() {
      maxStandard({ studentAccountId: this.studentId }).then(res => {
        if (res.code == 0) {
          if (res.data) {
            var scoreArrData = res.data;
            let result = Object.entries(scoreArrData).map(item => {
              return {
                name: item[0],
                value: Number(item[1])
              };
            });
            this.scoreArr = result;
            console.log(result, "result");
            // this.max = result.reduce((prev, current) => (prev.value > current.value) ? prev : current)
          }
          // else{
          // 	this.max = {}
          // }
        }
      });
    },
    getIndex($index) {
      return (this.currenPage - 1) * this.pageSize + $index + 1;
    },
    getIndexTwo($index) {
      return (
        (this.searchForm.pageNum - 1) * this.searchForm.pageSize + $index + 1
      );
    },
    //查询学校详情
    getSchoolDetail(id, type) {
      detailSchool({ schoolId: id }).then(res => {
        if (res.code == 0) {
          this.schoolDetailData = res.data;
          switch (type) {
            case "web":
              let newUrl = res.data.officialurl;
              if (
                newUrl.indexOf("http://") != -1 ||
                newUrl.indexOf("https://") != -1
              ) {
                window.open(newUrl);
              } else {
                window.open(`https://${newUrl}`);
              }
              break;
            case "edit":
              Bus.$emit("drawHandle", {
                handleType: type,
                ...res.data
              });
              break;
          }
        } else {
          this.$message({
            message: res.msg,
            type: "warning"
          });
        }
      });
    },
    //点击跳转官网和资料
    goPath(type, item) {
      this.getSchoolDetail(item.schoolId, type);
    },
    headStyle() {
      return "text-align:center;background: #F7F7F7;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #666666;";
    },
    rowStyle() {
      return "text-align:center;font-size: 14px;font-family: Source Han Sans CN;font-weight: 400;color: #333333;";
    },
    changeTab(index) {
      this.currentTab = index;
    },
    closeDialog() {
      this.diaLogVisible = false;
    },
    currentChange(val) {
      this.searchForm.pageNum = val;
      this.historyList();
    },
    currentChangeS(val) {
      // this.intPageIndexS = val
      this.$emit("currentChangeS", val)
    },
    //选择截止日期
    dateSelect(value) {
      this.diaDetail.end = commonFn.timeFormat(value, "yyyy-MM-dd");
    },
    //选择自定截止
    personSelect(value) {
      this.diaDetail.customEnd = commonFn.timeFormat(value, "yyyy-MM-dd");
    },
    // getShoolList(){
    // 	selectSchoolList(this.searchForm).then(res=>{
    // 		if(res.code == 0){
    // 			console.log(res.data,"==>>>>>>>信息")
    // 			this.schoolData = res.data.records
    // 		}
    // 	})
    // },
    //删除记录
    historyList() {
      hisChooseSchoolHis(this.searchForm).then(res => {
        if (res.code == 0) {
          this.historyData = res.data.records;
          this.totalCount = res.data.total * 1;
        }
      });
    },
    //删除学校
    chooseSchoolDel() {
      delChooseSchool(this.delParams).then(res => {
        if (res.code == 0) {
          this.diaLogVisible = false;
          this.$parent.getShoolList(this.searchForm);
          this.historyList();
        }
      });
    },
    //更新学校信息
    chooseSchoolUpdate() {
      let editParams = JSON.parse(JSON.stringify(this.diaDetail));
      updateChooseSchool(editParams).then(res => {
        if (res.code == 0) {
          this.diaLogVisible = false;
          this.searchForm.studentId = this.studentId;
          this.$parent.getShoolList(this.searchForm);
        }
      });
    },
    handleSubmit(type) {
      switch (type) {
        case "edit":
          this.chooseSchoolUpdate();
          break;
        case "delete":
          this.chooseSchoolDel();
          break;
      }
    },
    //获取删除记录

    handleFn(type, item) {
      console.log(item);
      switch (type) {
        case "search":
          this.$parent.getShoolList(this.searchForm);
          break;
        case "reset":
          this.searchForm.name = "";
          this.searchForm.batch = "";
          this.$parent.getShoolList(this.searchForm);
          break;
        case "add":
          Bus.$emit("schoolHandle", {
            type: "addDraw"
          });
          break;
        case "edit":
          this.dialogType = "edit";
          this.diaLogVisible = true;
          this.diaLogTitle = "编辑信息";
          this.diaDetail = JSON.parse(JSON.stringify(item));
          break;
        case "delete":
          this.dialogType = "delete";
          this.diaLogVisible = true;
          this.diaLogTitle = "删除原因（选填）";
          this.delParams.reason = "";
          this.delParams.id = item.id;
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../styles/index.scss";

// 自定义滚动条样式
/deep/.chooseTable_list ::-webkit-scrollbar {
  width: 3px;
  height: 15px;
}

/deep/.chooseTable_list ::-webkit-scrollbar-thumb {
  //滑块部分
  background-color: rgba(91, 168, 151, 1);
}
// 自定义滚动条样式
/deep/.chooseTable_list ::-webkit-scrollbar {
  width: 3px;
  height: 15px;
}

/deep/.chooseTable_list ::-webkit-scrollbar-thumb {
  //滑块部分
  background-color: rgba(91, 168, 151, 1);
}
.score-title {
  color: #5ba897;
  font-size: 14px;
  font-weight: bold;
}
input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 20px;
  color: #999999 !important;
}

.flex_item {
  display: flex;
  align-items: center;
}

.handle_form {
  .handle_form_row {
    display: flex;

    .handle_form_item {
      flex: 1;
      display: flex;
      position: relative;
      margin-right: 20px;

      .handle_form_select {
        width: 320px;
      }

      .handle_form_ipt {
        width: 320px;
        line-height: 36px;
        border: 1px solid #eeeeee;
        outline: none;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        padding: 0 10px;
        box-sizing: border-box;
      }

      .handle_form_textarea {
        width: 483px;
        height: 120px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        padding: 10px 10px;
        outline: none;
        resize: none;
        box-sizing: border-box;
        margin-left: 20px;
      }

      .form_width407 {
        width: 407px;
        margin-left: 0px;
      }
    }
  }

  .handle_form_btn {
    display: flex;
    align-items: center;
    margin: 40px 0 0px 0;

    .handle_form_cancel {
      margin-left: auto;
      width: 76px;
      line-height: 34px;
      background: #ffffff;
      border: 1px solid $theme_color;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: $theme_color;
      text-align: center;
      cursor: pointer;
      margin-right: 16px;
    }

    .handle_form_confirm {
      width: 76px;
      line-height: 34px;
      background: $theme_color;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
}

.chooseTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  .chooseTable_tab {
    width: 100%;
    display: flex;
    align-items: center;

    .chooseTable_tab_item {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 20px;
      // margin-right: 40px;
      cursor: pointer;

      .tab_item_label {
        color: #333333;
      }

      .tab_item_value {
        color: $theme_color;
      }

      .tab_item_active {
        font-weight: bold;
        color: $theme_color;
      }
    }
  }

  .chooseTable_search {
    display: flex;
    margin: 20px 0 10px 0;

    .handle_search {
      display: flex;
      align-items: center;

      .handle_search_confirm {
        width: 92px;
        line-height: 36px;
        background: $theme_color;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        margin-left: 16px;
      }

      .handle_search_cancel {
        width: 92px;
        line-height: 36px;
        border: 1px solid $theme_color;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: $theme_color;
        cursor: pointer;
        margin-left: 16px;
      }
    }
  }

  .chooseTable_content {
    width: 100%;
    display: flex;
    flex-direction: column;

    .chooseTable_list {
      .chooseTable_list_box {
        .chooseTable_list_inner {
          border-left: 1px solid #eeeeee;
          border-right: 1px solid #eeeeee;

          .chooseTable_list_name {
            height: 70px;
            position: relative;

            .list_name_inner {
              height: 70px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              cursor: pointer;
              position: relative;
              .ranking {
                width: 28px;
                height: 18px;
                line-height: 18px;
                text-align: center;
                background: #e6e6e6;
                border-radius: 0px 14px 14px 0;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
              .list_name_chinese {
                height: 35px;
                line-height: 35px;
              }

              .list_name_english {
                height: 35px;
                line-height: 35px;
              }
            }

            .list_name_handle {
              width: 100%;
              height: 0px;
              display: flex;
              align-items: center;
              justify-content: space-around;
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.5);

              .list_name_text {
                font-size: 14px;
                color: #ffffff;
                cursor: pointer;
              }
            }
          }

          .chooseTable_list_name:hover .list_name_handle {
            height: 70px;
          }

          .chooseTable_list_tag {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 20px;

            &.tag_green {
              color: $theme_color;
            }

            &.tag_yellow {
              color: #d0743b;
            }
          }

          .chooseTable_list_date {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 20px;
            }
          }

          .chooseTable_list_action {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 20px;
              color: $theme_color;
              margin: 0 12px;
              cursor: pointer;
            }
          }
        }
      }

      .chooseTable_list_page {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .chooseTable_title {
      display: flex;
      line-height: 24px;
      margin: 20px 0 10px 0;

      .chooseTable_title_left {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        box-sizing: border-box;
        border-bottom: 2px solid $theme_color;
      }

      .chooseTable_title_right {
        flex: 1;
        border-bottom: 1px solid #eeeeee;
        display: flex;

        .chooseTable_btn {
          width: 80px;
          background: $theme_color;
          border-radius: 4px;
          margin-left: auto;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 34px;
          color: #ffffff;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  .chooseTable_dialog {
    .handle_form_row {
      margin-top: 25px;
    }
  }
}
.knowledgeBase_table_page {
  text-align: center;
  margin-top: 20px;
}
.handle_form_date {
  width: 423px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  position: relative;
  box-sizing: border-box;
  .form_date_icon {
    margin-right: 10px;
  }
}
.handle_form_date .el-date-editor {
  width: 100%;
  position: absolute; //绝对定位
  top: 0;
  left: 10px;
  opacity: 0; //设置完全透明
}
</style>
