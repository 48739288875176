import { render, staticRenderFns } from "./mailForm.vue?vue&type=template&id=7c477aee&scoped=true&"
import script from "./mailForm.vue?vue&type=script&lang=js&"
export * from "./mailForm.vue?vue&type=script&lang=js&"
import style0 from "./mailForm.vue?vue&type=style&index=0&id=7c477aee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c477aee",
  null
  
)

export default component.exports