<template>
<div class="schoolTable_dialog">
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" :show-close="false" width="934px" :visible.sync="diaLogVisible">
		
		<div class="writFlowDialog_inner">
			<div class="writFlowDialog_inner_title">
				<span>{{studentName}}({{diaLogTitle}})</span>
				<img src="@/assets/images/common/close_light.png" @click="diaLogVisible=false">
			</div>
			<div class="writFlowDialog_inner_content">
				<!-- <div class="write-cover"></div> -->
				<div class="inner_content_left">
					<div class="content_left_list">
						<img @click="handleFn('openTip')" src="@/assets/images/applicationProgress/circle_add.png"
							class="content_left_add">
						<div v-if="showTip" class="content_left_tip">
							<div @click="handleFn('checkTip')" 
								class="left_tip_item">
								<span>学生计划完成</span>
								<img v-if="isChecked" src="@/assets/images/dashboard/radio.png">
								<img v-else src="@/assets/images/dashboard/radioSelected.png">
							</div>
							<div @click="handleFn('checkTip')" 
								class="left_tip_item">
								<span>老师计划完成</span>
								<img v-if="!isChecked" src="@/assets/images/dashboard/radio.png">
								<img v-else src="@/assets/images/dashboard/radioSelected.png">
							</div>
							<span class="left_tip_btn" @click="handleSure">确定</span>
						</div>
						<div class="left_list_inner" v-if="writFlowData.length > 0">
							<div v-for="(item,index) in writFlowData" :key="index" class="left_list_item">
								<div class="list_item_inner">
									<div class="list_item_step">
										<span :class="{'step_line_show': index != 0}" class="item_step_line"></span>
										<span
											:class="{'step_point_yellow': writFlowData.length > 0 && item.sendType == '2', 'step_point_green': writFlowData.length > 0&&item.sendType == '1',}"
											class="item_step_point"></span>
										<span
											:class="{'step_line_show':  writFlowData.length > 0&&index != (writFlowData.length - 1)}"
											class="item_step_line"></span>
									</div>
									<div class="list_item_content">
										<span class="item_content_time">{{item.createTime}}</span>
										<!-- 学生 -->
										<div v-if="item.sendType == '2'" class="item_content_student">
											<div class="content_student_top">
												<span class="student_top_title">{{item.paperworkFlowTitle}}</span>
												<el-popconfirm  title="确定要讲该文书设为终稿文书吗？" @confirm="handleFinalVersion(item)" v-if="item.finalVersion==0&&item.finalVersion!==-1">
													<span slot="reference"  class="student_top_btn">设为终稿</span>
												</el-popconfirm>
												<span class="student_top_btn" v-if="item.finalVersion==1">终稿</span>
											</div>
											<div class="content_student_bottom">
												<div class="student_bottom_left">
													<div class="bottom_left_mark">
														<span class="student_bottom_label">备注:</span>
														<span
															class="student_bottom_value">{{item.paperworkFlowRemark}}</span>
													</div>
													<span class="bottom_left_name">
														学生{{studentName}}
													</span>
												</div>
												<uploadText :fileNum="index" :fileType="item.type" :sendType="item.sendType" @successUpload="successUpload" :fileSrc="item.fileUrl"/>
											</div>
										</div>
										<!-- 老师 -->
										<div v-if="item.sendType == '1'" class="item_content_teacher">
											<div class="content_teacher_left">
												<div class="teacher_left_top">
													<span class="left_top_title">{{item.paperworkFlowTitle}}</span>
												</div>
												<div class="teacher_left_middle">
													<span class="left_middle_tag middle_tag_yellow" v-if="item.type==2">学生</span>
													<span class="left_middle_tag middle_tag_green" v-else>老师</span>
													<div class="left_middle_date">
														<span class="middle_date_label date_label_yellow" v-if="item.type==2">计划完成:</span>
														<span class="middle_date_label date_label_green" v-else>计划完成:</span>
														<img class="middle_date_icon"
															src="@/assets/images/applicationProgress/time_yellow.png" v-if="item.type==2">
														<img class="middle_date_icon"
																src="@/assets/images/applicationProgress/time_green.png" v-else>
														<div class="middle_date_picker" :class="{'date_picker_yellow':item.type==2,'date_picker_green':item.type==1}">
															<span>{{item.planTime?item.planTime:'暂未选择'}}</span>
															<div v-if="item.showType">
																<el-date-picker type="date" @change="finishSelect($event,'planTime',item)" v-model="item.planTime" placeholder="选择日期">
																</el-date-picker>
															</div>
														</div>
													</div>
												</div>
												<div class="teacher_left_bottom">
													<span>{{item.createUser}}老师</span>
													<img @click="handleRemark(item)" v-if="item.id&&item.sendType==1&&item.paperworkFlowRemark" class="remark-icon"
														src="@/assets/images/applicationProgress/remark-icon.png">
												</div>
											</div>
											<uploadText ref="uploadWrite" :reUpload="item.reUpload" :showType="item.showType" :fileNum="index"  :fileType="item.type" :sendType="item.sendType" @successUpload="successUpload" :fileSrc="item.fileUrl"/>
											
										</div>
										<div style="position:relative" v-if="item.showRemark">
											<div class="write-remark">{{item.paperworkFlowRemark}}</div>
										</div>
										<div v-if="item.showType=='addStudent'" class="item_content_handle">
											<span class="content_handle_btn" @click="handleForward(item)" v-preventReClick>发送给学生</span>
											<div class="content_handle_icon">
												<img @click.stop="handleFn('openMark', item)" class="handle_icon_edit"
													src="@/assets/images/applicationProgress/edit.png">
												<img v-if="isShowTeacherIcon==true" @click.stop="handleFn('openTeacher', item)" class="handle_icon_user"
													src="@/assets/images/applicationProgress/user.png">
											</div>
											<!-- 选择老师 -->
											<div v-if="item.showTeacher" class="content_handle_tip">
												<div class="radio-box">
												<div @click="handleFn('checkTeacher', ite)"
													v-for="(ite,index) in teacherList" :key="index"
													class="handle_tip_item">
													<span>{{ite.realname}}</span>
													<img v-if="!ite.checked" src="@/assets/images/dashboard/radio.png">
													<img v-else src="@/assets/images/dashboard/radioSelected.png">
												</div>
												<div class="btn-box">
													<span class="handle_tip_btn" @click="submitTeacher(item)">确定</span>
												</div>
												</div>
												
											</div>
											<!-- 添加备注 -->
											<div v-if="item.showMark" class="content_handle_mark">
												<div class="handle_mark_inner">
													<textarea placeholder="添加备注"
														class="handle_mark_textarea" v-model="item.paperworkFlowRemark"></textarea>
													<span class="handle_mark_btn" @click="item.showMark=false">确定</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else class="content_left_empty">
							<img src="@/assets/images/common/empty1.png">
						</div>
					</div>
				</div>
				<div class="inner_content_right">
					<div class="content_right_top">
						<span class="right_top_title">{{writeForm.paperworkTitle}}</span>
						<div class="right_top_edit title-box">
							<div>文书题目</div>
							<div class="common-btn" v-if="title&&writeForm.title!==title" @click="handleFn('caseSubmit')">保存</div>
						</div>
						<div class="content_right_bottom">
							<textarea :disabled="propData.deleteUser" placeholder="输入文书标题" class="content_right_textarea" v-model="title"></textarea>
						</div>
					</div>
					<div class="right_top_edit title-box">
							<div>备注内容</div>
							<div class="common-btn" v-if="remark&&writeForm.remark!==remark" @click="handleFn('remarkSubmit')">保存</div>
						</div>
					<div class="content_right_bottom">
						<textarea :disabled="propData.deleteUser" placeholder="添加备注" class="content_right_textarea" v-model="remark"></textarea>
					</div>
				</div>
			</div>
		</div>
	</el-dialog>
	</div>
</template>

<script>
	import Bus from "./eventBus.js";
	import commonFn from "@/utils/common.js";
	import storage from 'store';
	import {
		querySchoolWriteflow,
		AddSchoolWrit,
		writFlowSchoolTitle,
		versionWriteFlie,
		AddWorkFlow,
		addSchoolWritRemark,
		SetFinalVersion,
		teacherQuery
	} from "@/api/applicationProgress"
	import {
		listTeacher,
		personalViewStudents
	} from "@/api/common";
	import uploadText from "@/components/uploadFile/text.vue"
	export default {
		components:{
			uploadText
		},
		props:{
			studentName: {
				type: String,
				default: function() {
					return ""
				}
			},
			studentId: {
				type: String,
				default: function() {
					return ""
				}
			}
		},
		data() {
			return {
				diaLogVisible: false,
				diaLogTitle: "",
				writFlowData: [
					// {
					// 	type: "STUDENT"
					// },
					// {
					// 	type: "TEACHER"
					// }
				],
				showTip: false,
				leftTipList: [{
						label: "学生计划完成",
						value: "S",
						checked: false
					},
					{
						label: "老师计划完成",
						value: "T",
						checked: false
					},
				],
				teacherName:storage.get("ACCESS-NAME"),
				showTeacher: false,
				teacherList: [],
				showMark: false,
				titleEdit:false,
				propData:{},
				writeForm:{
					studentId: "",
					schoolId: "",
					paperworkTitle:"",
					remark: "",
					type: 1,
					title: ""
				},
				remark:"",
				isChecked:false,
				versionTitle:"",
				checkArr:[],
				showType:"",//是否显示发送学生按钮
				fileType:"student",
				teacherFile:"teacher",
				title:"",
				reUpload:false,
				isShowTeacherIcon:true
			}
		},
		mounted() {
			Bus.$on("handleWritFlowForm", props => {
				this.diaLogVisible = true
				this.showMark = false
				this.titleEdit = false
				this.isChecked = false
				this.getWriteFlowDetail(props.id||props.paperworkId)
				this.diaLogTitle = props.handleType
				this.propData = props
				this.getWriteTeacher()
				this.getlistSchoolWrite()
				// this.texcherList()
				// this.studentPersonalView()
				this.getVersion()
			})
		},
		methods: {
			//获取版本号
			getVersion(){
				var params = {
					schoolPaperworkId:this.propData.id||this.propData.paperworkId,
					sendType:1
				}
				versionWriteFlie(params).then(res=>{
					if(res.code == 0){
						console.log(res)
						this.versionTitle = res.data
					}
				})
			},
			handleRemark(item){
				item.showRemark = !item.showRemark
			},
			//回显标题和备注
			getlistSchoolWrite(){
				var params = {
					id:this.propData.id||this.propData.paperworkId
				}
				writFlowSchoolTitle(params).then((res) => {
					if (res.code == 0) {
						this.writeForm = res.data
						this.title = res.data.title
						this.remark = res.data.remark
					}
				})
			},
			//获取学生得关联老师
			 studentPersonalView() {
				if(!this.studentId) return
				let params = {
					studentInfoId: this.studentId
				}
				personalViewStudents(params).then((res) => {
					if (res.code == 0) {
						var teacherArr = ((res.data.otherTeacherId).split(',')).concat((res.data.principalTeacherId).split(','))
						var newTeacherArr = [...new Set(teacherArr)]
						if (storage.get('ACCESS-TEACHERID')) {
							var Index = newTeacherArr.indexOf(storage.get('ACCESS-TEACHERID'))
							if(Index==-1){
								this.isShowTeacherIcon = false
							}else{
								this.isShowTeacherIcon = true
							}
						}
					}
				})
			},
			//获取学生的文书关联老师
			getWriteTeacher(){
				teacherQuery({studentId:this.studentId}).then(res=>{
					if(res.code == 0){
						this.texcherList(res.data)
					}
				})
			},
			// 获取老师列表
			texcherList(arr) {
				let params = {
					pageIndex: 1,
					pageSize: 99999
				}
				listTeacher(params).then((res) => {
					res.data.forEach(el=>{
						el.checked = false
					})
					let arrTeacher = res.data
					if(arr){
						let arr1 = arr.otherTeacherId.concat(arr.principalTeacherId)
						if (storage.get('ACCESS-TEACHERID')) {
							var Index = arr1.indexOf(storage.get('ACCESS-TEACHERID'))
							if(Index==-1){
								this.isShowTeacherIcon = false
							}else{
								this.isShowTeacherIcon = true
							}
						}
						this.teacherList=arrTeacher.filter(item=>!arr1.some(ele=>ele===item.id))
						this.checkArr = arr.docTeacherId
						this.checkArr.forEach(el=>{
							this.teacherList.forEach(ite=>{
								if(el==ite.id){
									ite.checked=true
								}
							})
						})
					}

				})
			},
			//完成日期选择
			finishSelect(value, key, item) {
				console.log("dateSelect", value, item)
				item[key] = commonFn.timeFormat(value, "yyyy-MM-dd")
				this.$forceUpdate()
			},
			//根据文书id查看文书流
			getWriteFlowDetail(writeId){
				querySchoolWriteflow({paperworkId:writeId}).then(res=>{
					if(res.code == 0){
						res.data.forEach(el=>{
							el.showRemark = false
						})
						this.writFlowData = res.data
						this.$forceUpdate()
					}
				})
			},
			//设置终稿
			handleFinalVersion(item){
				SetFinalVersion({id:item.id}).then(res=>{
					if(res.code == 0){
						this.getWriteFlowDetail(item.id)
						this.diaLogVisible = false
					}
				})
			},
			//修改文书标题和备注
			editTitle(type){
				let editParams = JSON.parse(JSON.stringify(this.writeForm))
				if(type=='remarkSubmit'){
					editParams.remark = this.remark
					addSchoolWritRemark(editParams).then(res=>{
						if(res.code == 0){
							this.$message({
								message: '保存成功',
								type: 'success'
							});
							this.getlistSchoolWrite()
						}
					})
				}else if(type=='caseSubmit'){
					editParams.title = this.title
					AddSchoolWrit(editParams).then(res=>{
						if(res.code == 0){
							this.$message({
								message: '保存成功',
								type: 'success'
							});
							this.getlistSchoolWrite()
						}
				})
				}
			},
			//点击选择老师学生计划完成
			handleSure(){
				this.getVersion()
				if (this.isChecked == false) {
					this.writFlowData.unshift({reUpload:false,showType:"addStudent",createUser:this.teacherName,sendType:1,type:2,showTeacher:false,showMark:false,paperworkFlowTitle:this.versionTitle})
					// 学生
				} else {
					this.teacherFile=""
					this.writFlowData.unshift({createUser:this.teacherName,showType:"addStudent",sendType:1,type:1,showTeacher:false,showMark:false,paperworkFlowTitle:this.versionTitle})
					//老师
				}
				this.showTip = false
			},
			//文件上传成功
			successUpload(data){
				this.writFlowData[data.number].fileUrl = data.fileData
				this.writFlowData[data.number].reUpload = true
				// this.index = data.number
				// this.reUpload = true
			},
			closeDialog() {
				this.diaLogVisible = false
			},
			//选择多个老师
			checkOne(item){
				item.checked=!item.checked;
				if(this.checkArr.indexOf(item.id)===-1){
					this.checkArr.push(item.id);
				}else{
					this.checkArr.splice(this.checkArr.indexOf(item.id),1)
				}
			},
			//选择多个老师点击确定
			submitTeacher(item){
				item.showTeacher=false
				item.receiveUser = this.checkArr.join(",")
			},
			//点击转发给学生
			handleForward(paramsForm){
				console.log(paramsForm)
				let that = this
				if(!paramsForm.planTime){
					this.$message({
						message: '请选择计划完成时间',
						type: 'warning'
					});
					return
				}
				if(!paramsForm.receiveUser){
					paramsForm.receiveUser = this.checkArr.join(",")
				}
				paramsForm.studentId = this.propData.studentId
				paramsForm.schoolId = this.propData.schoolId
				paramsForm.paperworkId = this.propData.id||this.propData.paperworkId
				let editParams = JSON.parse(JSON.stringify(paramsForm))
				delete editParams.showType
				// console.log(editParams,"editparams")
				// return
				AddWorkFlow(editParams).then(res=>{
					if(res.code == 0){
						this.diaLogVisible = false
						this.$message({
							message: '发送成功',
							type: 'success'
						});
						this.$nextTick(()=>{
							Bus.$emit("handleWritFlowSchool")
						})
					}
				})
			},
			handleFn(type, item) {
				if(this.propData.deleteUser){
					return
				}
				switch (type) {
					case "checkTip":
						this.isChecked = !this.isChecked
						// this.checkRadio()
						break;
					case "openTip":
						this.showTip = !this.showTip
						break;
					case "openTeacher":
						item.showTeacher = !item.showTeacher
						item.showMark = false
						break;
					case "checkTeacher":
						this.checkOne(item)
						break
					case "openMark":
						item.showMark = !item.showMark
						item.showTeacher = false
						break;
					case "caseCancle":
						this.title = ''
						this.titleEdit = !this.titleEdit
						break;
					case "caseSubmit":
						// console.log(this.title.length)
						// if(this.title.length>5000){
						// 	this.$message({
						// 		message: '字数超出限制',
						// 		type: 'warning'
						// 	});
						// 	return
						// }
						this.editTitle(type)
						break
					case "titleEdit":
						this.title = this.writeForm.title
						this.titleEdit = true
						break;
					case "remarkSubmit":
						// console.log(this.remark.length)
						// if(this.remark.length>5000){
						// 	this.$message({
						// 		message: '字数超出限制',
						// 		type: 'warning'
						// 	});
						// 	return
						// }
						this.editTitle(type)
						break;
				}
			}
		}
	}
</script>

<style lang="scss" scoped="">
	@import "../../../styles/index.scss";
	// .write-cover{
	// 	position:absolute;
	// 	background:rgba(255,255,255,0);
	// 	left:0;
	// 	bottom:0;
	// 	z-index:9999;
	// 	width:100%;
	// 	height:90%;
	// }
	/deep/.el-dialog {
		padding: 0;
	}
	/deep/.schoolTable_dialog .el-dialog__body {
		max-height: 600px;
		overflow: hidden;
		overflow-y: auto;
	}
	.writFlowDialog_inner {
		display: flex;
		flex-direction: column;
		max-height:600px;
		overflow:auto;
		.writFlowDialog_inner_title {
			line-height: 56px;
			background: $theme_color;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0px 32px;
			box-sizing: border-box;
			border-radius: 10px 10px 0px 0px;

			span {
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: bold;
				color: #FFFFFF;
			}

			img {
				width: 20px;
				height: 20px;
				cursor: pointer;
			}
		}

		.writFlowDialog_inner_content {
			flex: 1;
			box-sizing: border-box;
			padding: 16px 32px;
			display: flex;
			min-height: 438px;

			.inner_content_left {
				width: 450px;
				margin-right: 20px;
				padding: 0 16px 0 0;
				max-height:600px;
				overflow: hidden;
				overflow-y: auto;
				.content_left_list {
					height: 100%;
					border-right: 1px solid #EEEEEE;
					position: relative;

					.content_left_add {
						width: 26px;
						height: 26px;
						position: absolute;
						right: -13px;
						top: 120px;
						cursor: pointer;
					}

					.content_left_tip {
						width: 141px;
						height: 117px;
						background-image: url(../../../assets/images/common/tip.png);
						background-repeat: no-repeat;
						background-size: cover;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						position: absolute;
						right: -22px;
						top: 140px;

						.left_tip_item {
							display: flex;
							align-items: center;
							margin-bottom: 9px;
							cursor: pointer;

							span {
								font-size: 12px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								line-height: 17px;
								color: #333333;
							}

							img {
								width: 14px;
								height: 14px;
								margin-left: 15px;
							}
						}

						.left_tip_btn {
							width: 76px;
							line-height: 26px;
							background: $theme_color;
							border-radius: 4px;
							text-align: center;
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							color: #FFFFFF;
							cursor: pointer;
						}
					}

					.content_left_tip :first-child.left_tip_item {
						margin-top: 8px;
					}

					.left_list_inner {
						height: 100%;
						display: flex;
						flex-direction: column;
						margin-right: 24px;

						.left_list_item {
							width: 100%;

							.list_item_inner {
								display: flex;
								height: 100%;

								.list_item_step {
									width: 26px;
									height: 100%;
									display: flex;
									flex-direction: column;
									align-items: center;
									justify-content: center;
									margin-right: 20px;

									.item_step_line {
										flex: 1;
										width: 1px;

										&.step_line_show {
											background-color: #EEEEEE;
										}
									}

									.item_step_point {
										width: 26px;
										height: 26px;
										border-radius: 50%;

										&.step_point_green {
											background: $theme_color;
										}

										&.step_point_yellow {
											background: #B5B181;
										}
									}
								}

								.list_item_content {
									flex: 1;
									display: flex;
									flex-direction: column;
									padding-bottom: 16px;
									box-sizing: border-box;

									.item_content_time {
										font-size: 14px;
										font-family: Source Han Sans CN;
										font-weight: 400;
										line-height: 20px;
										color: #CCCCCC;
										text-align: center;
										margin-bottom: 8px;
									}

									.item_content_student {
										border-radius: 4px;
										background: #FFFFFF;
										padding: 12px 12px;
										box-sizing: border-box;
										display: flex;
										flex-direction: column;
										border: 1px solid #B5B181;

										.content_student_top {
											display: flex;
											align-items: center;

											.student_top_title {
												font-size: 14px;
												font-family: Source Han Sans CN;
												font-weight: 500;
												line-height: 20px;
												color: #B5B181;
												margin-right: 12px;
											}

											.student_top_btn {
												line-height: 20px;
												background: #B5B181;
												border-radius: 10px;
												padding: 0 20px;
												font-size: 12px;
												font-family: Source Han Sans CN;
												font-weight: 400;
												color: #FFFFFF;
												cursor: pointer;
												user-select: none;
											}
										}

										.content_student_bottom {
											display: flex;

											.student_bottom_left {
												flex: 1;
												display: flex;
												flex-direction: column;

												.bottom_left_mark {
													display: flex;
													align-items: flex-start;
													margin: 8px 0;

													.student_bottom_label {
														font-size: 14px;
														font-family: Source Han Sans CN;
														font-weight: 400;
														line-height: 20px;
														color: #B5B181;
														margin-right: 4px;
													}

													.student_bottom_value {
														flex: 1;
														font-size: 14px;
														font-family: Source Han Sans CN;
														font-weight: 400;
														line-height: 20px;
														color: #C4C19A;
														display: -webkit-box;
														-webkit-box-orient: vertical;
														-webkit-line-clamp: 2;
														overflow: hidden;
													}
												}

												.bottom_left_name {
													font-size: 14px;
													font-family: Source Han Sans CN;
													font-weight: 400;
													line-height: 20px;
													color: #B5B181;
												}
											}

											.student_bottom_right {
												margin-left: 28px;
												display: flex;
												flex-direction: column;
												justify-content: center;
												align-items: center;
												cursor: pointer;

												img {
													width: 48px;
													height: 48px;
													margin-bottom: 8px;
												}

												span {
													font-size: 14px;
													font-family: Source Han Sans CN;
													font-weight: 400;
													line-height: 20px;
													color: #B5B181;
												}
											}
										}
									}
									.write-remark{
										position:absolute;
										top:0;
										left:0;
										z-index:999;
										background:rgba(0,0,0, 0.6);
										border-radius: 4px;
										width:351px;
										padding:8px;
										color:#ffffff;
										border: 1px solid #DDDDDD;
									}
									.item_content_teacher {
										// position:relative;
										border-radius: 4px;
										background: #FFFFFF;
										padding: 12px 12px;
										box-sizing: border-box;
										display: flex;
										border: 1px solid $theme_color;

										.content_teacher_left {
											flex: 1;
											display: flex;
											flex-direction: column;

											.teacher_left_top {
												display: flex;
												align-items: center;

												.left_top_title {
													font-size: 14px;
													font-family: Source Han Sans CN;
													font-weight: 500;
													line-height: 20px;
													color: $theme_color;
													margin-right: 12px;
												}

												.left_top_btn {
													line-height: 20px;
													background: $theme_color;
													border-radius: 10px;
													padding: 0 20px;
													font-size: 12px;
													font-family: Source Han Sans CN;
													font-weight: 400;
													color: #FFFFFF;
													cursor: pointer;
													user-select: none;
												}
											}

											.teacher_left_middle {
												display: flex;
												align-items: center;
												margin: 8px 0;

												.left_middle_tag {
													padding: 0 4px;
													line-height: 20px;
													border-radius: 2px;
													font-size: 12px;
													font-family: Source Han Sans CN;
													font-weight: 400;
													color: #FFFFFF;
													margin-right: 4px;

													&.middle_tag_green {
														background: $theme_color;
													}

													&.middle_tag_yellow {
														background: #B5B181;
													}
												}

												.left_middle_date {
													flex: 1;
													display: flex;
													align-items: center;

													.middle_date_label {
														font-size: 14px;
														font-family: Source Han Sans CN;
														font-weight: 400;
														line-height: 20px;

														&.date_label_yellow {
															color: #B4B186;
														}

														&.date_label_green {
															color: $theme_color;
														}
													}

													.middle_date_icon {
														width: 14px;
														height: 14px;
														margin: 0 6px 0 10px;
													}

													.middle_date_picker {
														min-width: 88px;
														height: 20px;
														line-height: 20px;
														border-radius: 10px;
														text-align: center;
														font-size: 12px;
														font-family: Source Han Sans CN;
														font-weight: 400;
														cursor: pointer;
														user-select: none;
														position: relative;

														&.date_picker_green {
															background: #EEF6F4;
															border: 1px solid $theme_color;
															color: $theme_color;
														}

														&.date_picker_yellow {
															background: #F6F5EF;
															border: 1px solid #B4B186;
															color: #B4B186;
														}
													}

													.middle_date_picker .el-date-editor {
														width: 88px;
														position: absolute; //绝对定位
														top: 0;
														left: 10px;
														opacity: 0; //设置完全透明
														cursor: pointer;
													}
												}
											}

											.teacher_left_bottom {
												display: flex;
												align-items: center;
												justify-content: flex-start;
												font-size: 14px;
												font-family: Source Han Sans CN;
												font-weight: 400;
												line-height: 20px;
												color: $theme_color;
												.remark-icon{
													width:12px;
													height:14px;
													margin-left:6px;
													cursor: pointer;
												}
											}
										}

										.content_teacher_right {
											margin-left: 28px;
											display: flex;
											flex-direction: column;
											justify-content: center;
											align-items: center;
											cursor: pointer;

											img {
												width: 48px;
												height: 48px;
												margin-bottom: 8px;
											}

											span {
												font-size: 14px;
												font-family: Source Han Sans CN;
												font-weight: 400;
												line-height: 20px;
												color: #B5B181;
											}
										}
									}

									.item_content_handle {
										display: flex;
										align-items: center;
										justify-content: space-between;
										margin-top: 8px;
										position: relative;

										.content_handle_btn {
											font-size: 14px;
											font-family: Source Han Sans CN;
											font-weight: 400;
											line-height: 26px;
											color: #FFFFFF;
											padding: 0 20px;
											background-color: $theme_color;
											border-radius: 4px;
											cursor: pointer;
										}

										.content_handle_icon {
											display: flex;
											align-items: center;

											.handle_icon_edit {
												width: 22px;
												height: 22px;
												margin-right: 15px;
												cursor: pointer;
											}

											.handle_icon_user {
												width: 22px;
												height: 22px;
												cursor: pointer;
											}
										}

										.content_handle_tip {
											width: 200px;
											height: 216px;
											background-image: url(../../../assets/images/common/tip.png);
											background-repeat: no-repeat;
											background-size: 200px 216px;
											display: flex;
											flex-direction: column;
											align-items: center;
											justify-content: center;
											position: absolute;
											right: -18px;
											top: 22px;
											box-sizing: border-box;
											padding-top:20px;
											z-index: 999 !important;
											.radio-box{
												width: 160px;
												height:150px;
												overflow:auto;
												position: absolute;
												// padding-top:20px;
											}
											.handle_tip_item {
												padding:0 8px;
												display: flex;
												align-items: center;
												justify-content: space-between;
												margin-bottom: 9px;
												cursor: pointer;

												span {
													font-size: 12px;
													font-family: Source Han Sans CN;
													font-weight: 400;
													line-height: 17px;
													color: #333333;
												}

												img {
													width: 14px;
													height: 14px;
													margin-left: 15px;
												}
											}
											.btn-box{
												width:100%;
												text-align: center;
												margin-top:14px;
												margin-bottom:8px;
											}
											.handle_tip_btn {
												display: inline-block;
												width: 76px;
												line-height: 26px;
												background: $theme_color;
												border-radius: 4px;
												text-align: center;
												font-size: 14px;
												font-family: Source Han Sans CN;
												font-weight: 400;
												color: #FFFFFF;
												cursor: pointer;
											}
										}

										.content_handle_tip :first-child.left_tip_item {
											margin-top: 8px;
										}

										.content_handle_mark {
											width: 316px;
											height: 143px;
											background-image: url(../../../assets/images/common/tip_big.png);
											background-repeat: no-repeat;
											background-size: 316px 143px;
											display: flex;
											align-items: center;
											justify-content: center;
											position: absolute;
											right: 26px;
											top: 22px;
											z-index:9999;
											.handle_mark_inner {
												width: 316px;
												height: 143px;
												display: flex;
												align-items: center;
												justify-content: center;
												position: relative;

												.handle_mark_textarea {
													width: 296px;
													height: 119px;
													border: 0;
													resize: none;
													outline: none;
													box-sizing: border-box;
													padding: 10px 10px;
												}

												.handle_mark_btn {
													width: 76px;
													line-height: 34px;
													background: $theme_color;
													border-radius: 4px;
													font-size: 14px;
													font-family: Source Han Sans CN;
													font-weight: 400;
													text-align: center;
													color: #FFFFFF;
													position: absolute;
													bottom: 15px;
													right: 15px;
													cursor: pointer;
													user-select: none;
												}
											}
										}
									}
								}
							}
						}
					}

					.content_left_empty {
						width: 450px;
						height: 438px;
						display: flex;
						align-items: center;
						justify-content: center;
						img {
							width: 201px;
							height: 168px;
						}
					}
				}
			}

			.inner_content_right {
				flex: 1;
				display: flex;
				flex-direction: column;
				position: relative;

				.content_right_top {
					display: flex;
					flex-direction: column;

					.right_top_title {
						font-size: 20px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 30px;
						color: #333333;
						
					}
				}
				.title-box{
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				.common-btn{
					width: 80px;
					line-height: 36px;
					background: #5BA897;
					border-radius: 4px;
					text-align: center;
					font-size:14px;
					color:#ffffff;
					cursor: pointer;
				}
				.right_top_edit {
						display: flex;
						align-items: center;
						margin-top: 20px;
						height:36px;
						span {
							font-size: 14px;
							font-family: Source Han Sans CN;
							font-weight: 400;
							line-height: 20px;
							color: #666666;
							margin-right: 10px;
						}

						img {
							width: 18px;
							height: 18px;
							cursor: pointer;
						}
					}
				.content_right_bottom {
					width: 100%;
					height: 170px;
					background: #FFFFFF;
					border: 1px solid #EEEEEE;
					border-radius: 2px;
					margin-top:12px;
					// position: absolute;
					// bottom: 0;
					// right: 0;
					.content_right_textarea {
						width: 100%;
						height: 160px;
						resize: none;
						outline: none;
						border: 0;
						padding: 12px 12px;
						box-sizing: border-box;
					}

					.content_right_btn {
						width: 76px;
						line-height: 34px;
						background: $theme_color;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #FFFFFF;
						text-align: center;
						display: inline-block;
						position: absolute;
						right: 20px;
						bottom: 20px;
						cursor: pointer;
					}
				}
			}
		}
	}
	.submit_btn {
		display: flex;
		margin-top: 20px;
		.handle_form_ipt {
			line-height: 36px;
			border: 1px solid #EEEEEE;
			outline: none;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #000000;
			padding: 0 10px;
			box-sizing: border-box;
		}
		.submit_btn_cancle {
			width: 80px;
			line-height: 34px;
			border: 1px solid $theme_color;
			border-radius: 4px;
			text-align: center;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: $theme_color;
			margin-left: auto;
			cursor: pointer;
			margin-right: 8px;
		}

		.submit_btn_confirm {
			width: 80px;
			line-height: 34px;
			background: $theme_color;
			border-radius: 4px;
			text-align: center;
			font-size: 14px;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			cursor: pointer;
		}
	}
</style>
