<template>
	<el-dialog :modal="false" class="dialog_bg" :append-to-body="false" :modal-append-to-body="false" @close="closeDialog" width="981px" :title="diaLogTitle" :visible.sync="diaLogVisible">
		<div class="handle_form">
			<el-form ref="commonDetail" :rules="commonRules" :model="commonDetail" label-width="90px">
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="提交状态:">
							<el-select v-model="commonDetail.state" class="handle_form_select" clearable
								placeholder="请选择">
								<el-option v-for="item in mainTeachers" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="申请结果:">
							<el-select v-model="commonDetail.result" class="handle_form_select" clearable
								placeholder="请选择">
								<el-option v-for="item in mainTeachers1" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="flex_item" label="申请提交时间:">
							<el-date-picker class="handle_form_date"  type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="commonDetail.applyTime">
							</el-date-picker>
						</el-form-item>
					</div>
					<div class="handle_form_item">
						<el-form-item class="" label="附件资料:">
							<uploadFile @successUpload="successUpload" :fileSrc="commonDetail.appendixUrl" :fileNameP="commonDetail.appendixName"/>
							<!-- <div class="handle_form_upload">
								<span class="form_upload_box">
									<img src="@/assets/images/dashboard/upLoad.png"
										class="form_upload_icon">点击上传
								</span>
								<span class="form_upload_tip">注：可上传文本和图片</span>
							</div> -->
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item class="" label="提交截图:"  ref="uploadCover">
							<dragUpLoad ref="dragUpLoad"  @beforeUpload="beforeUpload" @removeImg="removeImg" />
						</el-form-item>
					</div>
				</div>
				<div class="handle_form_row">
					<div class="handle_form_item">
						<el-form-item label-width="40px" class="flex-column" label="备注:">
							<textarea class="handle_form_textarea" v-model="commonDetail.remark"></textarea>
						</el-form-item>
					</div>
				</div>
			</el-form>
			<div class="handle_form_btn">
				<span @click="closeDialog" class="handle_form_cancel">取消</span>
				<span @click="submitCommon" class="handle_form_confirm" v-preventReClick>确定</span>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import Bus from "./eventBus.js";
	import dragUpLoad from "@/components/dragUpLoad/dragUpload.vue";
	import uploadFile from "@/components/uploadFile/index.vue";
	import {
		controlpanelDetail,
		controlpanelSave
	} from "@/api/acTable"
	export default {
		components: {
			dragUpLoad,
			uploadFile
		},
		props: {},
		watch: {},
		created(){

		},
		mounted() {
			Bus.$on("handleCommonForm", props => {
				this.propsData = props.row
				this.commonDetail.studentId = props.row.studentId
				this.commonDetail.schoolId = props.row.schoolId
				this.diaLogVisible = true
				this.getCommonDetail()
				console.log("handleCommonForm", props)
			})
		},
		data() {
			return {
				diaLogTitle: "通用",
				diaLogVisible: false,
				commonDetail: {},
				commonRules: {},
				mainTeachers:[
					{
					value: '待提交',
					label: '待提交'
					}, {
					value: '已提交',
					label: '已提交'
					}
				],
				mainTeachers1:[
					{
					value: 'Waitlist',
					label: 'Waitlist'
					}, {
					value: 'Admit',
					label: 'Admit'
					},{
					value: 'Reject',
					label: 'Reject'
					},
					
				],
				propsData:{},
			}
		},
		methods: {
			//查看通用
			getCommonDetail(){
				var params = {
					studentId: this.propsData.studentId,
					schoolId:this.propsData.schoolId
				}
				controlpanelDetail(params).then(res=>{
					if(res.code == 0){
						if(res.data){
							this.commonDetail = res.data
							if(res.data.picUrl){
								this.$refs.dragUpLoad.fileData = res.data.picUrl
							}
						}
					}
				})
			},
			successUpload(data){
				this.commonDetail.appendixUrl = data.fileData
				this.commonDetail.appendixName = data.fileName
			},
			beforeUpload(props) {
				console.log(props,'propsqweqw')
				if (props) {
					this.commonDetail.picUrl = props.fileData
					this.$refs.uploadCover.clearValidate()
				} else {
					this.commonDetail.picUrl = ""
				}
			},
			removeImg(props) {
				if (props.length > 0) {
					this.commonDetail.picUrl = props[0]
				} else {
					this.commonDetail.picUrl = ""
				}
			},
			closeDialog() {
				this.diaLogVisible = false
				// this.$emit('closeDialog', 'commom')
			},
			//通用编辑
			submitCommon(){
				console.log(this.commonDetail,99999)
				let editParams = JSON.parse(JSON.stringify(this.commonDetail))
				controlpanelSave(editParams).then(res=>{
					if(res.code == 0){
						 this.$message({
							message: '编辑成功',
							type: 'success'
						});
						this.closeDialog()
						this.$parent.getAcLists()
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../../styles/index.scss";

	/deep/.el-date-editor.el-input :focus {
		border: 1px solid $theme_color;
	}

	input::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	textarea::-webkit-input-placeholder {
		font-size: 14px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		line-height: 20px;
		color: #999999 !important;
	}

	.flex_item {
		display: flex;
		align-items: center;
	}
	
	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.handle_form {
		.handle_form_row {
			margin-top: 25px;
			display: flex;
			// padding: 0 23px;

			.handle_form_item {
				flex: 1;
				display: flex;

				.handle_form_ipt {
					width: 200px;
					border: 1px solid #EEEEEE;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 30px;
					color: #000000;
					padding: 0 10px;
					box-sizing: border-box;
				}
				
				.handle_form_date {
					width: 200px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					margin-top: 9px;
				}

				.handle_form_select {
					width: 200px;
					outline: none;
					font-size: 14px;
					font-family: Source Han Sans CN;
					font-weight: 400;
					line-height: 34px;
					color: #000000;
					margin-top: 9px;
				}

				.handle_form_textarea {
					width: 840px;
					height: 120px;
					background: #FFFFFF;
					border: 1px solid #EEEEEE;
					border-radius: 4px;
					outline: none;
					resize: none;
					padding: 6px 10px;
					box-sizing: border-box;
					margin: 8px 0 0 0px;
				}
				
				.handle_form_upload {
					display: flex;
					flex-direction: column;
				
					.form_upload_box {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 128px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #EEEEEE;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #000000;
						cursor: pointer;
				
						.form_upload_icon {
							width: 14px;
							height: 14px;
							margin-right: 10px;
						}
					}
				
					.form_upload_tip {
						font-size: 12px;
						font-family: Source Han Sans CN;
						font-weight: 400;
						line-height: 17px;
						color: #999999;
						margin-top: 5px;
					}
				}
			}
		}

		.handle_form_btn {
			display: flex;
			align-items: center;
			margin: 40px 0 0px 0;

			.handle_form_cancel {
				margin-left: auto;
				width: 76px;
				line-height: 34px;
				background: #FFFFFF;
				border: 1px solid $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: $theme_color;
				text-align: center;
				cursor: pointer;
				margin-right: 16px;
			}

			.handle_form_confirm {
				width: 76px;
				line-height: 34px;
				background: $theme_color;
				border-radius: 4px;
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFF;
				text-align: center;
				cursor: pointer;
			}
		}
	}
</style>
